import {
  CHECKIN_PARTICIPANTS,
  CHECK_OUT,
  CHECK_OUT_ALL,
  CREATE_PARTICIPANTS,
  DROP_ACTION,
  FETCHONE_PARTICIPANTS,
  FETCH_PARTICIPANTS,
  GET_DEGREE,
  GET_CHECK_IN,
  SEARCH_PRESIDER,
  SEARCH_USER,
  SEND_MAIL,
  UPDATE_CHECKIN_CHECKOUT,
  UPDATE_TICKET_PRESIDER,
  GET_CHECK_IN_MOBILE,
  UPDATE_CHECK_IN_MOBILE,
} from 'src/utils/types'

export function fetchParticipants(payload) {
  return {
    type: FETCH_PARTICIPANTS.REQUEST,
    payload,
  }
}

export function searchUsers(param) {
  return {
    type: SEARCH_USER.REQUEST,
    payload: param,
  }
}

export function searchPresider(body) {
  return {
    type: SEARCH_PRESIDER.REQUEST,
    payload: body,
  }
}

export function getDegree() {
  return {
    type: GET_DEGREE.REQUEST,
  }
}

export function createParticipants(body, action) {
  return {
    type: CREATE_PARTICIPANTS.REQUEST,
    payload: body,
    action,
  }
}

export function fetchOneLetter(param) {
  return {
    type: FETCHONE_PARTICIPANTS.REQUEST,
    payload: param,
  }
}

export function updateCheckIn(param, id) {
  return {
    type: CHECKIN_PARTICIPANTS.REQUEST,
    payload: param,
    id,
  }
}

export function updateTicketPresider(body, id) {
  return {
    type: UPDATE_TICKET_PRESIDER.REQUEST,
    payload: body,
    id,
  }
}

export function sendMail(body, action) {
  return {
    type: SEND_MAIL.REQUEST,
    payload: body,
    action,
  }
}

export function checkOut(param) {
  return {
    type: CHECK_OUT.REQUEST,
    payload: param,
  }
}

export function checkOutAll(param, action) {
  return {
    type: CHECK_OUT_ALL.REQUEST,
    payload: param,
    action,
  }
}

export function updateCheckInCheckOut(payload, action) {
  return {
    type: UPDATE_CHECKIN_CHECKOUT.REQUEST,
    payload,
    action,
  }
}

export function getCheckInMobile(payload) {
  return {
    type: GET_CHECK_IN_MOBILE.REQUEST,
    payload,
  }
}

export function updateCheckInMobile(payload) {
  return {
    type: UPDATE_CHECK_IN_MOBILE.REQUEST,
    payload,
  }
}

export function dropAction() {
  return {
    type: DROP_ACTION.REQUEST,
  }
}
