import { SIGNATURE_ZOOM } from '../../../utils/types'

const defaultState = {
  signature: '',
  meetConfig: {},
}

export default function contact(state = defaultState, action) {
  switch (action.type) {
    case SIGNATURE_ZOOM.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case SIGNATURE_ZOOM.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case SIGNATURE_ZOOM.FAILURE:
      return { ...state, error: action.error, loading: false }
    //

    default:
      return state
  }
}
