import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  createTemplateNotification,
  deleteNotificationAPI,
  fectTemplateNotification,
  fetchCMEAPI,
  searchSeminar,
  searchUsers,
  sendNotification,
  sendSeminarNotification,
} from 'src/utils/api'
import { commonNotify } from 'src/utils/helpers'
import {
  CREATE_TEMPLATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  LIST_CME,
  LIST_NOTIFICATION,
  SEARCH_SEMINAR_NOTI,
  SEARCH_USER,
  SEND_NOTIFICATION,
  SEND_SEMINAR_NOTIFICATION,
} from 'src/utils/types'

function* getListNotification() {
  try {
    const response = yield call(fectTemplateNotification)
    if (response.ok) {
      const reponseJSON = yield response.json()

      reponseJSON.map((item, key) => {
        item.key = key + 1
      })

      yield put({
        type: LIST_NOTIFICATION.SUCCESS,
        payload: {
          listTemplate: reponseJSON,
        },
      })
    } else {
      yield put({
        type: LIST_NOTIFICATION.FAILURE,
      })
    }
  } catch (error) {
    console.log(error)
    yield put({
      type: LIST_NOTIFICATION.FAILURE,
    })
  }
}

function* createListNotification(payload) {
  try {
    const { data } = payload

    const response = yield call(createTemplateNotification, data)

    if (response.ok) {
      const reponseJSON = yield response.json()

      yield put({
        type: CREATE_TEMPLATE_NOTIFICATION.SUCCESS,
      })
    } else {
      yield put({
        type: CREATE_TEMPLATE_NOTIFICATION.FAILURE,
      })
    }
  } catch (error) {
    console.log(error)
    yield put({
      type: CREATE_TEMPLATE_NOTIFICATION.FAILURE,
    })
  }
}

//======================= SEND NOTIFICATION =============== //
function* sendNotificationSaga(payload) {
  try {
    const { data } = payload
    const response = yield call(sendNotification, data)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: SEND_NOTIFICATION.SUCCESS,
        payload: {
          sendNotification: responseJson,
        },
      })
    } else {
      yield put({ type: SEND_NOTIFICATION.FAILURE })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: SEND_NOTIFICATION.FAILURE })
  }
}

function* sendSeminarNotificationSaga(payload) {
  try {
    const { data } = payload
    const response = yield call(sendSeminarNotification, data)

    if (response.ok) {
      commonNotify({ message: 'Gửi thông báo thành công', type: 'success' })
      yield put({
        type: SEND_SEMINAR_NOTIFICATION.SUCCESS,
      })
    } else {
      throw new Error('Error: Send Seminar Notification')
    }
  } catch (error) {
    console.error(error)
    commonNotify({ message: 'Gửi thông báo không thành công', type: 'error' })
    yield put({ type: SEND_SEMINAR_NOTIFICATION.FAILURE })
  }
}

//======================= SEARCH SEMINAR =============== //
function* searchSeminarSaga(payload) {
  try {
    const { data } = payload
    const { param } = payload
    const response = yield call(searchSeminar, data, param)
    if (response.ok) {
      const responseJson = yield response.json()
      responseJson.result.map((item) => {
        item.fullName = item.firstName + ' ' + item.lastName
      })

      yield put({
        type: SEARCH_SEMINAR_NOTI.SUCCESS,
        payload: {
          listSeminar: responseJson,
        },
      })
    } else {
      yield put({ type: SEARCH_SEMINAR_NOTI.FAILURE })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: SEARCH_SEMINAR_NOTI.FAILURE })
  }
}

function* fetchAllCMESaga() {
  try {
    const response = yield call(fetchCMEAPI)
    if (response.ok) {
      const responseJson = yield response.json()
      yield put({
        type: LIST_CME.SUCCESS,
        payload: {
          listCME: responseJson.data,
        },
      })
    } else {
      yield put({ type: LIST_CME.FAILURE })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: LIST_CME.FAILURE })
  }
}

function* deleteNotificationSaga(payload) {
  try {
    const { param } = payload
    console.log(param)
    const response = yield call(deleteNotificationAPI, param)
    if (response.ok) {
      const responseJson = yield response.json()
      console.log(responseJson, 'deleteNoti')
      yield put({
        type: DELETE_NOTIFICATION.SUCCESS,
        payload: {
          listCME: responseJson.data,
        },
      })
    } else {
      yield put({ type: DELETE_NOTIFICATION.FAILURE })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: DELETE_NOTIFICATION.FAILURE })
  }
}

// Monitoring Sagas
function* newsCategoryMonitor() {
  yield all([
    takeLatest(LIST_NOTIFICATION.REQUEST, getListNotification),
    takeLatest(CREATE_TEMPLATE_NOTIFICATION.REQUEST, createListNotification),
    takeLatest(SEND_NOTIFICATION.REQUEST, sendNotificationSaga),
    takeLatest(SEND_SEMINAR_NOTIFICATION.REQUEST, sendSeminarNotificationSaga),
    takeLatest(SEARCH_SEMINAR_NOTI.REQUEST, searchSeminarSaga),
    takeLatest(LIST_CME.REQUEST, fetchAllCMESaga),
    takeLatest(DELETE_NOTIFICATION.REQUEST, deleteNotificationSaga),
  ])
}

export default newsCategoryMonitor
