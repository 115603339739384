import { createActionSet } from './services/redux'

export const LOGIN = createActionSet('LOGIN')
export const LOGOUT = createActionSet('LOGOUT')
export const VERIFY_PASSWORD = createActionSet('VERIFY_PASSWORD')
export const CONFIRM_PASSWORD = createActionSet('CONFIRM_PASSWORD')
export const FORGET_PASSWORD_ADMIN = createActionSet('FORGET_PASSWORD_ADMIN')

export const CHANGE_SEMINAR_PAGINATION = 'CHANGE_CONTACT_PAGINATION'
export const ONE_SEMINAR = createActionSet('ONE_SEMINAR')
export const LIST_SEMINARS = createActionSet('LIST_SEMINARS')
export const CREATE_SEMINAR = createActionSet('CREATE_SEMINAR')
export const CREATE_SEMINAR_PN = createActionSet('CREATE_SEMINAR_PN')
export const UPDATE_SEMINAR = createActionSet('UPDATE_SEMINAR')
export const DELETE_SEMINAR = createActionSet('DELETE_SEMINAR')
export const SEND_MAIL_SEMINAR = createActionSet('SEND_MAIL_SEMINAR')
export const TEST_MAIL_CUTOFF = createActionSet('TEST_MAIL_CUTOFF')
export const SEARCH_SEMINAR = createActionSet('SEARCH_SEMINAR')
export const LIST_SEMINARS_FULL = createActionSet('LIST_SEMINARS_FULL')
export const FETCH_SEMINAR = createActionSet('FETCH_SEMINAR')
export const UPDATE_KEYLIVE_SEMINAR = createActionSet('UPDATE_KEYLIVE_SEMINAR')
export const UPDATE_DOCUMENTS = createActionSet('UPDATE_DOCUMENTS')
export const GET_DOCUMENTS = createActionSet('GET_DOCUMENTS')


export const CREATE_CATEGORY = createActionSet('CREATE_CATEGORY')
export const UPDATE_CATEGORY = createActionSet('UPDATE_CATEGORY')
export const DELETE_CATEGORY = createActionSet('DELETE_CATEGORY')
export const LIST_CATEGORIES = createActionSet('LIST_CATEGORIES')
export const ONE_CATEGORY = createActionSet('ONE_CATEGORY')
export const CREATE_CHILD_CATEGORY = createActionSet('CREATE_CHILD_CATEGORY')
export const UPDATE_CHILD_CATEGORY = createActionSet('UPDATE_CHILD_CATEGORY')
export const DELETE_CHILD_CATEGORY = createActionSet('DELETE_CHILD_CATEGORY')

export const LIST_REPORTERS = createActionSet('LIST_REPORTERS')
export const LIST_MODERATORS = createActionSet('LIST_MODERATORS')
export const LIST_SPONSORS = createActionSet('LIST_SPONSORS')
export const LIST_RANK_SPONSORS = createActionSet('LIST_RANK_SPONSORS')

export const FETCH_SPONSOR = createActionSet('FETCH_SPONSOR')
export const CREATE_SPONSOR = createActionSet('CREATE_SPONSOR')
export const UPDATE_SPONSOR = createActionSet('UPDATE_SPONSOR')
export const DELETE_SPONSOR = createActionSet('DELETE_SPONSOR')

export const SET_DEFAULT_STATUS = createActionSet('SET_DEFAULT_STATUS')
export const SET_DEFAULT_BTN_CME_STATUS = createActionSet('SET_DEFAULT_BTN_CME_STATUS')
export const RECORD_SEMINAR = createActionSet('RECORD_SEMINAR')

export const USERS = createActionSet('USERS')
export const DEGREE = createActionSet('DEGREE')
export const CREATE_USER = createActionSet('CREATE_USER')
export const DELETE_USER = createActionSet('DELETE_USER')
export const UPDATE_USER = createActionSet('UPDATE_USER')
export const SEARCH_USER = createActionSet('SEARCH_USER')
export const FORGETPASSWORD_USER = createActionSet('FORGETPASSWORD_USER')

export const GET_DEGREE = createActionSet('GET_DEGREE')
export const CREATE_DEGREE = createActionSet('CREATE_DEGREE')
export const UPDATE_DEGREE = createActionSet('UPDATE_DEGREE')
export const DELETE_DEGREE = createActionSet('DELETE_DEGREE')

export const LIST_CONTACTS = createActionSet('LIST_CONTACTS')
export const ONE_CONTACT = createActionSet('ONE_CONTACT')
export const FEEDBACK_CONTACT = createActionSet('FEEDBACK_CONTACT')
export const DELETE_CONTACT = createActionSet('DELETE_CONTACT')

export const GET_SPEAKER = createActionSet('GET_SPEAKER')
export const CREATE_SPEAKER = createActionSet('CREATE_SPEAKER')
export const UPDATE_SPEAKER = createActionSet('UPDATE_SPEAKER')
export const DELETE_SPEAKER = createActionSet('DELETE_SPEAKER')

export const LIST_SESSION = createActionSet('LIST_SESSION')
export const GET_PRESIDER = createActionSet('GET_PRESIDER')
export const CREATE_PRESIDER = createActionSet('CREATE_PRESIDER')
export const UPDATE_PRESIDER = createActionSet('UPDATE_PRESIDER')
export const DELETE_PRESIDER = createActionSet('DELETE_PRESIDER')

export const FETCH_PARTICIPANTS = createActionSet('FETCH_PARTICIPANTS')
// export const FETCH_SEMINAR = createActionSet('FETCH_SEMINAR')
export const CREATE_PARTICIPANTS = createActionSet('CREATE_PARTICIPANTS')
export const UPDATE_PARTICIPANTS = createActionSet('UPDATE_PARTICIPANTS')
export const FETCHONE_PARTICIPANTS = createActionSet('FETCHONE_PARTICIPANTS')
export const CHECKIN_PARTICIPANTS = createActionSet('CHECKIN_PARTICIPANTS')
export const UPDATE_TICKET_PRESIDER = createActionSet('UPDATE_TICKET_PRESIDER')
export const SEARCH_PRESIDER = createActionSet('SEARCH_PRESIDER')
export const SEND_MAIL = createActionSet('SEND_MAIL')
export const PUBLIC_SEMINAR = createActionSet('PUBLIC_SEMINAR')
export const CHECK_OUT = createActionSet('CHECK_OUT')
export const CHECK_OUT_ALL = createActionSet('CHECK_OUT_ALL')
export const UPDATE_CHECKIN_CHECKOUT = createActionSet('UPDATE_CHECKIN_CHECKOUT')
export const UPDATE_CONTENT_EMAIL = createActionSet('UPDATE_CONTENT_EMAIL')
export const UPDATE_CONTENT_EMAIL_PUBLIC = createActionSet('UPDATE_CONTENT_EMAIL_PUBLIC')
export const TEST_CONTENT_EMAIL_PUBLIC = createActionSet('TEST_CONTENT_EMAIL_PUBLIC')
export const SEND_ALL_USERS_SEMINAR_PUBLIC = createActionSet('SEND_ALL_USERS_SEMINAR_PUBLIC')
export const GET_CHECK_IN_MOBILE = createActionSet('GET_CHECK_IN_MOBILE')
export const UPDATE_CHECK_IN_MOBILE = createActionSet('UPDATE_CHECK_IN_MOBILE')

export const FETCH_DATA_ADMIN = createActionSet('FETCH_DATA_ADMIN')
export const CREATE_ACC_ADMIN = createActionSet('CREATE_ACC_ADMIN')
export const UPDATE_ACC_ADMIN = createActionSet('UPDATE_ACC_ADMIN')
export const UPDATE_PERMISSION_ADMIN = createActionSet('UPDATE_PERMISSION_ADMIN')
export const SEARCH_ADMIN = createActionSet('SEARCH_ADMIN')
export const UPDATE_USER_ADMIN = createActionSet('UPDATE_USER_ADMIN')
export const SEARCH_USER_ADMIN = createActionSet('SEARCH_USER_ADMIN')
export const SEARCH_USER_PAGE_ADMIN = createActionSet('SEARCH_USER_PAGE_ADMIN')
export const CREATE_ACC_ADMIN_BY_USER = createActionSet('CREATE_ACC_ADMIN_BY_USER')
export const UPGRADE_ACC_ADMIN_BY_USER = createActionSet('UPGRADE_ACC_ADMIN_BY_USER')


export const DROP_ACTION = createActionSet('DROP_ACTION')

export const LIST_ORDERS = createActionSet('LIST_ORDERS')
export const LIST_ORDERS_FULL = createActionSet('LIST_ORDERS_FULL')
export const CANCEL_ORDER = createActionSet('CANCEL_ORDER')
export const CONFIRM_ORDER = createActionSet('CONFIRM_ORDER')
export const ONE_ORDER = createActionSet('ONE_ORDER')
export const RESEND_MAIL_ORDER = createActionSet('RESEND_MAIL_ORDER')
export const GET_SEMINAR_EXPORT = createActionSet('GET_SEMINAR_EXPORT')
export const CALL_EXPORT_ORDER = createActionSet('CALL_EXPORT_ORDER')

export const ONE_USER = createActionSet('ONE_USER')
export const RESET_PASSWORD = createActionSet('RESET_PASSWORD')
export const MERGE_USER = createActionSet('MERGE_USER')
export const VERIFY_CERTIFICATE = createActionSet('VERIFY_CERTIFICATE')

export const FETCH_CME = createActionSet('FETCH_CME')
export const LIST_CME = createActionSet('LIST_CME')
export const CREATE_CME = createActionSet('CREATE_CME')
export const LIST_CME_ISSUE = createActionSet('LIST_CME_ISSUE')
export const PRINT_CME = createActionSet('PRINT_CME')
export const CREATE_ONE_CME = createActionSet('CREATE_ONE_CME')
export const STATISTICS_CME = createActionSet('STATISTICS_CME')



export const CREATE_LIVE_MEETING = createActionSet('CREATE_LIVE_MEETING')
export const SIGNATURE_ZOOM = createActionSet('SIGNATURE_ZOOM')

export const POST_COORDINATES1 = createActionSet('POST_COORDINATES1')
export const GET_COORDINATES = createActionSet('GET_COORDINATES')
export const FETCH_COORDINATES = createActionSet('FETCH_COORDINATES')

export const CREATE_NEWS_CATEGORY = createActionSet('CREATE_NEWS_CATEGORY')
export const UPDATE_NEWS_CATEGORY = createActionSet('UPDATE_NEWS_CATEGORY')
export const DELETE_NEWS_CATEGORY = createActionSet('DELETE_NEWS_CATEGORY')
export const LIST_NEWS_CATEGORIES = createActionSet('LIST_NEWS_CATEGORIES')
export const ONE_NEWS_CATEGORY = createActionSet('ONE_NEWS_CATEGORY')

export const CREATE_NEWS = createActionSet('CREATE_NEWS')
export const UPDATE_NEWS = createActionSet('UPDATE_NEWS')
export const DELETE_NEWS = createActionSet('DELETE_NEWS')
export const LIST_NEWS = createActionSet('LIST_NEWS')
export const ONE_NEWS = createActionSet('ONE_NEWS')
export const POST_COORDINATES_CME = createActionSet('POST_COORDINATES_CME')
export const GET_COORDINATES_CME = createActionSet('GET_COORDINATES_CME')
export const FETCH_COORDINATES_CME = createActionSet('FETCH_COORDINATES_CME')

export const CREATE_COUPON = createActionSet('CREATE_COUPON')
export const UPDATE_COUPON = createActionSet('UPDATE_COUPON')
export const DELETE_COUPON = createActionSet('DELETE_COUPON')
export const LIST_COUPONS = createActionSet('LIST_COUPONS')
export const ONE_COUPON = createActionSet('ONE_COUPON')
export const LIST_KIND_OF_COUPON = createActionSet('LIST_KIND_OF_COUPON')
export const LIST_KIND_OF_DISCOUNT = createActionSet('LIST_KIND_OF_DISCOUNT')
export const LIST_SEMINARS_COUPON = createActionSet('LIST_SEMINARS_COUPON')

export const LIST_NOTIFICATION = createActionSet('LIST_NOTIFICATION')
export const CREATE_TEMPLATE_NOTIFICATION = createActionSet('CREATE_TEMPLATE_NOTIFICATION')
export const SEND_NOTIFICATION = createActionSet('SEND_NOTIFICATION')
export const SEND_SEMINAR_NOTIFICATION = createActionSet('SEND_SEMINAR_NOTIFICATION')
export const SEARCH_SEMINAR_NOTI = createActionSet('SEARCH_SEMINAR_NOTI')
export const DELETE_NOTIFICATION = createActionSet('DELETE_NOTIFICATION')


export const LIST_FOOTER = createActionSet('LIST_FOOTER')
export const ONE_FOOTER = createActionSet('ONE_FOOTER')
export const EDIT_FOOTER = createActionSet('EDIT_FOOTER')

export const LIST_POLL = createActionSet('LIST_POLL')
export const ONE_POLL = createActionSet('ONE_POLL')
export const CREATE_POLL = createActionSet('CREATE_POLL')
export const EDIT_POLL = createActionSet('EDIT_POLL')
export const DELETE_POLL = createActionSet('DELETE_POLL')

export const LIST_PANELIST = createActionSet('LIST_PANELIST')
export const ONE_PANELIST = createActionSet('ONE_PANELIST')
export const CREATE_PANELIST = createActionSet('CREATE_PANELIST')
export const EDIT_PANELIST = createActionSet('EDIT_PANELIST')
export const DELETE_PANELIST = createActionSet('DELETE_PANELIST')
