import { LIST_NEWS, CREATE_NEWS, ONE_NEWS, UPDATE_NEWS, DELETE_NEWS, LIST_NEWS_CATEGORIES } from '../../../utils/types'

const defaultState = {
  newList: null,
}

export default function news(state = defaultState, action) {
  switch (action.type) {
    case LIST_NEWS.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LIST_NEWS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case LIST_NEWS.FAILURE:
      return { ...state, error: action.error, loading: false }

    case ONE_NEWS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case ONE_NEWS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case ONE_NEWS.FAILURE:
      return { ...state, error: action.error, loading: false }

    case CREATE_NEWS.REQUEST:
    case CREATE_NEWS.SUCCESS:
    case CREATE_NEWS.FAILURE:

    case UPDATE_NEWS.REQUEST:
    case UPDATE_NEWS.SUCCESS:
    case UPDATE_NEWS.FAILURE:

    case DELETE_NEWS.REQUEST:
    case DELETE_NEWS.SUCCESS:
    case DELETE_NEWS.FAILURE:

    //list Categories
    case LIST_NEWS_CATEGORIES.REQUEST:
      state.newCategories = action.payload
      return {
        ...state,
        loading: true,
      }
    case LIST_NEWS_CATEGORIES.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case LIST_NEWS_CATEGORIES.FAILURE:
      return { ...state, error: action.error, loading: false }

    default:
      return state
  }
}
