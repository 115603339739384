import { CREATE_CME, CREATE_ONE_CME, FETCH_CME, LIST_CME_ISSUE, PRINT_CME, STATISTICS_CME } from 'src/utils/types'

const defaultState = {
  data: {
    loading: false,
    error: null,
    isProvidingCme: false, 
    isProvidingOneCme: false,
    isPrintOneCme: false,
  },
}

export default function Cme(state = defaultState, action) {
  switch (action.type) {
    case FETCH_CME.REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
        error: false,
      }
    case FETCH_CME.SUCCESS:
      return {
        ...state,
        // data:action.payload,
        ...action.payload,

        error: false,
        loading: false,
      }
    case FETCH_CME.FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true,
      }

    // ======================= Search User ============= //
    case CREATE_CME.REQUEST:
      return {
        ...state,
        ...action.payload,
        isProvidingCme: true,
      }
    case CREATE_CME.SUCCESS:
      return {
        ...state,
        ...action.payload,
        isProvidingCme: false,
      }
    case CREATE_CME.FAILURE:
      return {
        ...state,
        ...action.payload,
        isProvidingCme: false,
      }

    case LIST_CME_ISSUE.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case LIST_CME_ISSUE.SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      }
    case LIST_CME_ISSUE.FAILED:
      return {
        ...state,
        loading: false,
        error: null,
      }

    //=========================== print cme ==================//

    case PRINT_CME.REQUEST:
      return {
        ...state,
        ...action.payload,
        isPrintOneCme: true,
        statusPrintCME: 0,
      }
    case PRINT_CME.SUCCESS:
      return {
        ...state,
        ...action.payload,
        isPrintOneCme: false,
        statusPrintCME: 1,
      }
    case PRINT_CME.FAILURE:
      return {
        ...state,
        ...action.payload,
        isPrintOneCme: false,
        statusPrintCME: 2,
      }

    case CREATE_ONE_CME.REQUEST:
      return {
        ...state,
        ...action.payload,
        isProvidingOneCme: true,
      }
    case CREATE_ONE_CME.SUCCESS:
      return {
        ...state,
        ...action.payload,
        isProvidingOneCme: false,
      }
    case CREATE_ONE_CME.FAILURE:
      return {
        ...state,
        ...action.payload,
        isProvidingOneCme: false,
      }
      
    case STATISTICS_CME.REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
      }
    case STATISTICS_CME.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case STATISTICS_CME.FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }

    default:
      return state
  }
}
