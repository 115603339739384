import { all, fork } from 'redux-saga/effects'
import usersSaga from 'src/containers/Admin/User/saga'
import authSaga from 'src/containers/Auth/saga'

import seminarSaga from 'src/containers/Admin/Seminar/saga'
import categorySaga from 'src/containers/Admin/Category/saga'

import degreeSaga from 'src/containers/Admin/Degree/saga'
import contactSaga from 'src/containers/Admin/Contact/saga'
// import degreeSaga  from 'src/containers/Admin/Degree/saga'
import speakerSaga from 'src/containers/Admin/Speaker/saga'
import PresiderSaga from 'src/containers/Admin/Presider/saga'
import ParticipantsSaga from 'src/containers/Admin/Participants/saga'
import OrderSaga from 'src/containers/Admin/Order/saga'
import AdminSaga from 'src/containers/Admin/Admin/saga'
import SponsorSaga from 'src/containers/Admin/Sponsor/saga'
import DesignTicketSaga from 'src/containers/Admin/DesignTicket/saga'
import CmeSaga from 'src/containers/Admin/CME/saga'
import ZoomMeetingSaga from 'src/containers/Admin/ZoomLive/saga'
import NewsCategorySaga from 'src/containers/Admin/NewsCategory/saga'
import NewsSaga from 'src/containers/Admin/News/saga'
import DesignCMESaga from 'src/containers/Admin/DesignCME/saga'
import CouponSaga from 'src/containers/Admin/Coupon/saga'
// export default function* rootSaga() {
//   yield all([fork(authSaga), fork(seminarSaga), fork(categorySaga), fork(usersSaga), fork(degreeSaga), fork(contactSaga)]
import NotificationSaga from 'src/containers/Admin/Notification/sagas'
import FooterSaga from 'src/containers/Admin/Footer/saga'

import seminarPanelistSaga from 'src/containers/Admin/Panelist/saga'
import seminarPollSaga from 'src/containers/Admin/Poll/saga'
export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(seminarSaga),
    fork(categorySaga),
    fork(usersSaga),
    fork(degreeSaga),
    fork(speakerSaga),
    fork(PresiderSaga),
    fork(contactSaga),
    fork(ParticipantsSaga),
    fork(OrderSaga),
    fork(AdminSaga),
    fork(SponsorSaga),
    fork(CmeSaga),
    fork(DesignTicketSaga),
    fork(ZoomMeetingSaga),
    fork(NewsCategorySaga),
    fork(NewsSaga),
    fork(DesignCMESaga),
    fork(CouponSaga),
    fork(NotificationSaga),
    fork(FooterSaga),
    fork(seminarPanelistSaga),
    fork(seminarPollSaga),
  ])
}

