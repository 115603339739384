import { message } from 'antd'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { createCme, createOneCme, getCmeIssueList, getListCme, getSeminarList, printCMEAPI, statisticsCme } from 'src/utils/api'
import { CREATE_CME, CREATE_ONE_CME, FETCH_CME, LIST_CME_ISSUE, PRINT_CME, STATISTICS_CME } from 'src/utils/types'

function* fetchCmeList({ payload }) {
  const response = yield call(getListCme, payload)
  try {
    const responseJson = yield response.json()
    if (response.ok) {
      yield put({
        type: FETCH_CME.SUCCESS,
        payload: {
          listCme: responseJson.data,
        },
      })
    } else if (response.status == 400) {
      yield put({ type: FETCH_CME.FAILURE, status: 400 })
    } else {
      yield put({ type: FETCH_CME.FAILURE, status: 500 })
    }
  } catch (error) {
    yield put({ type: FETCH_CME.FAILURE, error })
  }
}

function* createCmeSaga({ seminarId, action }) {
  try {
    const response = yield call(createCme, seminarId)
    if (response.ok) {
      yield put({
        type: CREATE_CME.SUCCESS,
      })
      message.success('Cấp chứng chỉ thành công')
      action()
    } else {
      yield put({ type: CREATE_CME.FAILURE, status: 500 })
      message.error('Lỗi. Vui lòng thử lại')
    }
  } catch (error) {
    yield put({ type: CREATE_CME.FAILURE, status: 500 })
    message.error('Lỗi. Vui lòng thử lại')
  }
}

function* createOneCmeSaga({ payload, action }) {
  try {
    const response = yield call(createOneCme, payload)
    if (response.ok) {
      yield put({
        type: CREATE_ONE_CME.SUCCESS,
      })
      message.success('Cấp chứng chỉ thành công')
      action()
    } else {
      yield put({ type: CREATE_ONE_CME.FAILURE, status: 500 })
      message.error('Lỗi. Vui lòng thử lại')
    }
  } catch (error) {
    yield put({ type: CREATE_ONE_CME.FAILURE, status: 500 })
    message.error('Lỗi. Vui lòng thử lại')
  }
}

function* fetchCmeIssueList({ payload }) {
  try {
    const response = yield call(getCmeIssueList, payload)
    if (response.ok) {
      const responseJson = yield response.json()
      yield put({
        type: LIST_CME_ISSUE.SUCCESS,
        payload: {
          listCmeIssue: responseJson.data,
        },
      })
    }
  } catch (error) {
    yield put({ type: LIST_CME_ISSUE.FAILURE })
  }
}

function* printCMESaga({ payload, action }) {
  try {
    const response = yield call(printCMEAPI, payload)
    if (response.ok) {
      const { data, status_code } = yield response.json()
      console.log(data)
      if (status_code === 200) {
        const cme = process.env.API_URI + `/upload/${data.file}`
        action(cme, true)
        yield put({
          type: PRINT_CME.SUCCESS,
        })
        message.success('Đã in chứng chỉ')
        return
      }

      if (status_code === 404) {
        yield put({
          type: PRINT_CME.FAILURE,
          status: 404,
        })
        message.error('Đại biểu chưa được cấp CME')
        return
      }
    }
    yield put({ type: PRINT_CME.FAILURE, status: 500 })
    message.error('Lỗi. Vui lòng thử lại')
  } catch (error) {
    console.error(error, 'error')
    yield put({ type: PRINT_CME.FAILURE, status: 500 })
    message.error('Lỗi. Vui lòng thử lại')
  }
}

function* getStatisticsCmeSaga({ payload }) {
  try {
    const response = yield call(statisticsCme, payload)
    if (response.ok) {
      const { data } = yield response.json()

      yield put({
        type: STATISTICS_CME.SUCCESS,
        payload: data,
      })
      return
    }
    yield put({ type: STATISTICS_CME.FAILURE, status: 500 })
  } catch (error) {
    console.error(error, 'error')
    yield put({ type: STATISTICS_CME.FAILURE, status: 500 })
  }
}

function* cmeMonitor() {
  yield all([
    takeLatest(FETCH_CME.REQUEST, fetchCmeList),
    takeLatest(CREATE_CME.REQUEST, createCmeSaga),
    takeLatest(LIST_CME_ISSUE.REQUEST, fetchCmeIssueList),
    takeLatest(PRINT_CME.REQUEST, printCMESaga),
    takeLatest(CREATE_ONE_CME.REQUEST, createOneCmeSaga),
    takeLatest(STATISTICS_CME.REQUEST, getStatisticsCmeSaga),
  ])
}

export default cmeMonitor
