import { takeLatest, put, call, all } from 'redux-saga/effects'
import {
  LIST_NEWS_CATEGORIES,
  CREATE_NEWS_CATEGORY,
  ONE_NEWS_CATEGORY,
  UPDATE_NEWS_CATEGORY,
  DELETE_NEWS_CATEGORY,
} from '../../../utils/types'
import {
  getNewsCategoriesList,
  createNewsCategory,
  getOneNewsCategory,
  updateNewsCategory,
  deleteNewsCategory,
} from 'src/utils/api'
import { reject } from 'lodash'

function* findAllCategories({ payload: searchText }) {
  
  try {
    
    const response = yield call(getNewsCategoriesList, searchText)
    
    
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      
      
      yield put({
        type: LIST_NEWS_CATEGORIES.SUCCESS,
        payload: {
          newsCategory: responseJson,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: LIST_NEWS_CATEGORIES.FAILURE, error })
    yield call(reject)
  }
}

function* addCategory({ payload: body, handleNotification }) {
  try {
  
    const response = yield call(createNewsCategory, body)
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      yield put({
        type: CREATE_NEWS_CATEGORY.SUCCESS,
      })
      handleNotification('Thêm thành công')
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: CREATE_NEWS_CATEGORY.FAILURE, error })
    handleNotification('Thêm thất bại')
    yield call(reject)
  }
}

function* findOneCategory({ payload: id, handleOpenModalUpdate }) {
  try {
    
    const response = yield call(getOneNewsCategory, id)
    
    
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      
      
      yield put({
        type: ONE_NEWS_CATEGORY.SUCCESS,
        payload: {
          oneCategory: responseJson,
        },
      })
      if (handleOpenModalUpdate) {
        handleOpenModalUpdate()
      }
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: ONE_NEWS_CATEGORY.FAILURE, error })
    yield call(reject)
  }
}

function* editCategory({ payload: body, handleNotification, setIsModalUpdate }) {
  try {
  
    const response = yield call(updateNewsCategory, body)
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      yield put({
        type: UPDATE_NEWS_CATEGORY.SUCCESS,
      })

      handleNotification('Sửa thành công')
      setIsModalUpdate(false)
    } else {
      handleNotification('Sửa thất bại')
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_NEWS_CATEGORY.FAILURE, error })
    handleNotification('Sửa thất bại')
    yield call(reject)
  }
}

function* removeCategory({ payload: id, handleNotification }) {
  try {
    
    const response = yield call(deleteNewsCategory, id)
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      yield put({
        type: DELETE_NEWS_CATEGORY.SUCCESS,
      })
      handleNotification('Xoá thành công')
    } else {
      handleNotification('Xoá thất bại')
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: DELETE_NEWS_CATEGORY.FAILURE, error })
    handleNotification('Xoá thất bại')
    yield call(reject)
  }
}

// Monitoring Sagas
function* newsCategoryMonitor() {
  yield all([takeLatest(LIST_NEWS_CATEGORIES.REQUEST, findAllCategories)])

  yield all([takeLatest(CREATE_NEWS_CATEGORY.REQUEST, addCategory)])

  yield all([takeLatest(ONE_NEWS_CATEGORY.REQUEST, findOneCategory)])

  yield all([takeLatest(UPDATE_NEWS_CATEGORY.REQUEST, editCategory)])

  yield all([takeLatest(DELETE_NEWS_CATEGORY.REQUEST, removeCategory)])
}

export default newsCategoryMonitor
