import { getStore } from 'src/store'

const METHOD = {
  GET: 'GET',
  HEAD: 'HEAD',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
  POST: 'POST',
}

const { API_URI } = process.env

const request = {
  call: async (url, parameters) => {
    const finalUrl = url.indexOf(API_URI) === 0 ? url : `${API_URI}${url}`
    const response = await fetch(finalUrl, parameters)

    return response
  },
  parameters: (method = METHOD.GET, body = {}) => {
    const { auth } = getStore().getState()
   
    const withBody = [METHOD.PUT, METHOD.PATCH, METHOD.POST]
    const params = {
      method,
      headers: {
        'Content-type': 'application/json',
        ...(auth && auth.token ?  {Authorization: `Basic ${auth.token}`}  : {}),
        'Cache-Control': 'no-cache',
      },
    }
   

    if (withBody.indexOf(method) !== -1) {
      params.body = JSON.stringify(body)
      if (method === METHOD.PUT) {
        params.headers['Content-Length'] = 0
      }
    }
    return params
  },
  delete: async (url) => {
    const response = await request.call(url, request.parameters(METHOD.DELETE))

    return response
  },
  get: async (url, body = {}) => {
    const response = await request.call(url, request.parameters(METHOD.GET, body))
    return response
  },

  getAdmin: async (url, body = {}) => {
    const params = request.parameters(METHOD.GET, body)
    params.headers.Authorization = `Basic ${body.token}`
    const response = await request.call(url, params)
    
    return response
  },

  patch: async (url, body = {}) => {
    const response = await request.call(url, request.parameters(METHOD.PATCH, body))

    return response
  },
  post: async (url, body = {}, token = undefined) => {
    const params = request.parameters(METHOD.POST, body)
    if (token) params.headers.Authorization = token
   
    const response = await request.call(url, params)

    return response
  },

  postAdmin: async (url, body = {}) => {

    const params = request.parameters(METHOD.POST, body.data)
    params.headers.Authorization = `Basic ${body.token}`

    const response = await request.call(url, params)
    return response
  },

  put: async (url, body = {}) => {
    const response = await request.call(url, request.parameters(METHOD.PUT, body))

    return response
  },

  putAdmin: async (url, body = {}, token = undefined) => {
    const params = request.parameters(METHOD.PUT, body.data)
    params.headers.Authorization = `Basic ${body.token}`
    
    const response = await request.call(url, params)

    return response
  },


  // putAdmin: async (url, body = {}, token = undefined) => {
  //   // const response = await request.call(url, request.parameters(METHOD.PUT, body.data))
  //   // response.headers.Authorization = `Basic ${body.token}`
  //   const token1 = JSON.parse(JSON.stringify(`Basic ${body.token}`))
  //   console.log(token1,"_____________api ");

  //   // return response
  // },

  upload: async (url, body = {}) => {
    const { auth } = getStore().getState()
    const params = {
      method: 'POST',
      body,
      headers: {
        ...(auth && auth.token ? { Authorization: `${auth.token}` } : {}),
      },
    }
    return response
  },
  search: async (url = {}) => {
    const response = await request.call(url, request.parameters(METHOD.GET))

    return response
  },
}

export default request
