import { LIST_PANELIST, ONE_PANELIST, CREATE_PANELIST, EDIT_PANELIST, DELETE_PANELIST } from 'src/utils/types'

const defaultState = {
  data: [],
}

export default function seminarPanelist(state = defaultState, action) {
  switch (action.type) {
    case LIST_PANELIST.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LIST_PANELIST.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case LIST_PANELIST.FAILURE:
      return { ...state, error: action.error, loading: false }
    //

    case CREATE_PANELIST.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CREATE_PANELIST.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case CREATE_PANELIST.FAILURE:
      return { ...state, error: action.error, loading: false }

    case DELETE_PANELIST.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case DELETE_PANELIST.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case DELETE_PANELIST.FAILURE:
      return { ...state, error: action.error, loading: false }
    default:
      return state
  }
}
