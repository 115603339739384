import { takeLatest, put, call, all } from 'redux-saga/effects'
import React from 'react'
import {
  LIST_SEMINARS,
  CREATE_SEMINAR,
  UPDATE_SEMINAR,
  DELETE_SEMINAR,
  LIST_CATEGORIES,
  LIST_REPORTERS,
  LIST_MODERATORS,
  LIST_SPONSORS,
  LIST_RANK_SPONSORS,
  ONE_SEMINAR,
  SEND_MAIL_SEMINAR,
  LIST_SEMINARS_FULL,
  CREATE_LIVE_MEETING,
  PUBLIC_SEMINAR,
  CREATE_SEMINAR_PN,
  UPDATE_CONTENT_EMAIL,
  SET_DEFAULT_BTN_CME_STATUS,
  UPDATE_CONTENT_EMAIL_PUBLIC,
  TEST_CONTENT_EMAIL_PUBLIC,
  SEND_ALL_USERS_SEMINAR_PUBLIC,
  RECORD_SEMINAR,
  TEST_MAIL_CUTOFF,
  UPDATE_KEYLIVE_SEMINAR,
  GET_DOCUMENTS,
  UPDATE_DOCUMENTS,
} from '../../../utils/types'
import {
  getSeminarList,
  getCategoriesList,
  getReportersList,
  getModeratorsList,
  createSeminar,
  updateSeminar,
  deleteSeminar,
  getSeminar,
  getSponsorsList,
  getRankSponsorsList,
  sendMailSeminar,
  createLiveMeeting,
  publicSeminar,
  createSeminarPNAPI,
  updateContentEmailAPI,
  publicBtnCME,
  updateContentEmailPublicAPI,
  testContentEmailPublicAPI,
  sendEmailAllUserSeminarPublicAPI,
  recordSeminarAPI,
  testMailSeminar,
  updateKeyAPI,
  getDocumentsAPI,
  updateDocumentsAPI,
} from 'src/utils/api'
import { reject } from 'lodash'
import { notification } from 'antd'
import { commonNotify } from 'src/utils/helpers'

function* findAllSeminars(params) {
  try {
    const response = yield call(
      getSeminarList,
      params.payload.paging.page,
      params.payload.paging.limit,
      params.payload.searchText,
      params.payload.sortField,
      params.payload.sortOrder,
    )

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: LIST_SEMINARS.SUCCESS,
        payload: {
          seminars: responseJson.data,
          page: responseJson.page,
          limit: responseJson.limit,
          totalCount: responseJson.totalCount,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }

    // yield call(resolve)
    // yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: LIST_SEMINARS.FAILURE, error })
    yield call(reject)
  }
}

function* createSeminarPNSaga({ data }) {
  try {
    const response = yield call(createSeminarPNAPI, data)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: CREATE_SEMINAR_PN.SUCCESS,
      })
    } else {
      notification.open({
        message: 'Tên hội đã được tạo trước đó.',
      })
    }
  } catch (error) {
    notification.open({
      message: error,
    })
    yield put({ type: CREATE_SEMINAR_PN.FAILURE, error })
    yield call(reject)
  }
}

function* findFullSeminars({ payload: createWS }) {
  try {
    const response = yield call(getSeminarList, 1, 10000000, '')

    if (response.ok) {
      const responseJson = yield response.json()
      yield put({
        type: LIST_SEMINARS_FULL.SUCCESS,
        payload: {
          seminarsFull: responseJson.data,
          totalCount: responseJson.totalCount,
        },
      })
      createWS(responseJson.data)
    } else {
      throw new Error('Invalid login request')
    }

    // yield call(resolve)
    // yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: LIST_SEMINARS_FULL.FAILURE, error })
    yield call(reject)
  }
}

function* findOneSeminar({ payload: slug, action }) {
  try {
    console.log("=============");
    const response = yield call(getSeminar, slug)
    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: ONE_SEMINAR.SUCCESS,
        payload: {
          oneSeminar: responseJson,
        },
      })
      action()
    } else {
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.log('error', error)

    yield put({ type: ONE_SEMINAR.FAILURE, error })
  }
}

function* findAllCategories() {
  try {
    const response = yield call(getCategoriesList)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: LIST_CATEGORIES.SUCCESS,
        payload: {
          category: responseJson.category,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: LIST_CATEGORIES.FAILURE, error })
    yield call(reject)
  }
}

function* findAllModerators() {
  try {
    const response = yield call(getModeratorsList)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: LIST_MODERATORS.SUCCESS,
        payload: {
          moderators: responseJson.data,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: LIST_MODERATORS.FAILURE, error })
    yield call(reject)
  }
}

function* findAllReporters() {
  try {
    const response = yield call(getReportersList)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: LIST_REPORTERS.SUCCESS,
        payload: {
          reporters: responseJson.data,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.error(error)
    yield put({ type: LIST_REPORTERS.FAILURE, error })
    yield call(reject)
  }
}

function* findAllSponsors() {
  try {
    const response = yield call(getSponsorsList)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: LIST_SPONSORS.SUCCESS,
        payload: {
          sponsors: responseJson,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: LIST_SPONSORS.FAILURE, error })
    yield call(reject)
  }
}

function* findAllRankSponsors() {
  try {
    const response = yield call(getRankSponsorsList)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: LIST_RANK_SPONSORS.SUCCESS,
        payload: {
          rankSponsors: responseJson,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: LIST_RANK_SPONSORS.FAILURE, error })
    yield call(reject)
  }
}

function* addSeminar({ payload: body, awaitFetchSeminar, setIsModalVisible, handleNotification }) {
  try {
    const response = yield call(createSeminar, body)

    if (response.ok) {
      const responseJson = yield response.json()
      if (responseJson.status === 1) {
        handleNotification('Thành công', 'success')
        yield put({
          type: CREATE_SEMINAR.SUCCESS,
        })

        awaitFetchSeminar()
        setIsModalVisible(false)
      } else {
        handleNotification('Thất bại')
      }
    } else {
      handleNotification('Thất bại')
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.log(error)
    handleNotification('Thất bại')
    yield put({ type: CREATE_SEMINAR.FAILURE, error })
    yield call(reject)
  }
}

function* editSeminar({ payload: body, awaitFetchSeminar, setIsModalVisibleUpdate, handleNotification }) {
  try {
    const response = yield call(updateSeminar, body)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: UPDATE_SEMINAR.SUCCESS,
        payload: {},
      })
      handleNotification('Thành công', 'success')
      //awaitFetchSeminar()
      setIsModalVisibleUpdate(false)
    } else {
      handleNotification('Thất bại')
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    handleNotification('Thất bại')
    yield put({ type: UPDATE_SEMINAR.FAILURE, error })
    yield call(reject)
  }
}

function* removeSeminar({ payload, handleNotification }) {
  try {
    const response = yield call(deleteSeminar, payload)
    if (response.ok) {
      const responseJson = yield response.json()
      if (responseJson.status) {
        handleNotification('Thành công', 'success')
        yield put({
          type: DELETE_SEMINAR.SUCCESS,
          payload: {
            deleteSeminar: responseJson,
          },
        })
      } else {
        handleNotification('Hội nghị đã có vé. Không thể xóa')
        yield call(reject)
      }
    } else {
      handleNotification('Thất bại')
      yield call(reject)
    }
    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    handleNotification('Thất bại')
    yield put({ type: DELETE_SEMINAR.FAILURE, error })
    yield call(reject)
  }
}

function* mailSeminar(id) {
  try {
    const response = yield call(sendMailSeminar, id.payload)

    if (response.ok) {
      const responseJson = yield response.json()
      yield call(resolve())

      yield put({
        type: SEND_MAIL_SEMINAR.SUCCESS,
        payload: {
          deleteSeminar: responseJson,
        },
      })
    } else {
      yield call(reject)
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: SEND_MAIL_SEMINAR.FAILURE, error })
    yield call(reject)
  }
}

function* testMailCutOffSaga({ payload, handleNotification }) {
  try {
    const response = yield call(testMailSeminar, payload)
    if (response.ok) {
      handleNotification('Gửi mail thành công', 'success')
      yield put({
        type: TEST_MAIL_CUTOFF.SUCCESS,
      })
    } else {
      handleNotification('Gửi mail thất bại', 'failed')
      yield put({
        type: TEST_MAIL_CUTOFF.FAILURE,
      })
    }
  } catch (error) {
    handleNotification('Gửi mail thất bại', 'failed')
    yield put({ type: TEST_MAIL_CUTOFF.FAILURE, error })
  }
}

function* createZoomMeeting({ payload: openInNewTab }) {
  try {
    const response = yield call(createLiveMeeting)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: CREATE_LIVE_MEETING.SUCCESS,
        payload: {},
      })
      openInNewTab(responseJson.join_url)
    } else {
      yield call(reject)
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: CREATE_LIVE_MEETING.FAILURE, error })
    yield call(reject)
  }
}

function* publicSeminarSaga({ payload: id, handleNotification }) {
  try {
    const response = yield call(publicSeminar, id)

    if (response.ok) {
      const responseJson = yield response.json()
      if (responseJson.status === 1) {
        yield put({
          type: PUBLIC_SEMINAR.SUCCESS,
          payload: {},
        })
        handleNotification('Thành công', 'success')
      } else {
        handleNotification('Thất bại')
        yield put({ type: PUBLIC_SEMINAR.FAILURE, error })
        yield call(reject)
      }
    } else {
      handleNotification('Thất bại')
      yield put({ type: PUBLIC_SEMINAR.FAILURE, error })
      yield call(reject)
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    handleNotification('Thất bại')
    yield put({ type: CREATE_LIVE_MEETING.FAILURE, error })
    yield call(reject)
  }
}

function* updateContentMailSaga({ seminarId, body }) {
  try {
    const response = yield call(updateContentEmailAPI, seminarId, body)
    if (response.ok) {
      yield put({ type: UPDATE_CONTENT_EMAIL.SUCCESS })
      commonNotify({ message: 'Cập nhật thành công', type: 'success' })
    } else {
      yield put({ type: UPDATE_CONTENT_EMAIL.FAILURE, error })
      commonNotify({ message: 'Cập nhật thất bại', type: 'error' })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_CONTENT_EMAIL.FAILURE, error })
    commonNotify({ message: 'Cập nhật thất bại', type: 'error' })
  }
}

function* updateContentMailPublicSaga({ seminarId, body }) {
  try {
    const response = yield call(updateContentEmailPublicAPI, seminarId, body)

    if (response.ok) {
      yield put({ type: UPDATE_CONTENT_EMAIL_PUBLIC.SUCCESS })
      commonNotify({ message: 'Cập nhật thành công', type: 'success' })
    } else {
      yield put({ type: UPDATE_CONTENT_EMAIL_PUBLIC.FAILURE, error })
      commonNotify({ message: 'Cập nhật thất bại', type: 'error' })
    }
  } catch (error) {
    console.log(error)
    yield put({ type: UPDATE_CONTENT_EMAIL_PUBLIC.FAILURE, error })
    commonNotify({ message: 'Cập nhật thất bại', type: 'error' })
  }
}

function* testContentMailPublicSaga({ seminarId, payload, handleNotification }) {
  try {
    const response = yield call(testContentEmailPublicAPI, seminarId, payload)

    if (response.ok) {
      yield put({ type: TEST_CONTENT_EMAIL_PUBLIC.SUCCESS })
      handleNotification('Gửi mail thành công', 'success')
    } else {
      yield put({ type: TEST_CONTENT_EMAIL_PUBLIC.FAILURE, error })
      handleNotification('Gửi mail thất bại', 'failed')
    }
  } catch (error) {
    console.error(error)
    yield put({ type: TEST_CONTENT_EMAIL_PUBLIC.FAILURE, error })
    handleNotification('Gửi mail thất bại', 'failed')
  }
}

function* setCMEBTNSaga({ seminarId, show }) {
  try {
    const response = yield call(publicBtnCME, seminarId, show)

    if (response.ok) {
      const responseJson = yield response.json()
      yield put({ type: SET_DEFAULT_BTN_CME_STATUS.SUCCESS })
      notification.success({
        message: 'Cập nhật thành công.',
      })
    } else {
      yield put({ type: SET_DEFAULT_BTN_CME_STATUS.FAILURE, error })
      yield call(reject)
      notification.error({
        message: 'Cập nhật thất bại',
      })
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: SET_DEFAULT_BTN_CME_STATUS.FAILURE, error })
    yield call(reject)
    notification.error({
      message: 'Cập nhật thất bại',
    })
  }
}

function* sendAllUsersSeminarPublicSaga({ seminarId, handleNotification }) {
  try {
    const response = yield call(sendEmailAllUserSeminarPublicAPI, seminarId)

    if (response.ok) {
      yield put({ type: SEND_ALL_USERS_SEMINAR_PUBLIC.SUCCESS })
      handleNotification('Gửi mail thành công', 'success')
    } else {
      yield put({ type: SEND_ALL_USERS_SEMINAR_PUBLIC.FAILURE, error })
      handleNotification('Gửi mail thất bại', 'failed')
    }
  } catch (error) {
    console.error(error)
    yield put({ type: SEND_ALL_USERS_SEMINAR_PUBLIC.FAILURE, error })
    handleNotification('Gửi mail thất bại', 'failed')
  }
}

function* recordSeminarSaga({ seminarId, record }) {
  try {
    const response = yield call(recordSeminarAPI, seminarId, record)
    if (response.ok) {
      yield put({ type: RECORD_SEMINAR.SUCCESS })
      notification.success({
        message: 'Cập nhật bản ghi thành công.',
      })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: RECORD_SEMINAR.FAILURE, error })
    yield call(reject)
    notification.error({
      message: 'Cập nhật bản ghi thất bại',
    })
  }
}

function* updateKeyLiveSaga({ seminarId, keyYoutube }) {
  try {
    const response = yield call(updateKeyAPI, seminarId, keyYoutube)

    if (response.ok) {
      const responseJson = yield response.json()
      if (responseJson.status_code === 200) {
        yield put({ type: UPDATE_KEYLIVE_SEMINAR.SUCCESS })
        notification.success({
          message: 'Cập nhật thành công',
        })
        return
      }

      yield put({ type: UPDATE_KEYLIVE_SEMINAR.FAILURE, error: responseJson.message })
      notification.error({
        message: 'Cập nhật thất bại',
      })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_KEYLIVE_SEMINAR.FAILURE, error })
    notification.error({
      message: 'Cập nhật thất bại',
    })
  }
}

function* updateDocumentsSaga({ payload: { body, action } }) {
  try {
    console.log("body===========", body);
    const response = yield call(updateDocumentsAPI, body)
    if (response.ok) {
      if (response.status === 201) {
        yield put({ type: UPDATE_DOCUMENTS.SUCCESS })
        notification.success({
          message: 'Cập nhật thành công',
        })
        action && action()
        return
      }

      yield put({ type: UPDATE_DOCUMENTS.FAILURE, error: responseJson.message })
      notification.error({
        message: 'Cập nhật thất bại',
      })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_DOCUMENTS.FAILURE, error })
    notification.error({
      message: 'Cập nhật thất bại',
    })
  }
}

function* getDocumentsSaga({ payload }) {
  try {
    const response = yield call(getDocumentsAPI, payload)

    if (response.ok) {
      const responseJson = yield response.json()
      yield put({ type: GET_DOCUMENTS.SUCCESS, payload: responseJson })
    } else {
      yield put({ type: GET_DOCUMENTS.FAILURE, error: responseJson.message })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: GET_DOCUMENTS.FAILURE, error })
  }
}

// Monitoring Sagas
function* seminarMonitor() {
  yield all([takeLatest(LIST_SEMINARS.REQUEST, findAllSeminars)])

  yield all([takeLatest(LIST_CATEGORIES.REQUEST, findAllCategories)])

  yield all([takeLatest(LIST_REPORTERS.REQUEST, findAllReporters)])

  yield all([takeLatest(LIST_SEMINARS_FULL.REQUEST, findFullSeminars)])

  yield all([takeLatest(LIST_MODERATORS.REQUEST, findAllModerators)])

  yield all([takeLatest(LIST_SPONSORS.REQUEST, findAllSponsors)])

  yield all([takeLatest(LIST_RANK_SPONSORS.REQUEST, findAllRankSponsors)])

  yield all([takeLatest(CREATE_SEMINAR.REQUEST, addSeminar)])

  yield all([takeLatest(CREATE_SEMINAR_PN.REQUEST, createSeminarPNSaga)])

  yield all([takeLatest(UPDATE_SEMINAR.REQUEST, editSeminar)])

  yield all([takeLatest(DELETE_SEMINAR.REQUEST, removeSeminar)])

  yield all([takeLatest(ONE_SEMINAR.REQUEST, findOneSeminar)])

  yield all([takeLatest(SEND_MAIL_SEMINAR.REQUEST, mailSeminar)])

  yield all([takeLatest(TEST_MAIL_CUTOFF.REQUEST, testMailCutOffSaga)])

  yield all([takeLatest(CREATE_LIVE_MEETING.REQUEST, createZoomMeeting)])

  yield all([takeLatest(PUBLIC_SEMINAR.REQUEST, publicSeminarSaga)])

  yield all([takeLatest(UPDATE_CONTENT_EMAIL.REQUEST, updateContentMailSaga)])

  yield all([takeLatest(UPDATE_CONTENT_EMAIL_PUBLIC.REQUEST, updateContentMailPublicSaga)])

  yield all([takeLatest(SET_DEFAULT_BTN_CME_STATUS.REQUEST, setCMEBTNSaga)])

  yield all([takeLatest(TEST_CONTENT_EMAIL_PUBLIC.REQUEST, testContentMailPublicSaga)])

  yield all([takeLatest(SEND_ALL_USERS_SEMINAR_PUBLIC.REQUEST, sendAllUsersSeminarPublicSaga)])

  yield all([takeLatest(RECORD_SEMINAR.REQUEST, recordSeminarSaga)])

  yield all([takeLatest(UPDATE_KEYLIVE_SEMINAR.REQUEST, updateKeyLiveSaga)])

  yield all([takeLatest(GET_DOCUMENTS.REQUEST, getDocumentsSaga)])
  yield all([takeLatest(UPDATE_DOCUMENTS.REQUEST, updateDocumentsSaga)])
}

// findFullSeminars
export default seminarMonitor
