import React from 'react'
import styles from './styles'

const GlobalFooter = () => (
  <div className={styles.globalFooter}>
    <div className={styles.copyright}>Medstore JSC Company</div>
  </div>
)

export default GlobalFooter
