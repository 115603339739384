import React from 'react'
import { Layout } from 'antd'
import { Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { flowRight as compose } from 'lodash'
import { renderRoutes } from 'src/utils/services/router'

import GlobalHeader from '../components/GlobalHeader'
import GlobalFooter from '../components/GlobalFooter'
import SiderMenu from '../components/SiderMenu'
import { getMenuData } from '../common/menu'
import { logout } from '../containers/Auth/actions'

const { Content, Header, Footer } = Layout

class MeetingLayOut extends React.PureComponent {
  render() {
    const { auth, location, history, loading } = this.props
    const { routes } = this.props.route
    const permission = this.props.auth.permission
   
    return (
      <Layout>
        <Content style={{ margin: '24px 24px 0', height: '100%', backgroundColor: '#f0f2f5' }}>
          <Switch>{renderRoutes(routes, { hasNew: false }, auth?.user?.role?.policy)}</Switch>
        </Content>
      </Layout>
    )
  }
}

export default compose(connect(({ auth }) => ({ auth, loading: auth.loading }), { signOut: logout }))(MeetingLayOut)
