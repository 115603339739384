import { takeLatest, put, call, all, take } from 'redux-saga/effects'
import { CONFIRM_PASSWORD, FORGET_PASSWORD_ADMIN, LOGIN, LOGOUT, VERIFY_PASSWORD } from 'src/utils/types'
import { push } from 'connected-react-router'
import { getStore } from 'src/store'
import { SmileOutlined } from '@ant-design/icons'
import { confirmPasswordAPI, forgetPasswordAPI, login, verifyPassword } from 'src/utils/api'
import Modal from 'antd/lib/modal/Modal'
import { notification } from 'antd'

function* loginSaga(action) {
  const { email, password } = action.payload

  try {
    const response = yield call(login, { email, password })
    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: LOGIN.SUCCESS,
        payload: {
          token: responseJson.data.data,
          userId: responseJson.data.dataCreateToken.id,
          info: {
            data: responseJson.data.dataCreateToken,
          },
          user: {
            id: responseJson.data.dataCreateToken.id,
            email: responseJson.data.dataCreateToken.email,
            avatar: responseJson.data.dataCreateToken.avatar,
          },
          permission: responseJson.data.dataCreateToken.permission,
        },
      })
    } else {
      yield put({ type: LOGIN.FAILURE, error })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: LOGIN.FAILURE, error })
    // yield call(reject)
  }
}

function* verifyPasswordSaga(action) {
  // const { email, password } = action.payload

  try {
    const token = action.payload.token
    const data = { password: action.payload.password }

    const response = yield call(verifyPassword, { token, data })

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: VERIFY_PASSWORD.SUCCESS,
        payload: {
          token: responseJson.data,
          userId: responseJson.data.dataCreateToken.id,

          user: {
            id: responseJson.data.dataCreateToken.id,
            email: responseJson.data.dataCreateToken.email,
          },
          permission: responseJson.data.dataCreateToken.permission,
        },
      })

      yield put(push('/dashboard/users'))
    } else {
      yield put({ type: VERIFY_PASSWORD.FAILURE, error })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: VERIFY_PASSWORD.FAILURE, error })

    // yield call(reject)
  }
}

function* confirmPasswordSaga(action) {
  // const { email, password } = action.payload

  try {
    const data = action.payload

    const response = yield call(confirmPasswordAPI, data)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: CONFIRM_PASSWORD.SUCCESS,
        // payload: {
        //   token: responseJson.data,
        //   userId: responseJson.data.dataCreateToken.id,
        //   user: {
        //     id: responseJson.data.dataCreateToken.id,
        //     email: responseJson.data.dataCreateToken.email,
        //   },
        //   permission:responseJson.data.dataCreateToken.permission
        // },
      })
      notification.open({
        message: 'Đổi mật khẩu thành công.',
        // icon: <SmileOutlined style={{ color: '#108ee9' }} />,
      })
      yield put(push('/auth/login'))
    } else {
      yield put({ type: CONFIRM_PASSWORD.FAILURE, error })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: CONFIRM_PASSWORD.FAILURE, error })

    // yield call(reject)
  }
}

function* forgetPasswordSaga(action) {
  // const { email, password } = action.payload

  try {
    const data = action.payload

    const response = yield call(forgetPasswordAPI, data)

    if (response.ok) {
      const responseJson = yield response.json()
      // console.log(responseJson.data.dataCreateToken,"________________________________________________________________ responseJson.data.dataCreateToken.permission____________________ -");

      yield put({
        type: FORGET_PASSWORD_ADMIN.SUCCESS,
        // payload: {
        //   token: responseJson.data,
        //   userId: responseJson.data.dataCreateToken.id,
        //   user: {
        //     id: responseJson.data.dataCreateToken.id,
        //     email: responseJson.data.dataCreateToken.email,
        //   },
        //   permission:responseJson.data.dataCreateToken.permission
        // },
      })
      notification.open({
        message: 'Vui lòng kiểm tra email.',
        // icon: <SmileOutlined style={{ color: '#108ee9' }} />,
      })
      yield put(push('/auth/login'))
    } else {
      yield put({ type: FORGET_PASSWORD_ADMIN.FAILURE, error })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: FORGET_PASSWORD_ADMIN.FAILURE, error })

    // yield call(reject)
  }
}

function* logout() {
  window.location.href = '/'
  yield call(getStore().purge)
  yield put({ type: LOGOUT.SUCCESS })
  yield put({ type: 'RESET' })
  // we need to reload the url, so redux persist can work properly again
}

// Monitoring Sagas
function* authMonitor() {
  yield all([
    takeLatest(LOGOUT.REQUEST, logout),
    takeLatest(VERIFY_PASSWORD.REQUEST, verifyPasswordSaga),
    takeLatest(LOGIN.REQUEST, loginSaga),
    takeLatest(CONFIRM_PASSWORD.REQUEST, confirmPasswordSaga),
    takeLatest(FORGET_PASSWORD_ADMIN.REQUEST, forgetPasswordSaga),
  ])
}

export default authMonitor
