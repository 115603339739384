import Images from 'src/utils/images'
import _ from 'lodash'
import request from './request'

export const fetchAccessToken = (params) => request.post('/auth/login', params)
export const verifyPassword = (body) => request.postAdmin('/users/admin/setPassword', body)
export const login = (body) => request.post('/users/admin/login', body)
export const uploadFile = (body) => request.upload('/upload', body)
export const confirmPasswordAPI = (body) => request.post('/users/admin/confirmPassword', body)
export const forgetPasswordAPI = (body) => request.post('/users/admin/forgetPassword', body)

export const getSeminarList = (page, limit, searchText, sortField, sortOrder) =>
  request.get(
    `/seminar?page=${page}&limit=${limit}&searchText=${searchText}&sortField=${sortField}&sortOrder=${sortOrder}&isAdmin=true`,
  ) // get list
export const getSeminar = (slug) => request.get(`/seminar/detail/${slug}`)
export const createSeminar = (body) => request.post('/seminar', body)
export const createSeminarPNAPI = (body) => request.post('/seminar/admin', body)
export const updateSeminar = (body) => request.put(`/seminar/dashboard/${body.id}`, _.omit(body, ['id']))
export const deleteSeminar = (id) => request.delete(`/seminar/delete/${id}`)
export const sendMailSeminar = (id) => request.post(`/seminar/sendMail/${id}`)
export const testMailSeminar = (body) => request.post(`/seminar/cutoff/${body.email ? `test/` : ``}${body.id}`, body)
export const publicSeminar = (id) => request.post(`/seminar/public/${id}`)
export const updateContentEmailAPI = (id, body) => request.put(`/seminar/admin/content-email/${id}`, body)
export const testContentEmailPublicAPI = (id, content) =>
  request.post(`/seminar/admin/public-seminar/send-test-email/${id}`, content)
export const updateContentEmailPublicAPI = (id, content) =>
  request.put(`/seminar/admin/public-seminar/content-email/${id}`, content)
export const sendEmailAllUserSeminarPublicAPI = (id) => request.post(`/seminar/admin/public-seminar/send-email/${id}`)
export const recordSeminarAPI = (seminarId, record) => request.put(`/seminar/record/${seminarId}`, record)
export const updateKeyAPI = (seminarId, keyYoutube) =>
  request.put(`/seminar/key-youtube?seminarId=${seminarId}&keyYoutube=${keyYoutube}`)

export const publicBtnCME = (id, show) => request.put(`/seminar/show-button-cme/${id}/${show}`)

export const updateDocumentsAPI = (body) => request.post(`/seminar/dashboard/document/${body.id}`, body)
export const getDocumentsAPI = (params) => request.get(`/seminar/dashboard/get-document/${params.id}`)

export const createCategory = (body) => request.post(`/category`, body)
export const updateCategory = (body) => request.put(`/category/${body.id}`, body)
export const deleteCategory = (id) => request.delete(`/category/${id}`)
export const getCategoriesList = () => request.get(`/category`)
export const getOneCategory = (id) => request.get(`/category/${id}`)
export const createChildCategory = (body) => request.post('/child-category', body)
export const updateChildCategory = (body) => request.put(`/child-category/${body.id}`, body)
export const deleteChildCategory = (id) => request.delete(`/child-category/${id}`)

export const getReportersList = () => request.get(`/reporter`)
export const getModeratorsList = () => request.get(`/moderator`)
export const getSponsorsList = (searchText) => request.get(`/sponsor?searchText=${searchText}`)
export const getRankSponsorsList = () => request.get(`/rank-sponsor`)

export const getAllUsers = () => request.get('/users')
export const getAllDegree = () => request.get('/degree')
export const createUser = (body) => request.post('/users', body)
export const deleteUser = (param) => request.delete(`/users/${param}`)
export const updateUser = (payload) => request.put(`/users/profileId/${payload.payload}`, payload.body)
export const searchUsersApi = ({ searchText, limit, page }) =>
  request.search(`/users/search?query=${searchText}&limit=${limit}&page=${page}`)
export const forgetPassword = (body) => request.post('/users/forgetPassword', body.payload)
export const resetPasswordAPI = (email) => request.post('/users/admin/reset-password', email)
export const verifyCertificateAPI = (body) => request.put('/users/profile/verify/certificate', body)

// export const getAllUsers= () => request.get('/users')
// export const getAllDegree= () => request.get('/degree')
// export const createUser= (body) => request.post('/users',body)
// export const deleteUser= (param) => request.delete(`/users/${param}`)
// export const updateUser= (payload) =>  request.put(`/users/profileId/${payload.payload}`,payload.body)

export const getListDegree = () => request.get(`/degree`)
export const createDegree = (body) => request.post(`/degree`, body)
export const updateDegree = (param, body) => request.put(`/degree/${param}`, body)
export const deleteDegree = (param) => request.delete(`/degree/${param}`)

// =========== speaker / reporter =============
export const getListSpeaker = () => request.get('/reporter')
export const createListSpeaker = (body) => request.post('/reporter', body)
export const updateSpeaker = (payload) => request.put(`/reporter/${payload.payload}`, payload.body)
export const deleteSpeaker = (id) => request.delete(`/reporter/${id}`)

export const getContactList = (page, limit, searchText, sortField, sortOrder) =>
  request.get(`/contact?page=${page}&limit=${limit}&searchText=${searchText}&sortField=${sortField}&sortOrder=${sortOrder}`)
export const postFeedback = (body) => request.put(`/contact`, body)
export const deleteContact = (id) => request.delete(`/contact/${id}`)
export const oneContact = (id) => request.get(`/contact/${id}`)

export const getListModerator = () => request.get('/moderator/all')
export const createModerator = (body) => request.post('/moderator', body)
export const updateModerator = (payload) => request.put(`/moderator/${payload.payload}`, payload.body)
export const deleteModerator = (id) => request.delete(`/moderator/${id}`)

export const getListParticipants = ({ page, limit, seminarId, searchText, type }) =>
  request.get(`/letter/dashboard?searchText=${searchText}&page=${page}&limit=${limit}&seminarId=${seminarId}&type=${type}`)
export const createParticipantsAPI = (body) => request.post('/order/presider', body)
export const checkInParticipants = (checkIn, id) => request.put(`/letter/${checkIn}/${id}`)
export const fetchOneParticipants = (param) => request.get(`/letter/${param}`)
export const updateParticipants = (payload) => request.put(`/moderator/${payload.payload}`, payload.body)
export const updateTicket = (body, id) => request.put(`/letter/updateInfo/${id}`, body)
export const fetchSeminars = () => request.get(`/seminar`)
export const searchLetter = (body) => request.post('/letter/search', body)
export const updateCheckInCheckOut = (body) => request.post(`/zoom-live/dasboard/joiner/live/check-in/${body.ticketId}`, body)
export const checkOut = (param) => request.put(`/letter/admin/dasboard/check_out/${param}`)
export const checkOutAll = (param) => request.post(`/zoom-live/dasboard/joiner/live/check-out-all/${param}`)
export const sendMail = (idTicket) => request.post(`/seminar/dashboard/sendMail/user/${idTicket}`)
export const getCheckInMobileAPI = ({ seminarId, email, ticketId }) =>
  request.get(`/letter/get-checkin/mobile?seminarId=${seminarId}&email=${email}&ticketId=${ticketId}`)
export const updateCheckInMobileAPI = (ticketId) =>
  request.put(`/letter/v2/admin/status/checkin/${ticketId}`)
// export const deleteParticipants = (id) => request.delete(`/moderator/${id}`)

export const getOrdersList = (query) => request.get('/order/v2/admin/buyer?'+query)
export const cancelOrder = (id) => request.post(`/order/admin/cancel/order/${id}`)
export const confirmOrder = (id) => request.post(`/order/admin/confirm/order/${id}`)
export const getOneOrder = (id) => request.get(`/order/${id}`)
export const resendMailOrderAPI = (email) => request.post('/order/test/mail/order', email)
export const getSeminarExportAPI = () => request.get('/seminar/all')
export const getSeminarAll = () => request.get('/seminar/all')
export const callExportOrderAPI = (id) => request.get(`/order/excel/${id}`)

export const fetchDataAdminAPI = (token) => request.postAdmin('/users/listAdmin', token)
export const createUserAdminAPI = (data) => request.post('/users/signUp/superAdmin/dashboard', data)
export const searchUserAdminAPI = (payload) => request.get(`/users/superAdmin/search?query=${payload.data}`, payload)
export const updateUserAdminAPI = (payload) => request.putAdmin(`/users/superAdmin/update`, payload)
export const checkUserAdminAPI = (query) => request.get(`/users/search?query=${query}`)
export const updateUserToAdminAPI = (body) => request.put(`/users/signUp/superAdmin/dashboard`, body)
export const upgradeAccountPermissionAdminAPI = (body) => request.put('/users/dropPermission/dashboard', body)
export const fetchCMEAPI = () => request.get('/cme/dashboard')

export const fetchListRole = (token) => request.getAdmin('/role', token)
export const updatePermissionAdminAPI = (data) => request.putAdmin('/permission', data)

export const createSponsor = (body) => request.post(`/sponsor`, body)
export const updateCSponsor = (body) => request.put(`/sponsor/${body.id}`, body)
export const deleteSponsor = (id) => request.delete(`/sponsor/${id}`)
export const getOneSponsor = (id) => request.get(`/sponsor/${id}`)

export const getOneUser = (email) => request.get(`/users/admin/find-email/${email}`)
export const mergeUser = (body) => request.post(`/users/admin/merge-user`, body)

export const getListCme = ({ searchText, seminarId, paging }) =>
  request.get(`/cme/seminar?searchText=${searchText}&seminarId=${seminarId}&page=${paging.page}&limit=${paging.limit}`)

export const getCmeIssueList = ({ seminarId, searchText, page, limit }) =>
  request.get(`/zoom-live/admin/${seminarId}?search=${searchText}&page=${page}&limit=${limit}`)

export const createCme = (seminarId) => request.post(`/seminar/provide-centi/cme/${seminarId}`)

export const printCMEAPI = ({ seminarId, userId, ticketId }) =>
  request.get(`/ticket/admin/get-cme-one-user?ticketId=${ticketId}&userId=${userId}&seminarId=${seminarId}`)

export const createOneCme = ({ seminarId, ticketId }) => request.get(`/pdf/v2/canvas/${seminarId}?ticketId=${ticketId}`)
export const statisticsCme = ({ seminarId }) => request.get(`/letter/dashboard/quantity-onl-off?seminarId=${seminarId}`)

export const createLiveMeeting = () => request.post(`/zoom-live`)

export const getSignatureLiveMeeting = (body) => request.post(`/zoom-live/create-signature/admin`, body)

export const postCoordinatesAPI = (body) => request.post('/coordinates-tickets', body)
export const fetchCoordinatesAPI = (param) => request.get(`/coordinates-tickets/${param}`)

export const createNewsCategory = (body) => request.post(`/news-category`, body)
export const updateNewsCategory = (body) => request.put(`/news-category/${body.id}`, body)
export const deleteNewsCategory = (id) => request.delete(`/news-category/${id}`)
export const getNewsCategoriesList = (searchText) => request.get(`/news-category?searchText=${searchText}`)
export const getOneNewsCategory = (id) => request.get(`/news-category/${id}`)

export const createNews = (body) => request.post(`/news`, body)
export const updateNews = (body) => request.put(`/news/${body.id}`, body)
export const deleteNews = (id) => request.delete(`/news/${id}`)
export const getNewsList = (searchText) => request.get(`/news?searchText=${searchText}`)
export const getOneNews = (id) => request.get(`/news/${id}`)
export const postCoordinatesCMEAPI = (body) => request.post('/coordinates-cmes', body)
export const fetchCoordinatesCMEAPI = (param) => request.get(`/coordinates-cmes/${param}`)

export const createCoupons = (body) => request.post(`/coupon?type=${body.type}`, body)
export const updateCoupon = (body) => request.put(`/coupon/${body.id}`, body)
export const deleteCoupon = (id) => request.delete(`/coupon/${id}`)
export const getCouponsList = (searchText) => request.get(`/coupon/admin?searchText=${searchText}`)
export const getOneCoupon = (id) => request.get(`/coupon/${id}`)
/* export const getKindOfCouponsList = () => request.get(`/coupon?searchText=${searchText}`)
export const getKindOfDiscountList = () => request.get(`/coupon?searchText=${searchText}`) */
export const fectTemplateNotification = () => request.get(`/template-notification1`)
export const createTemplateNotification = (data) => request.post(`/template-notification1`, data)
export const sendNotification = (data) => request.post(`/push-notification/admin`, data)
export const sendSeminarNotification = (data) => request.post(`/order/push-notification/admin`, data)
export const searchSeminar = (data, keyword) => request.post(`/seminar/searchNoti/admin?key_word=${keyword}`, data)
export const deleteNotificationAPI = (id) => request.delete(`/template-notification1/${id}`)

export const updateFooter = (body) => request.patch(`/footer/${body.id}`, body)
export const getFooterList = () => request.get(`/footer`)
export const getOneFooter = (id) => request.get(`/footer/${id}`)

export const panelists = (slug) => request.get(`/zoom-live/panel/${slug}`)
export const createPanelist = (slug, body) => request.post(`/zoom-live/panel/${slug}`, body)
export const deletePanelist = (slug, body) => request.delete(`/zoom-live/panel/${slug}/${body.email}`, body)

export const createPoll = (body, slug) => request.post(`/zoom-live/poll/${slug}`, body)
export const updatePoll = (slug, body) => request.put(`/zoom-live/poll/${slug}`, body)
export const deletePoll = (slug, id) => request.delete(`/zoom-live/poll/${slug}/${id}`)
export const getPollList = (slug) => request.get(`/zoom-live/poll/${slug}`)
export const getOnePoll = (id, slug) => request.get(`/zoom-live/poll/onePoll/${slug}/${id}`)

export const asyncDownloadImage = (imageUrl) =>
  new Promise((resolve) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', imageUrl, true)
    xhr.responseType = 'blob'
    xhr.onload = function () {
      if (this.status === 200) {
        const blob = new Blob([this.response], { type: 'image/png' })
        Images.resizeImage(blob).then((result) => {
          resolve(URL.createObjectURL(result))
        })
      }
    }

    xhr.send()
  })
