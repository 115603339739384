import moment from 'moment'
import { all, put, takeLatest, call } from 'redux-saga/effects'
import {
  checkUserAdminAPI,
  createUserAdminAPI,
  fetchDataAdminAPI,
  fetchListRole,
  getAllDegree,
  searchUserAdminAPI,
  // searchUsers, 
  updatePermissionAdminAPI,
  updateUserAdminAPI,
  updateUserToAdminAPI,
  upgradeAccountPermissionAdminAPI,
} from 'src/utils/api'
import {
  CREATE_ACC_ADMIN,
  CREATE_ACC_ADMIN_BY_USER,
  FETCH_DATA_ADMIN,
  SEARCH_ADMIN,
  SEARCH_USER_ADMIN,
  SEARCH_USER_PAGE_ADMIN,
  UPDATE_PERMISSION_ADMIN,
  UPDATE_USER_ADMIN,
  UPGRADE_ACC_ADMIN_BY_USER,
} from 'src/utils/types'

function* fetchDataSaga(data) {
  try {
    const token = data.payload
    const response = yield call(fetchDataAdminAPI, { token })
    const responseRole = yield call(fetchListRole, { token: token })
    const responseDegree = yield call(getAllDegree)

    if (response.ok) {
      const responseJson = yield response.json()
      const responseRoleJson = yield responseRole.json()
      const responseDegreeJson = yield responseDegree.json()

      responseJson.data.forEach((item, key) => {
        item.key = key + 1
        item.fullName = item.lastName + ' ' + item.firstName
        item.dateOfBirth = moment(item.dateOfBirth).format('MM-DD-YYYY')
      })
      yield put({
        type: FETCH_DATA_ADMIN.SUCCESS,
        payload: {
          listAdmins: responseJson,
          listRole: responseRoleJson,
          listDegree: responseDegreeJson,
          status: 200,
        },
      })
    } else {
      yield put({ type: FETCH_DATA_ADMIN.FAILURE, status: 500 })
    }
  } catch (error) {
    yield put({ type: FETCH_DATA_ADMIN.FAILURE, status: 500 })
  }
}

function* updatePermissionAdminSaga(payload) {
  try {
    const token = payload.payload
    const data = payload.data

    const response = yield call(updatePermissionAdminAPI, { token, data })
    if (response.ok) {
      // const responseJson = yield response.json()

      yield put({
        type: UPDATE_PERMISSION_ADMIN.SUCCESS,
        payload: {
          status: 200,
        },
      })
    } else {
      yield put({ type: UPDATE_PERMISSION_ADMIN.FAILURE, status: 500 })
    }
  } catch (error) {
    yield put({ type: UPDATE_PERMISSION_ADMIN.FAILURE, status: 500 })
  }
}

function* createUserAdminSaga(payload) {
  try {
    const data = payload.data
    const response = yield call(createUserAdminAPI, data)
    if (response.ok) {
      yield put({
        type: CREATE_ACC_ADMIN.SUCCESS,
        payload: {
          status: 200,
        },
      })
    } else {
      yield put({ type: CREATE_ACC_ADMIN.FAILURE, status: 500 })
    }
  } catch (error) {
    yield put({ type: CREATE_ACC_ADMIN.FAILURE, status: 500 })
  }
}

function* searchAdminSaga(payload) {
  try {
    const token = payload.data
    const data = payload.payload

    const response = yield call(searchUserAdminAPI, { token, data })
    if (response.ok) {
      const responseJson = yield response.json()

      responseJson.data.forEach((item, key) => {
        item.key = key + 1
        item.fullName = item.lastName + ' ' + item.firstName
        item.dateOfBirth = moment(item.dateOfBirth).format('MM-DD-YYYY')
      })

      yield put({
        type: SEARCH_ADMIN.SUCCESS,
        payload: {
          listAdmins: responseJson,
          status: 200,
        },
      })
    } else {
      yield put({ type: SEARCH_ADMIN.FAILURE, status: 500 })
    }
  } catch (error) {
    yield put({ type: SEARCH_ADMIN.FAILURE, status: 500 })
  }
}

function* updateAdminSaga(payload) {
  try {
    const token = payload.payload
    const data = payload.data

    const response = yield call(updateUserAdminAPI, { token, data })
    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: UPDATE_USER_ADMIN.SUCCESS,
        payload: {
          // updateUserAdmins:responseJson,
          status: 200,
        },
      })
    } else {
      yield put({ type: UPDATE_USER_ADMIN.FAILURE, status: 500 })
    }
  } catch (error) {
    yield put({ type: UPDATE_USER_ADMIN.FAILURE, status: 500 })
  }
}

function* checkUserAdminSaga(param) {
  try {
    const keyword = param.payload

    const response = yield call(checkUserAdminAPI, keyword)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: SEARCH_USER_ADMIN.SUCCESS,
        payload: {
          searchUser: responseJson,
        },
      })
    } else {
      yield put({
        type: SEARCH_USER_ADMIN.FAILURE,
        payload: {
          searchUser: {
            status: 500,
          },
        },
      })
    }
  } catch (error) {
    yield put({
      type: SEARCH_USER_ADMIN.FAILURE,
      payload: {
        searchUser: {
          status: 500,
        },
      },
    })
  }
}

function* searchUserPageAdminSaga(payload) {
  try {
    const response = yield call(searchUsers, payload)

    if (response.ok) {
      const responseJson = yield response.json()
      responseJson.data.forEach((item, key) => {
        item.key = key + 1
        item.fullName = item.lastName + ' ' + item.firstName
      })

      yield put({
        type: SEARCH_USER_PAGE_ADMIN.SUCCESS,
        payload: {
          listUsers: responseJson,
        },
      })
    } else {
      yield put({ type: SEARCH_USER_PAGE_ADMIN.FAILURE })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: SEARCH_USER_PAGE_ADMIN.FAILURE })
  }
}

function* updateFromUserToAdminSaga(payload) {
  try {
    const token = payload.payload
    const data = payload.data

    const response = yield call(updateUserToAdminAPI, data)
    if (response.ok) {
      // const responseJson = yield response.json()

      yield put({
        type: CREATE_ACC_ADMIN_BY_USER.SUCCESS,
        payload: {
          status: 200,
        },
      })
    } else {
      yield put({ type: CREATE_ACC_ADMIN_BY_USER.FAILURE, status: 500 })
    }
  } catch (error) {
    console.error(error, 'eoor')
    yield put({ type: CREATE_ACC_ADMIN_BY_USER.FAILURE, status: 500 })
  }
}

function* upgradeAccountPermissionAdminSaga(payload) {
  try {
    const data = payload.payload

    const response = yield call(upgradeAccountPermissionAdminAPI, data)
    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: UPGRADE_ACC_ADMIN_BY_USER.SUCCESS,
        payload: {
          status: 200,
        },
      })
    } else {
      yield put({ type: UPGRADE_ACC_ADMIN_BY_USER.FAILURE, status: 500 })
    }
  } catch (error) {
    yield put({ type: UPGRADE_ACC_ADMIN_BY_USER.FAILURE, status: 500 })
  }
}

function* authMonitor() {
  yield all([
    takeLatest(FETCH_DATA_ADMIN.REQUEST, fetchDataSaga),
    takeLatest(UPDATE_PERMISSION_ADMIN.REQUEST, updatePermissionAdminSaga),
    takeLatest(CREATE_ACC_ADMIN.REQUEST, createUserAdminSaga),
    takeLatest(SEARCH_ADMIN.REQUEST, searchAdminSaga),
    takeLatest(UPDATE_USER_ADMIN.REQUEST, updateAdminSaga),
    takeLatest(SEARCH_USER_ADMIN.REQUEST, checkUserAdminSaga),
    takeLatest(SEARCH_USER_PAGE_ADMIN.REQUEST, searchUserPageAdminSaga),
    takeLatest(CREATE_ACC_ADMIN_BY_USER.REQUEST, updateFromUserToAdminSaga),
    takeLatest(UPGRADE_ACC_ADMIN_BY_USER.REQUEST, upgradeAccountPermissionAdminSaga),
  ])
}

export default authMonitor
