import React from 'react'
import { Provider } from 'react-redux'
import { Redirect, Switch } from 'react-router-dom'
import momentTimezone from 'moment-timezone'
import { PersistGate } from 'redux-persist/integration/react'
import { configureStore, getStore } from 'src/store'
import { publicRoutesArray, dashboardRoutesArray, meetingRoutesArray } from 'src/routes'
import { renderRoutes } from 'src/utils/services/router'
import { ConnectedRouter } from 'connected-react-router'
import history from 'src/utils/history'

// Stylesheets
import './styles.css'

import ScrollToTop from './ScrollToTop'
import { isExpToken } from 'src/utils/helpers'

momentTimezone.tz.setDefault('Asia/Ho_Chi_Minh')

const { store, persistor } = configureStore()

const menuData = [
  {
    name: 'Quản lý sự kiện',
    path: 'seminars',
    role: 1,
  },
  {
    name: 'Quản lý đơn hàng',
    path: 'order',
    role: 2,
  },
  {
    name: 'Đại biểu tham dự',
    path: 'participants',
    role: 3,
  },
  {
    name: 'CME đã in',
    path: 'cme',
    role: 4,
  },
  {
    name: 'Coupon',
    path: 'coupon',
    role: 5,
  },
  {
    name: 'Thông báo',
    path: 'notification',
    role: 6,
  },
  {
    name: 'Tin tức',
    path: 'news',
    role: 7,
  },
  {
    name: 'Phản hồi từ Website',
    path: 'contact',
    role: 8,
  },
  {
    name: 'Danh mục sự kiện',
    path: 'category',
    role: 9,
  },
  {
    name: 'Danh sách chủ tọa',
    path: 'presider',
    role: 10,
  },
  {
    name: 'Báo cáo viên',
    path: 'speaker',
    role: 11,
  },
  {
    name: 'Danh sách nhà tài trợ',
    path: 'sponsor',
    role: 12,
  },
  {
    name: 'Danh mục tin tức',
    path: 'news-category',
    role: 13,
  },
  {
    name: 'Danh sách HH/HV',
    path: 'degree',
    role: 14,
  },
  {
    name: 'Footer',
    path: 'footer',
    role: 15,
  },
  {
    name: 'Người dùng',
    path: 'users',
    role: 16,
  },
  {
    name: 'Tài khoản Admin',
    path: 'admins',
    role: 17,
  },
  {
    name: 'Chỉnh sửa CME',
    path: 'designCME',
    role: 18,
  },
  {
    name: 'Chỉnh sửa ticket',
    path: 'designTicket',
    role: 19,
  },
]

class App extends React.PureComponent {
  state = { ready: false }
  state = { pathURL: window.location.pathname.split('/')[2] }

  componentDidMount() {}

  componentDidUpdate() {
    const URL = window.location.pathname.split('/')[2]
    const { auth } = getStore().getState()
    const location = auth.permission
    const listRole = []
    if (location) {
      location.map((e) => {
        listRole.push(e.role)
      })
      listRole.push('Chỉnh sửa CME');
      listRole.push('Chỉnh sửa ticket');
      const listRoleTemp = []
      menuData.map((i) => {
        listRole.map((e) => {
          if (i.name == e) {
            listRoleTemp.push(i.path)
          }
        })
      })
      const isHas = listRoleTemp.includes(URL)
      if (!isHas) {
        history.push(`/dashboard/${listRole[0]}`)
      }
    }
  }

  
  render() {
    return (
      <Provider store={store}>
        <PersistGate onBeforeLift={() => this.setState({ ready: true })} loading={<div>Loading...</div>} persistor={persistor}>
          <ConnectedRouter history={history}>
            {this.state.ready && (
              <ScrollToTop>
                <Switch>
                  {renderRoutes(publicRoutesArray)}
                  {renderRoutes(dashboardRoutesArray)}
                  {renderRoutes(meetingRoutesArray)}
                  <Redirect to="/dashboard" />
                </Switch>
              </ScrollToTop>
            )}
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    )
  }
}

export default App


// {getStore().getState().auth.token && !isExpToken(getStore().getState().auth.token) ? (
//   <Redirect to="/dashboard/seminars" />
// ) : (
//   <Redirect to="/auth/login" />
//  )}