export const S3_FOLDER = ''
// export const VERSION = 4
// export const UNKNOWN_ERROR = 'Lỗi không xác định'
// export const SEMINAR_KEY = {
//   subTitle: 'Kỳ',
//   title: 'Tiêu đề',
//   sqd: 'Số quyết định',
//   slot: 'Tổng số thư mời',
//   price: 'Giá',
//   location: 'Nơi tổ chức',
//   numberTraining: 'Số tiết đào tạo tương đương',
//   dateFrom: 'Ngày tổ chức',
//   dateTo: 'Ngày ký',
//   timeFrom: 'Từ giờ',
//   timeTo: 'Đến giờ',
//   description: 'Quyền lợi',
//   isSpecial: 'Nổi bật',
//   thumbnailUrl: 'Ảnh đại diện',
//   invationUrl: 'Mẫu thư mời',
// }
// export const SPEAKER_KEY = {
//   academicId: 'Học vị',
//   fullname: 'Họ tên',
//   jobTitle: 'Chức vụ',
//   birthday: 'Ngày sinh',
// }
// export const CHAIRMAN_KEY = { ...SPEAKER_KEY }
// export const STUDENT_KEY = {
//   phone: 'Điện thoại',
//   academicId: 'Học vị',
//   fullname: 'Họ tên',
//   email: 'Email',
//   workplace: 'Nơi công tác',
//   birthday: 'Ngày sinh',
// }
// export const BOOKING_KEY = {
//   ...STUDENT_KEY,
//   seminarId: 'Hội nghị',
// }
// export const PAYMENT_TYPE = {
//   1: 'Tiền mặt',
//   2: 'Chuyển khoản',
//   3: 'Thư mời',
// }
// export const SPONSOR_TYPE = {
//   EMPTY: '',
//   DIAMOND: 'Tài trợ kim cương',
//   GOLD: 'Tài trợ vàng',
//   SILVER: 'Tài trợ bạc',
//   BRONZE: 'Tài trợ đồng',
// }
