import React from 'react';
import styles from './styles.css'
const Image = () => {
    return (
        <div className={styles.container}>
            <img src='/assets/images/Image_login.svg'/>
        </div>
    );
};

export default Image;
