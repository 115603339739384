import { takeLatest, put, call, all } from 'redux-saga/effects'
import {
  LIST_COUPONS,
  CREATE_COUPON,
  ONE_COUPON,
  UPDATE_COUPON,
  DELETE_COUPON,
  LIST_SEMINARS_COUPON,
} from '../../../utils/types'
import { getCouponsList, createCoupons, getOneCoupon, updateCoupon, deleteCoupon, getSeminarList } from 'src/utils/api'
import { reject } from 'lodash'

function* findAllCoupons({ payload: searchText }) {
  try {
    const response = yield call(getCouponsList, searchText)

    if (response.ok) {
      const responseJson = yield response.json()

      if (!responseJson.message) {
        yield put({
          type: LIST_COUPONS.SUCCESS,
          payload: {
            couponsList: responseJson,
          },
        })
      } else {
        yield put({ type: LIST_COUPONS.FAILURE })
        yield call(reject)
      }
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)

    yield call(reject)
  }
}

function* findAllSeminars({ payload: searchText }) {
  try {
    const response = yield call(getSeminarList, 1, 10000000, '')

    if (response.ok) {
      const responseJson = yield response.json()
      if (!responseJson.message) {
        yield put({
          type: LIST_SEMINARS_COUPON.SUCCESS,
          payload: {
            seminarsList: responseJson.data,
          },
        })
      } else {
        yield put({ type: LIST_SEMINARS_COUPON.FAILURE })
        yield call(reject)
      }
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)

    yield call(reject)
  }
}

function* findAllUsers({ payload: searchText }) {
  try {
    const response = yield call(getCouponsList, searchText)

    if (response.ok) {
      const responseJson = yield response.json()

      if (!responseJson.message) {
        yield put({
          type: LIST_COUPONS.SUCCESS,
          payload: {
            couponsList: responseJson,
          },
        })
      } else {
        yield put({ type: LIST_COUPONS.FAILURE })
        yield call(reject)
      }
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)

    yield call(reject)
  }
}

function* addCoupon({ payload: body, handleNotification }) {
  try {
    const response = yield call(createCoupons, body)
    if (response.ok) {
      yield put({
        type: CREATE_COUPON.SUCCESS,
      })
      handleNotification('Thêm thành công', 'success')
    } else if (response.status === 409) {
      yield put({ type: CREATE_COUPON.FAILURE })
      handleNotification('Coupon bạn vừa tạo trùng tên với coupon đã có', 'error')
    } else {
      throw new Error('Invalid login request')
    }
  } catch (error) {
    yield put({ type: CREATE_COUPON.FAILURE, error })
    handleNotification('Thêm thất bại', 'error')
    yield call(reject)
  }
}

function* findOneCoupon({ payload: id, handleOpenModalUpdate }) {
  try {
    const response = yield call(getOneCoupon, id)

    if (response.ok) {
      const responseJson = yield response.json()
      yield put({
        type: ONE_COUPON.SUCCESS,
        payload: {
          oneCoupon: responseJson,
        },
      })
      if (handleOpenModalUpdate) {
        handleOpenModalUpdate()
      }
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: ONE_NEWS.FAILURE, error })
    yield call(reject)
  }
}

function* editCoupon({ payload: body, handleNotification, setIsModalUpdate }) {
  try {
    const response = yield call(updateCoupon, body)

    if (response.ok) {
      const responseJson = yield response.json()
      if (responseJson.message) {
        yield put({ type: UPDATE_COUPON.FAILURE })
        handleNotification(responseJson.message, 'error')
      } else {
        yield put({
          type: UPDATE_COUPON.SUCCESS,
        })

        handleNotification('Sửa thành công', 'success')
        setIsModalUpdate(false)
      }
    } else {
      handleNotification('Sửa thất bại', 'error')
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_COUPON.FAILURE, error })
    handleNotification('Sửa thất bại')
    yield call(reject)
  }
}

function* removeCoupon({ payload: id, handleNotification }) {
  try {
    const response = yield call(deleteCoupon, id)

    if (response.ok) {
      const responseJson = yield response.json()
      yield put({
        type: DELETE_COUPON.SUCCESS,
      })
      handleNotification('Xoá thành công', 'success')
    } else {
      handleNotification('Xoá thất bại', 'error')
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: DELETE_COUPON.FAILURE, error })
    handleNotification('Xoá thất bại', 'error')
    yield call(reject)
  }
}

// Monitoring Sagas
function* newsMonitor() {
  yield all([takeLatest(LIST_COUPONS.REQUEST, findAllCoupons)])

  yield all([takeLatest(CREATE_COUPON.REQUEST, addCoupon)])

  yield all([takeLatest(ONE_COUPON.REQUEST, findOneCoupon)])

  yield all([takeLatest(UPDATE_COUPON.REQUEST, editCoupon)])

  yield all([takeLatest(DELETE_COUPON.REQUEST, removeCoupon)])

  yield all([takeLatest(LIST_SEMINARS_COUPON.REQUEST, findAllSeminars)])
}

export default newsMonitor
