import { takeLatest, put, call, all } from 'redux-saga/effects'
import { createSponsor, deleteSponsor, getOneSponsor, getSponsorsList, updateCSponsor } from 'src/utils/api'
import { CREATE_SPONSOR, DELETE_SPONSOR, FETCH_SPONSOR, LIST_SPONSORS, UPDATE_SPONSOR } from '../../../utils/types'
import { commonNotify } from 'src/utils/helpers'

function* findAllSponsor({ payload: searchText }) {
  try {
    const response = yield call(getSponsorsList, searchText)
    if (response.ok) {
      const responseJson = yield response.json()
      yield put({
        type: LIST_SPONSORS.SUCCESS,
        payload: {
          sponsors: responseJson,
          totalCount: responseJson.length,
        },
      })
    } else {
      throw new Error('Error: Load sponsors')
    }
  } catch (error) {
    console.error(error)
    yield put({ type: LIST_SPONSORS.FAILURE, error })
  }
}

function* removeSponsor({ payload: id, fetchSponsor }) {
  try {
    const response = yield call(deleteSponsor, id)
    if (response.ok) {

      commonNotify({ message: 'Đã xoá', type: 'success' })
      yield put({
        type: DELETE_SPONSOR.SUCCESS,
      })
      fetchSponsor()

    } else {
      throw new Error('Error: Remove sponsor')
    }
  } catch (error) {
    console.error(error)
    commonNotify({ message: 'Xoá không thành công', type: 'error' })
    yield put({ type: DELETE_SPONSOR.FAILURE, error })
  }
}

function* oneSponsor({ payload: id }) {
  try {
    const response = yield call(getOneSponsor, id)
    if (response.ok) {
      const responseJson = yield response.json()
      yield put({
        type: FETCH_SPONSOR.SUCCESS,
        payload: {
          oneSponsor: responseJson,
        },
      })
    } else {
      throw new Error('Error: Get one sponsor')
    }
  } catch (error) {
    console.error(error)
    yield put({ type: FETCH_SPONSOR.FAILURE, error })
  }
}

function* addSponsor({ payload: body, fetchSponsor }) {
  try {
    const response = yield call(createSponsor, body)
    if (response.ok) {
      commonNotify({ message: 'Đã thêm nhà tài trợ', type: 'success' })
      yield put({
        type: CREATE_SPONSOR.SUCCESS,
      })
      fetchSponsor()
    } else {
      throw new Error('Error: Add sponsor')
    }
  } catch (error) {
    console.error(error)
    commonNotify({ message: 'Thêm thất bại', type: 'error' })
    yield put({ type: CREATE_SPONSOR.FAILURE, error })
  }
}

function* editSponsor({ payload: body, fetchSponsor }) {
  try {
    const response = yield call(updateCSponsor, body)
    if (response.ok) {
      commonNotify({ message: 'Cập nhật thành công', type: 'success' })
      yield put({
        type: UPDATE_SPONSOR.SUCCESS,
      })
      fetchSponsor()
    } else {
      throw new Error('Error: Edit sponsor')
    }
  } catch (error) {
    console.error(error)
    commonNotify({ message: 'Cập nhật thất bại', type: 'error' })
    yield put({ type: UPDATE_SPONSOR.FAILURE, error })
  }
}

// Monitoring Sagas
function* sponsorMonitor() {
  yield all([takeLatest(LIST_SPONSORS.REQUEST, findAllSponsor)])

  yield all([takeLatest(DELETE_SPONSOR.REQUEST, removeSponsor)])

  yield all([takeLatest(FETCH_SPONSOR.REQUEST, oneSponsor)])

  yield all([takeLatest(CREATE_SPONSOR.REQUEST, addSponsor)])

  yield all([takeLatest(UPDATE_SPONSOR.REQUEST, editSponsor)])
}

export default sponsorMonitor
