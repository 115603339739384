import { isUrl } from 'src/utils/url'
import { BsFillCalendarEventFill, BsInboxesFill, BsPersonBadgeFill, BsFileCheckFill } from 'react-icons/bs'
import {
  RiCoupon2Fill,
  RiNewspaperFill,
  RiFeedbackFill,
  RiFileList2Fill,
  RiLayout2Fill,
  RiAdminFill,
  RiUserStarFill,
} from 'react-icons/ri'
import { FaBell, FaUserTie, FaUserFriends, FaNewspaper, FaAward } from 'react-icons/fa'
import { BiDonateHeart } from 'react-icons/bi'

const menuData = [
  {
    name: 'Quản lý sự kiện',
    path: 'seminars',
    icon: 'icon-seminar',
    role: 1,
    iconComp: BsFillCalendarEventFill
  },
  {
    name: 'Quản lý đơn hàng',
    path: 'order',
    icon: 'icon-manager-order',
    role: 2,
    iconComp: BsInboxesFill,
  },
  {
    name: 'Quản lý thư mời',
    path: 'participants',
    icon: 'icon-delegate',

    role: 3,
    iconComp: BsPersonBadgeFill,
  },
  {
    name: 'Quản lý CME',
    path: 'cme',
    icon: 'icon-list',

    role: 4,
    iconComp: BsFileCheckFill,
  },
  {
    name: 'Quản lý khuyến mãi (coupon)',
    path: 'coupon',
    icon: 'icon-coupon',

    role: 5,
    iconComp: RiCoupon2Fill,
  },
  {
    name: 'Quản lý thông báo',
    path: 'notification',
    icon: 'icon-noti',
    role: 6,
    iconComp: FaBell,
  },
  {
    name: 'Quản lý tin tức',
    path: 'news',
    icon: 'icon-cme',
    role: 7,
    iconComp: RiNewspaperFill,
  },
  {
    name: 'Phản hồi từ Website',
    path: 'contact',
    icon: 'icon-contact',
    role: 8,
    iconComp: RiFeedbackFill,
  },
  // {
  //   name: 'Danh mục sự kiện',
  //   path: 'category',
  //   icon: 'icon-category-seminar',
  //   role: 9,
  //   iconComp: RiFileList2Fill,
  // },
  {
    name: 'Danh sách chủ tọa',
    path: 'presider',
    icon: 'icon-presider',
    role: 10,
    iconComp: FaUserTie,
  },
  {
    name: 'Danh sách báo cáo viên',
    path: 'speaker',
    icon: 'icon-speaker',
    role: 11,
    iconComp: FaUserFriends,
  },
  {
    name: 'Danh sách nhà tài trợ',
    path: 'sponsor',
    icon: 'icon-sponsor',
    role: 12,
    iconComp: BiDonateHeart,
  },
  {
    name: 'Danh mục tin tức',
    path: 'news-category',
    icon: 'icon-news',
    role: 13,
    iconComp: FaNewspaper,
  },
  {
    name: 'Danh sách HH/HV',
    path: 'degree',
    icon: 'icon-degree',
    role: 14,
    iconComp: FaAward,
  },
  // {
  //   name: 'Footer',
  //   path: 'footer',
  //   icon: 'icon-footer',
  //   role: 15,
  //   iconComp: RiLayout2Fill,
  // },
  {
    name: 'Quản lý người dùng',
    path: 'users',
    icon: 'icon-users',
    role: 16,
    iconComp: RiUserStarFill,
  },
  {
    name: 'Tài khoản Admin',
    path: 'admins',
    icon: 'icon-admin',
    role: 17,
    iconComp: RiAdminFill,
  },
]

function formatter(permission, data, parentPath = '/dashboard/', parentRole) {
  const dataTemp = []
  if (typeof permission != 'undefined') {
    Promise.all(
      permission.map((item) => {
        data.map((item_child) => {
          if (item.roleId === item_child.role) {
            dataTemp.push(item_child)
          }
        })
      }),
    )
  }

  return dataTemp.map((item) => {
    let { path } = item
    if (!isUrl(path)) {
      path = parentPath + item.path
    }
    const result = {
      ...item,
      path,
      role: item.role || parentRole,
    }

    if (item.children) {
      result.children = formatter(item.children, `${parentPath}${item.path}/`, item.role)
    }

    return result
  })
}
export const menu = menuData
export const getMenuData = (permission) => formatter(permission, menuData)
