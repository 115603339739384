import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Button, Input, Modal, Row, Col } from 'antd'
import { Icon } from '@ant-design/compatible'
import Cookies from 'universal-cookie'
import { auth, dropAction } from './actions'
import styles from './styles'
import Image from './Components/Image'
import { Link } from 'react-router-dom'
import { isExpToken } from 'src/utils/helpers'
import history from 'src/utils/history'
class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      isHidden: true,
    }
  }

  componentDidMount() {
    const { token } = this.props
    if (token && !isExpToken(token)) {
      history.push('/dashboard')
      return;
    }

  }

  handleSubmit = async (values) => {
    try {
      await this.onSubmit(values.email, values.password)
    } catch (error) {
      Modal.error({ title: 'Đăng nhập thất bại', content: 'Vui lòng thử lại' })
    }
  }

  componentDidUpdate() {
    const { token } = this.props
    if (token && !isExpToken(token)) {
      history.push('/dashboard')
      return;
    }

    if (this.props.statusLogin === 2) {
      Modal.error({ title: 'Đăng nhập thất bại', content: 'Vui lòng thử lại' })
      dropAction()
    }

    if (this.props.statusSendmailForgetPassword === 2) {
      Modal.error({ title: 'Gửi mail thất bại', content: 'Email này chưa đăng ký' })
      dropAction()
    } else if (this.props.statusSendmailForgetPassword === 2) {
      Modal.success({ title: 'Gửi mail thành công', content: 'Vui lòng check email của bạn !' })
      dropAction()
    }
  }

  onSubmit = (email, password) => this.props.auth({ email, password })

  handleChange = (e) => {
    if (e.target.value.length > 0) {
      this.state.isHidden = false
    }
    // this.state.email = e.target.value
  }

  render() {
    const { loading } = this.props

    return (
      <div className={styles.container}>
        <Row>
          <Col sm={24} md={12}>
            <div>
              <Image />
            </div>
          </Col>
          <Col sm={24} md={12}>
            <div className={styles.main}>
              <div className={styles.login}>
                <div className={styles.header}>Đăng nhập</div>
                <Form onFinish={this.handleSubmit} className="login-form">
                  <label className={styles.label}>
                    <b>Email</b>
                  </label>
                  <Form.Item name="email" rules={[{ required: true, message: 'Vui lòng nhập email' }]}>
                    <Input
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      size="large"
                    />
                  </Form.Item>
                  <label className={styles.label}>
                    <b>Password: </b>
                  </label>

                  <Form.Item name="password" rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}>
                    <Input.Password
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      name="password"
                      placeholder="Password"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item>
                    <div className={styles.forgetPassword}>
                      <Link to="/auth/forgetPassword">Quên mật khẩu ?</Link>
                    </div>
                  </Form.Item>

                  <Form.Item>
                    <Button size="large" className={styles.submit} type="primary" htmlType="submit">
                      {loading ? <Icon type="loading" /> : 'Đăng nhập'}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect((state) => state.auth, { auth, dropAction })(LoginForm)
