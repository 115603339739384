import { takeLatest, put, call, all } from 'redux-saga/effects'
import {
  getOrdersList,
  getOneOrder,
  cancelOrder,
  confirmOrder,
  resendMailOrderAPI,
  getSeminarExportAPI,
  callExportOrderAPI,
} from 'src/utils/api'
import { reject } from 'lodash'
import { commonNotify } from 'src/utils/helpers'
import {
  LIST_ORDERS,
  ONE_ORDER,
  CANCEL_ORDER,
  CONFIRM_ORDER,
  LIST_ORDERS_FULL,
  RESEND_MAIL_ORDER,
  GET_SEMINAR_EXPORT,
  CALL_EXPORT_ORDER,
} from '../../../utils/types'

function* findAllOrders({ payload }) {
  try {
    console.log("payload:>>>", payload);
    const response = yield call(getOrdersList, payload)
    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: LIST_ORDERS.SUCCESS,
        payload: {
          page: responseJson.page,
          limit: responseJson.limit,
          data: responseJson,
          type: payload.type,
          seminarId: payload.seminarId,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }

    // yield call(resolve)
    // yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: LIST_ORDERS.FAILURE, error })
    yield call(reject)
  }
}

function* findFullOrders({ payload: { createWS, payload } }) {
  try {
    const response = yield call(getOrdersList, {
      ...payload,
      ...payload.paging,
    })

    if (response.ok) {
      const responseJson = yield response.json()
      createWS(responseJson.All.data)
      yield put({
        type: LIST_ORDERS_FULL.SUCCESS,
        payload: {
          page: responseJson.page,
          limit: responseJson.limit,
          data: responseJson.All.data,
          type: payload.type,
          seminarId: payload.seminarId,
        },
      })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: LIST_ORDERS_FULL.FAILURE, error })
    yield call(reject)
  }
}

function* oneOrder({ payload: id, showModal }) {
  try {
    const response = yield call(getOneOrder, id)
    if (response.ok) {
      const responseJson = yield response.json()
      yield put({
        type: ONE_ORDER.SUCCESS,
        payload: {
          oneOrder: responseJson,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }
    showModal()
    // yield call(resolve)
    // yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: ONE_ORDER.FAILURE, error })
    yield call(reject)
  }
}

function* cancelerOrder({ payload: id }) {
  try {
    console.log('idSaga', id)
    const response = yield call(cancelOrder, id)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: CANCEL_ORDER.SUCCESS,
        payload: {
          message: responseJson,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }
    // yield call(resolve)
    // yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: CANCEL_ORDER.FAILURE, error })
    yield call(reject)
  }
}

function* confirmedOrder({ payload: id }) {
  try {
    const response = yield call(confirmOrder, id)

    if (response.ok) {
      const responseJson = yield response.body

      yield put({
        type: CONFIRM_ORDER.SUCCESS,
        payload: {
          message: responseJson,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }
    // yield call(resolve)
    // yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: CONFIRM_ORDER.FAILURE, error })
    yield call(reject)
  }
}

function* presendMailOrder({ payload }) {
  try {
    const response = yield call(resendMailOrderAPI, { payload })

    if (response.ok) {
      const responseJson = yield response.body

      yield put({
        type: RESEND_MAIL_ORDER.SUCCESS,
        payload: {
          message: responseJson,
        },
      })

      commonNotify({ message: `Đã gửi mail đến ${payload.email}`, type: 'success' })
    } else {
      throw new Error('Invalid login request')
    }
  } catch (error) {
    commonNotify({ message: `Gửi mail không thành công`, type: 'error' })
    console.error(error)
    yield put({ type: RESEND_MAIL_ORDER.FAILURE, error })
  }
}

function* getSeminarExportSaga() {
  try {
    const response = yield call(getSeminarExportAPI)
    if (response.ok) {
      const responseJson = yield response.json()
      yield put({
        type: GET_SEMINAR_EXPORT.SUCCESS,
        payload: {
          seminars: responseJson,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.error(error)
    yield put({ type: GET_SEMINAR_EXPORT.FAILURE, error })
    yield call(reject)
  }
}

function* callExportOrderSaga({ id }) {
  try {
    const response = yield call(callExportOrderAPI, id)

    if (response.ok) {
      const responseJson = yield response.json()
      console.log(responseJson, 'responseJson')

      yield put({
        type: CALL_EXPORT_ORDER.SUCCESS,
        payload: {
          dataOrder: responseJson,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.error(error)
    yield put({ type: CALL_EXPORT_ORDER.FAILURE, error })
    yield call(reject)
  }
}

// Monitoring Sagas
function* orderMonitor() {
  yield all([takeLatest(LIST_ORDERS.REQUEST, findAllOrders)])

  yield all([takeLatest(ONE_ORDER.REQUEST, oneOrder)])

  yield all([takeLatest(CONFIRM_ORDER.REQUEST, confirmedOrder)])

  yield all([takeLatest(CANCEL_ORDER.REQUEST, cancelerOrder)])

  yield all([takeLatest(LIST_ORDERS_FULL.REQUEST, findFullOrders)])

  yield all([takeLatest(RESEND_MAIL_ORDER.REQUEST, presendMailOrder)])

  yield all([takeLatest(GET_SEMINAR_EXPORT.REQUEST, getSeminarExportSaga)]),
  
  yield all([takeLatest(CALL_EXPORT_ORDER.REQUEST, callExportOrderSaga)])
}

export default orderMonitor
