import { DROP_ACTION, FETCH_COORDINATES_CME, POST_COORDINATES_CME } from "src/utils/types"

const defaultState = {
    data:{

    }
  }

  export default function Participants(state = defaultState, action) {

        switch (action.type) {
            case POST_COORDINATES_CME.REQUEST:
                return {
                    ...state,
                    ...action.payload,
                    loading: true,
                    error: false,
                    stateCreate:0
                }
            case POST_COORDINATES_CME.SUCCESS:
                return {
                    ...state,
                    ...action.payload,
                    error: false,
                    loading: false,
                    stateCreate:1
                }
            case POST_COORDINATES_CME.FAILURE:
                return {
                    ...state,
                    ...action.payload,
                    loading: false,
                    error: true,
                    stateCreate:2

                }
            // ========================== fecth one ==========================//

            case FETCH_COORDINATES_CME.REQUEST:
                return {
                    ...state,
                    ...action.payload,
                    loading: true,
                    statusFetchCoordinates:0
                }
            case FETCH_COORDINATES_CME.SUCCESS:
                return {
                    ...state,
                    ...action.payload,
                    loading: false,
                    statusFetchCoordinates:1

                }
            case FETCH_COORDINATES_CME.FAILURE:
                return {
                    ...state,
                    ...action.payload,
                    loading: false,
                    statusFetchCoordinates:2

                }

            //drop Action
            case DROP_ACTION.REQUEST:
                return {
                stateCreate:0,

            }
            default:
                return state
        }
}
