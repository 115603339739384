import React, { PureComponent, useState } from 'react'
import { Layout, Menu, Badge, Button } from 'antd'
import { Icon } from '@ant-design/compatible'
import { pathToRegexp } from 'path-to-regexp'
import { Link } from 'react-router-dom'

import { urlToList } from 'src/utils/url'
import Images from 'src/utils/images'
import styles from './styles.css'

const { Sider } = Layout
const { SubMenu } = Menu

// Allow menu.js config icon as string or ReactNode
//   icon: 'setting',
//   icon: 'http://demo.com/icon.png',
//   icon: <Icon type="setting" />,
const getIcon = (icon) => {
  if (typeof icon === 'string' && icon.indexOf('http') === 0) {
    return <img src={icon} alt="icon" className={`${styles.icon} sider-menu-item-img`} />
  }
  if (typeof icon === 'string') {
    // return <Icon style={{ fontSize: 20 }} type={icon} />
    return (
      <div>
        <img src={`../../../assets/images/${icon}.svg`} alt="icon" className={`${styles.icon} sider-menu-item-img`} />
      </div>
    )
  }
  return icon
}

/**
 * Recursively flatten the data
 * [{path:string},{path:string}] => {path,path2}
 * @param  menu
 */
export const getFlatMenuKeys = (menu) =>
  menu.reduce((keys, item) => {
    keys.push(item.path)
    if (item.children) {
      return keys.concat(getFlatMenuKeys(item.children))
    }
    return keys
  }, [])

/**
 * Find all matched menu keys based on paths
 * @param  flatMenuKeys: [/abc, /abc/:id, /abc/:id/info]
 * @param  paths: [/abc, /abc/11, /abc/11/info]
 */
export const getMenuMatchKeys = (flatMenuKeys, paths) =>
  paths.reduce((matchKeys, path) => matchKeys.concat(flatMenuKeys.filter((item) => pathToRegexp(item).test(path))), [])

export default class SiderMenu extends PureComponent {
  constructor(props) {
    super(props)
    this.menus = props.menuData
    this.flatMenuKeys = getFlatMenuKeys(props.menuData)
    this.state = {
      openKeys: this.getDefaultCollapsedSubMenus(props),
      isLogo: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    if (prevProps.location.pathname !== location.pathname) {
      this.setState({
        openKeys: this.getDefaultCollapsedSubMenus(this.props),
      })
    }
  }

  componentDidMount() {
    console.log(this.props.permission, '______________________________SiderMenu')
  }

  /**
   * Convert pathname to openKeys
   * /list/search/articles = > ['list','/list/search']
   * @param  props
   */
  getDefaultCollapsedSubMenus(props) {
    const {
      location: { pathname },
    } = props || this.props
    const keyList = getMenuMatchKeys(this.flatMenuKeys, urlToList(pathname))
    if (!keyList.includes('/dashboard/content-approval')) {
      keyList.unshift('/dashboard/content-approval')
    }
    return keyList
  }

  getMenuItemPath = (item) => {
    const icon = getIcon(item.icon)
    const { target, name, path } = item
    const { location, badgeData = [] } = this.props

    const hasNewData = badgeData.find((data) => data.name === name)
    return (
      <>
        <Link className={styles.ant_menu_title_content} to={path} target={target} replace={path === location.pathname}>
          {/*icon*/}
          {<item.iconComp className={`${styles.icon} sider-menu-item-img`} />}
          <span className={styles.li}>{name}</span>
          {hasNewData?.badge ? (
            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
              <Badge count={hasNewData?.badge} />
            </div>
          ) : null}
        </Link>
      </>
    )
  }

  /**
   * get SubMenu or Item
   */
  getSubMenuOrItem = (item) => {
    if (item.children && item.children.some((child) => child.name)) {
      const childrenItems = this.getNavMenuItems(item.children)
      if (childrenItems && childrenItems.length > 0) {
        return (
          <SubMenu
            title={
              item.icon ? (
                <span>
                  {getIcon(item.icon)}
                  <span>{item.name}</span>
                </span>
              ) : (
                item.name
              )
            }
            key={item.path}
          >
            {childrenItems}
          </SubMenu>
        )
      }
      return null
    }
    return (
      <Menu.Item className={styles.my_menu_item} key={item.path}>
        {this.getMenuItemPath(item)}
      </Menu.Item>
    )
  }

  getNavMenuItems = (menusData) => {
    if (!menusData) {
      return []
    }
    const menusDataTemp = menusData
      .filter((item) => item.name && !item.hideInMenu)
      .map((item, key) => {
        const ItemDom = this.getSubMenuOrItem(item)

        return this.checkPermissionItem(key, item.role, ItemDom)
      })
      .filter((item) => item)
    return menusDataTemp
  }

  // Get the currently selected menu
  getSelectedMenuKeys = () => {
    const {
      location: { pathname },
    } = this.props
    return getMenuMatchKeys(this.flatMenuKeys, urlToList(pathname))
  }

  checkPermissionItem = (key, role, ItemDom) => {
    const { currentUser } = this.props

    if (Array.isArray(role) && Array.isArray(currentUser?.role?.policy)) {
      return role.some((r) => currentUser.role.policy.includes(r)) ? ItemDom : null
    }

    return ItemDom
  }

  isMainMenu = (key) => this.menus.some((item) => key && (item.key === key || item.path === key))

  handleOpenChange = (openKeys) => {
    const lastOpenKey = openKeys[openKeys.length - 1]
    const moreThanOne = openKeys.filter((openKey) => this.isMainMenu(openKey)).length > 1
    this.setState({
      openKeys: moreThanOne ? [lastOpenKey] : [...openKeys],
    })
  }

  handleChangeImage = (info) => {
    const { currentUser } = this.props
    // Get this url from response in real world.
    Images.getBase64(info.file, async (imageUri) => {
      const blob = Images.dataURItoBlob(imageUri)
      try {
        await this.uploadAvatar(currentUser._id, blob)
      } catch (err) {
        console.log(err)
      }
    })
  }

  uploadAvatar = async (profileId, data) =>
    new Promise((resolve, reject) => this.props.uploadAvatar({ profileId, data, resolve, reject }))

  logo_image = () => {
    return (
      // style={{display:(this.state.isLogo)?'none':''}}
      <div className={styles.image_logo}>
        <div className={styles.logo}>
          <img src="../../../assets/images/logo-hnht.svg" height="50px" width="50px" />
        </div>
        <div className={styles.text_logo} style={{ display: this.state.isLogo ? 'none' : '' }}>
          <img src="../../../assets/images/logo-dashboad.svg" />
        </div>
      </div>
    )
  }

  render() {
    const { collapsed, onCollapse } = this.props
    const { openKeys } = this.state
    // Don't show popup menu when it is been collapsed
    const menuProps = collapsed ? {} : { openKeys }
    console.log(collapsed, 'collapsed')
    // if pathname can't match, use the nearest parent's key
    let selectedKeys = this.getSelectedMenuKeys()
    if (!selectedKeys.length) {
      selectedKeys = [openKeys[openKeys.length - 1]]
    }

    if (collapsed) {
      this.state.isLogo = true
    } else {
      this.state.isLogo = false
    }

    return (
      <Sider
        breakpoint="xxl"
        className={styles.sider}
        collapsed={collapsed}
        collapsible
        onCollapse={onCollapse}
        theme="light"
        trigger={null}
        width={290}
      >
        <Menu
          {...menuProps}
          className={styles.menu}
          key="Menu"
          mode="inline"
          onOpenChange={this.handleOpenChange}
          selectedKeys={selectedKeys}
          style={{ fontSize: '18px' }}
          theme="light"
        >
          {this.logo_image()}
          {this.getNavMenuItems(this.menus)}
        </Menu>
      </Sider>
    )
  }
}
