import { CONFIRM_PASSWORD, DROP_ACTION, FORGET_PASSWORD_ADMIN, LOGIN, LOGOUT, VERIFY_PASSWORD } from 'src/utils/types'

export function auth(params) {
  if (params) {
    return {
      type: LOGIN.REQUEST,
      payload: params,
    }
  }

  return { type: LOGIN.FAILURE, payload: 'No token' }
}

export function logout() {
  return {
    type: LOGOUT.REQUEST,
    payload: null,
  }
}

export function verifyPassword(params) {
  // console.log(params);
  return {
    type: VERIFY_PASSWORD.REQUEST,
    payload: params,
  }
}

export function confirmPassword(params) {
  
  return {
    type: CONFIRM_PASSWORD.REQUEST,
    payload: params,
  }
}

export function forgetPassword(params) {
 
  return {
    type: FORGET_PASSWORD_ADMIN.REQUEST,
    payload: params,
  }
}


export function dropAction() {
  return {
    type: DROP_ACTION.REQUEST
  }
}
