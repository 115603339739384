import { ConsoleSqlOutlined, SmileOutlined } from '@ant-design/icons'
import { notification } from 'antd'
import { months } from 'moment'
import { parse } from 'path-to-regexp'
import React from 'react'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  getAllUsers,
  getAllDegree,
  createUser,
  deleteUser,
  updateUser,
  searchUsersApi,
  forgetPassword,
  getOneUser,
  mergeUser,
  resetPasswordAPI,
  verifyCertificateAPI,
} from 'src/utils/api'
import { commonNotify } from 'src/utils/helpers'
import {
  CREATE_USER,
  DEGREE,
  DELETE_USER,
  FORGETPASSWORD_USER,
  SEARCH_USER,
  UPDATE_USER,
  USERS,
  ONE_USER,
  MERGE_USER,
  RESET_PASSWORD,
  VERIFY_CERTIFICATE,
} from 'src/utils/types'

function* getUsers() {
  const response = yield call(getAllUsers)
  try {
    const responseJson = yield response.json()
    yield put({
      type: USERS.SUCCESS,
      payload: {
        allUsers: responseJson,
      },
    })
  } catch (error) {
    yield put({ type: USERS.FAILURE, error })
  }
}

function* getDegree() {
  try {
    const response = yield call(getAllDegree)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: DEGREE.SUCCESS,
        payload: { listDegree: responseJson },
      })
    } else {
      yield put({ type: DEGREE.FAILURE, error })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: DEGREE.FAILURE, error })
  }
}

function* createUserSaga({ payload, action }) {
  try {
    const response = yield call(createUser, payload)

    if (response.ok) {
      const responseJson = yield response.json()
      commonNotify({ message: 'Thêm tài khoản thành công', type: 'success' })
      yield put({
        type: CREATE_USER.SUCCESS,
      })
      action()
    } else if (response.status === 500) {
      commonNotify({ message: 'Tài khoản đã tồn tại', type: 'error' })
      yield put({ type: CREATE_USER.FAILURE })
    } else {
      commonNotify({ message: 'Thêm tài khoản thất bại', type: 'error' })
      yield put({ type: CREATE_USER.FAILURE })
    }
  } catch (error) {
    commonNotify({ message: 'Thêm tài khoản thất bại', type: 'error' })
    console.error(error)
    yield put({ type: CREATE_USER.FAILURE, status: 500 })
  }
}

function* deleteUserSaga(param) {
  try {
    const response = yield call(deleteUser, param.payload)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: DELETE_USER.SUCCESS,
        payload: {
          createUser: responseJson,
          status: 200,
        },
      })
    } else {
      yield put({ type: DELETE_USER.FAILURE, status: 500 })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: DELETE_USER.FAILURE, status: 500 })
  }
}

function* updateUserSaga(payload) {
  try {
    const response = yield call(updateUser, payload)

    if (response.ok) {
      const responseJson = yield response.json()
      commonNotify({ message: 'Cập nhật thành công', type: 'success' })

      yield put({
        type: UPDATE_USER.SUCCESS,
        payload: {
          createUser: responseJson,
          status: 200,
        },
      })
      payload.action()
    } else {
      throw new Error('Error: Update User')
    }
  } catch (error) {
    commonNotify({ message: 'Cập nhật không thành công', type: 'error' })
    console.error(error)
    yield put({ type: UPDATE_USER.FAILURE, status: 500 })
  }
}

function* searchUserSaga({ payload }) {
  try {
    const response = yield call(searchUsersApi, payload)
    if (response.ok) {
      const responseJson = yield response.json()
      yield put({
        type: SEARCH_USER.SUCCESS,
        payload: {
          listUsers: responseJson.data,
        },
      })
    } else {
      throw new Error('Error: Search User')
    }
  } catch (error) {
    console.error(error)
    yield put({ type: SEARCH_USER.FAILURE })
  }
}

function* forgetPasswordSaga(payload) {
  try {
    const response = yield call(forgetPassword, payload)

    if (response.ok) {
      yield put({
        type: FORGETPASSWORD_USER.SUCCESS,
      })
    } else {
      yield put({ type: FORGETPASSWORD_USER.FAILURE })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: FORGETPASSWORD_USER.FAILURE })
  }
}

function* oneUser({ payload: email, setDataUser }) {
  const response = yield call(getOneUser, email)

  try {
    const responseJson = yield response.json()

    if (responseJson.data.status === 1) {
      yield put({
        type: ONE_USER.SUCCESS,
      })
      setDataUser({
        degree: responseJson.data.user.degree,
        fullName: responseJson.data.user.fullName,
        email: responseJson.data.user.email,
        location: responseJson.data.user.workUnit,
        dateOfBirth: responseJson.data.user.dateOfBirth,
      })
    } else {
      yield put({ type: ONE_USER.FAILURE, error })
    }
  } catch (error) {
    yield put({ type: ONE_USER.FAILURE, error })
  }
}

function* postMergeUser({ payload: body, notification, isCancelModal }) {
  const response = yield call(mergeUser, body)

  try {
    const responseJson = yield response.json()

    if (responseJson.data.status === 1) {
      yield put({
        type: MERGE_USER.SUCCESS,
      })
      isCancelModal(false)
    } else {
      yield put({ type: MERGE_USER.FAILURE, error })
      notification(responseJson.data.message)
    }
  } catch (error) {
    yield put({ type: MERGE_USER.FAILURE, error })
    notification(responseJson.data.message)
  }
}

function* resetPasswordSaga({ payload }) {
  try {
    const response = yield call(resetPasswordAPI, { email: payload })

    if (response.ok) {
      commonNotify({ message: 'Đã gửi mail đặt lại mật khẩu', type: 'success' })
      yield put({ type: RESET_PASSWORD.SUCCESS })
    } else {
      throw new Error('Error: Reset Password')
    }
  } catch (error) {
    yield put({ type: RESET_PASSWORD.FAILURE, error })
    commonNotify({
      message: 'Đã xảy ra lỗi. Hãy thử lại',
      type: 'error',
    })
  }
}

function* verifyCertificateSaga({ payload, action }) {
  try {
    const response = yield call(verifyCertificateAPI, payload)

    if (response.ok) {
      commonNotify({
        message: payload.verifyCertificate === 1 ? `Đã xác minh` : 'Đã gửi phản hồi đến email đại biểu',
        type: 'success',
      })
      yield put({ type: VERIFY_CERTIFICATE.SUCCESS })
      action()
    } else {
      throw new Error('Error: Verify Certificate')
    }
  } catch (error) {
    console.log(error)
    commonNotify({
      message: `Đã xảy ra lỗi. Hãy thử lại`,
      type: 'error',
    })
    yield put({ type: VERIFY_CERTIFICATE.FAILURE, error })
  }
}

function* authMonitor() {
  yield all([
    takeLatest(USERS.REQUEST, getUsers),
    takeLatest(DEGREE.REQUEST, getDegree),
    takeLatest(CREATE_USER.REQUEST, createUserSaga),
    takeLatest(DELETE_USER.REQUEST, deleteUserSaga),
    takeLatest(UPDATE_USER.REQUEST, updateUserSaga),
    takeLatest(SEARCH_USER.REQUEST, searchUserSaga),
    takeLatest(FORGETPASSWORD_USER.REQUEST, forgetPasswordSaga),
    takeLatest(RESET_PASSWORD.REQUEST, resetPasswordSaga),
    takeLatest(ONE_USER.REQUEST, oneUser),
    takeLatest(MERGE_USER.REQUEST, postMergeUser),
    takeLatest(VERIFY_CERTIFICATE.REQUEST, verifyCertificateSaga),
  ])
}

export default authMonitor
