import {
  LIST_COUPONS,
  CREATE_COUPON,
  ONE_COUPON,
  UPDATE_COUPON,
  DELETE_COUPON,
  LIST_SEMINARS_COUPON,
} from '../../../utils/types'

const defaultState = {
  couponsList: [],
}

export default function news(state = defaultState, action) {
  switch (action.type) {
    case LIST_COUPONS.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LIST_COUPONS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case LIST_COUPONS.FAILURE:
      return { ...state, error: action.error, loading: false }

    case LIST_SEMINARS_COUPON.REQUEST:
      console.log(1)
      return {
        ...state,
        loading: true,
      }
    case LIST_SEMINARS_COUPON.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case LIST_SEMINARS_COUPON.FAILURE:
      return { ...state, error: action.error, loading: false }

    case ONE_COUPON.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case ONE_COUPON.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case ONE_COUPON.FAILURE:
      return { ...state, error: action.error, loading: false }

    case CREATE_COUPON.REQUEST:
    case CREATE_COUPON.SUCCESS:
    case CREATE_COUPON.FAILURE:

    case UPDATE_COUPON.REQUEST:
    case UPDATE_COUPON.SUCCESS:
    case UPDATE_COUPON.FAILURE:

    case DELETE_COUPON.REQUEST:
    case DELETE_COUPON.SUCCESS:
    case DELETE_COUPON.FAILURE:
    default:
      return state
  }
}
