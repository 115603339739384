import { CREATE_SPEAKER, DELETE_SPEAKER, DROP_ACTION, GET_SPEAKER, UPDATE_SPEAKER } from "src/utils/types"

const defaultState = {
    data: null,
    
  }
  
  export default function seminar(state = defaultState, action) {
    switch (action.type) {
      //Change pagination
        case GET_SPEAKER.REQUEST:
            return {
                ...state,
                ...action.payload,
                statusFetchSpeaker:0
            }

        case GET_SPEAKER.SUCCESS:
            return {
                ...state,
                ...action.payload,
                statusFetchSpeaker:1
            }
        
        case GET_SPEAKER.FAILURE:
            return {
                ...state,
                ...action.payload,
                statusFetchSpeaker:2
            }
    // ========================= create speaker=============== //
        case CREATE_SPEAKER.REQUEST:
            return {
                ...state,
                ...action.payload,
                statusCreateSpeaker:0
            }

        case CREATE_SPEAKER.SUCCESS:
            console.log(action.payload, "________________ reducer__________________________")
            return {
                ...state,
                ...action.payload,
                statusCreateSpeaker:1
            }
            
        case CREATE_SPEAKER.FAILURE:
            return {
                ...state,
                ...action.payload,
                statusCreateSpeaker:2
            }
        
        // ========================= update speaker=============== //
        case UPDATE_SPEAKER.REQUEST:
            return {
                ...state,
                ...action.payload,
                statusUpdateSpeaker:0
            }

        case UPDATE_SPEAKER.SUCCESS:
            return {
                ...state,
                ...action.payload,
                statusUpdateSpeaker:1
            }
            
        case UPDATE_SPEAKER.FAILURE:
            return {
                ...state,
                ...action.payload,
                statusUpdateSpeaker:2
            }

        // ========================= update speaker=============== //
        case DELETE_SPEAKER.REQUEST:
            return {
                ...state,
                ...action.payload,
                statusDeleteSpeaker:0
            }

        case DELETE_SPEAKER.SUCCESS:
          
            return {
                ...state,
                ...action.payload,
                statusDeleteSpeaker:1
            }
            
        case DELETE_SPEAKER.FAILURE:
            return {
                ...state,
                ...action.payload,
                statusDeleteSpeaker:2
            }
        
        

        //====================== Drop Action ====================== //
        case DROP_ACTION.REQUEST:
            return{
                ...state,
                ...action.payload,
                statusFetchSpeaker:0, 
                statusCreateSpeaker:0,
                statusUpdateSpeaker:0,
                statusDeleteSpeaker:0,
            }

        default:
            return state
    }
}  