import { LIST_FOOTER, ONE_FOOTER, EDIT_FOOTER } from '../../../utils/types'

const defaultState = {
  footerList: null,
}

export default function footer(state = defaultState, action) {
  switch (action.type) {
    case LIST_FOOTER.REQUEST:
      console.log('reducer')
      return {
        ...state,
        loading: true,
      }
    case LIST_FOOTER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case LIST_FOOTER.FAILURE:
      return { ...state, error: action.error, loading: false }

    case ONE_FOOTER.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case ONE_FOOTER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case ONE_FOOTER.FAILURE:
      return { ...state, error: action.error, loading: false }

    case EDIT_FOOTER.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case EDIT_FOOTER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case EDIT_FOOTER.FAILURE:
      return { ...state, error: action.error, loading: false }

    default:
      return state
  }
}
