import {
  CHECKIN_PARTICIPANTS,
  CHECK_OUT,
  CHECK_OUT_ALL,
  CREATE_PARTICIPANTS,
  DEGREE,
  DROP_ACTION,
  FETCHONE_PARTICIPANTS,
  FETCH_PARTICIPANTS,
  PRINT_CME,
  SEARCH_PRESIDER,
  SEARCH_USER,
  SEND_MAIL,
  UPDATE_CHECKIN_CHECKOUT,
  UPDATE_TICKET_PRESIDER,
  PRINT_LETTER
} from 'src/utils/types'

const defaultState = {
  data: {},
}

export default function Participants(state = defaultState, action) {
  switch (action.type) {
    case FETCH_PARTICIPANTS.REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
        error: false,
      }
    case FETCH_PARTICIPANTS.SUCCESS:
      return {
        ...state,
        // data:action.payload,
        ...action.payload,

        error: false,
        loading: false,
      }
    case FETCH_PARTICIPANTS.FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true,
      }

    // ======================= Search User ============= //
    case SEARCH_USER.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case SEARCH_USER.SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case SEARCH_USER.FAILURE:
      return {
        ...state,
        ...action.payload,
      }

    // ====================== Degree ============================ //
    case DEGREE.REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case DEGREE.SUCCESS:
      return {
        ...state,
        ...action.payload,
        // data:action.payload,
        error: false,
        loading: false,
      }
    case DEGREE.FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }

    //      // ====================== Create PARTICIPANTS ============================ //
    case CREATE_PARTICIPANTS.REQUEST:
      return {
        ...state,
        ...action.payload,
      }
    case CREATE_PARTICIPANTS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case CREATE_PARTICIPANTS.FAILURE:
      return {
        ...state,
        ...action.payload,
      }

    case FETCHONE_PARTICIPANTS.REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
      }
    case FETCHONE_PARTICIPANTS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case FETCHONE_PARTICIPANTS.FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    //    // ======================= checkIn presider ============= //
    case CHECKIN_PARTICIPANTS.REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
        statusUpdateCheckIn: 0,
        statusUpdatePrint: 0,
      }
    case CHECKIN_PARTICIPANTS.SUCCESS:
      if (action.payload.resultLetter.isCheckIn) {
        return {
          ...state,
          ...action.payload,
          statusUpdateCheckIn: 1,
        }
      } else {
        return {
          ...state,
          ...action.payload,
          statusUpdatePrint: 1,
        }
      }

    case CHECKIN_PARTICIPANTS.FAILURE:
      return {
        ...state,
        ...action.payload,
        statusUpdateCheckIn: 2,
      }

    //=========================== Search Presider ==================//

    case SEARCH_PRESIDER.REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
      }
    case SEARCH_PRESIDER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case SEARCH_PRESIDER.FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }

    //=========================== update ticket Presider ==================//

    case UPDATE_TICKET_PRESIDER.REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
        statusUpdateTicket: 0,
      }
    case UPDATE_TICKET_PRESIDER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        statusUpdateTicket: 1,
      }
    case UPDATE_TICKET_PRESIDER.FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
        statusUpdateTicket: 2,
      }

    //=========================== send Mail ticket Presider ==================//

    case SEND_MAIL.REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
        statussendMail: 0,
      }
    case SEND_MAIL.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        statussendMail: 1,
      }
    case SEND_MAIL.FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
        statussendMail: 2,
      }

    //=========================== CheckOut ==================//

    case CHECK_OUT.REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
        statusCheckOut: 0,
      }
    case CHECK_OUT.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        statusCheckOut: 1,
      }
    case CHECK_OUT.FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
        statusCheckOut: 2,
      }

    case CHECK_OUT_ALL.REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
      }
    case CHECK_OUT_ALL.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case CHECK_OUT_ALL.FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
      
    case UPDATE_CHECKIN_CHECKOUT.REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
      }
    case UPDATE_CHECKIN_CHECKOUT.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case UPDATE_CHECKIN_CHECKOUT.FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }

    

    // ================== Drop Action ================ //
    case DROP_ACTION.REQUEST:
      console.log('Call dropAction')
      return {
        ...state,
        ...action.payload,
        statusCreate: 0,
        statusUpdateCheckIn: 0,
        statusUpdatePrint: 0,
        statusUpdateTicket: 0,
        statussendMail: 0,
        statusCheckOut: 0,
        statusPrintCME: 0,
      }
    default:
      return state
  }
}
