import { takeLatest, put, call, all } from 'redux-saga/effects'
import { LIST_PANELIST, ONE_PANELIST, CREATE_PANELIST, EDIT_PANELIST, DELETE_PANELIST } from 'src/utils/types'
import { panelists, deletePanelist, createPanelist } from 'src/utils/api'
import { reject } from 'lodash'

function* fetchPanelist({ payload: slug }) {
  try {
    const response = yield call(panelists, slug)

    console.log(123132132, slug, response)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: LIST_PANELIST.SUCCESS,
        payload: {
          panelists: responseJson,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.error(error)
    yield put({ type: LIST_PANELIST.FAILURE, error })
    yield call(reject)
  }
}

function* createPanelists({ payload: body, handleNotification, slug, setIsModalVisible }) {
  try {
    const response = yield call(createPanelist, slug, body)

    if (response.ok) {
      const responseJson = yield response.json()
      if (responseJson.status === 1) {
        handleNotification('Thành công')
        setIsModalVisible(false)
        yield put({
          type: CREATE_PANELIST.SUCCESS,
          payload: {
            status: responseJson,
          },
        })
      } else {
        handleNotification('Thất bại')
        yield put({ type: CREATE_PANELIST.FAILURE })
        yield call(reject)
      }
    } else {
      handleNotification('Thất bại')
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.error(error)
    handleNotification('Thất bại')
    yield put({ type: CREATE_PANELIST.FAILURE, error })
    yield call(reject)
  }
}

function* deletePanelists({ payload: body, handleNotification, slug }) {
  try {
    const response = yield call(deletePanelist, slug, body)
    if (response.ok) {
      const responseJson = yield response.json()
      if (responseJson.status === 1) {
        handleNotification('Thành công')

        yield put({
          type: DELETE_PANELIST.SUCCESS,
          payload: {
            status: responseJson,
          },
        })
      } else {
        handleNotification('Thất bại')
        yield put({ type: DELETE_PANELIST.FAILURE })
        yield call(reject)
      }
    } else {
      handleNotification('Thất bại')
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.error(error)
    handleNotification('Thất bại')
    yield put({ type: DELETE_PANELIST.FAILURE, error })
    yield call(reject)
  }
}

// Monitoring Sagas
function* seminarPanelistMonitor() {
  yield all([takeLatest(LIST_PANELIST.REQUEST, fetchPanelist)])

  yield all([takeLatest(CREATE_PANELIST.REQUEST, createPanelists)])

  yield all([takeLatest(DELETE_PANELIST.REQUEST, deletePanelists)])
}

export default seminarPanelistMonitor
