import { CREATE_DEGREE, DELETE_DEGREE, DROP_ACTION, GET_DEGREE, UPDATE_DEGREE } from "src/utils/types"

const defaultUserState = {
    data:{
        listDegree:[],
    }
  }
export default function degree(state = defaultUserState, action) {
    switch (action.type) {
        case GET_DEGREE.REQUEST:
            return {
                ...state,
                ...action.payload,
                loading: true,
                statusListDegree:0
            }
        case GET_DEGREE.SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: true,
                statusListDegree:1
            }
        case GET_DEGREE.FAILURE:
            return {
                ...state,
                loading: true,
                statusListDegree:2
            }

        // ======================== Create Degree ======================== //
        case CREATE_DEGREE.REQUEST:
            return {
                ...state,
                ...action.payload,
                loading: true,
                statusCreateDegree:0
            }
        case CREATE_DEGREE.SUCCESS:
            return {
                // ...state,
                // ...action.payload,
                loading: true,
                statusCreateDegree:1
            }
        case CREATE_DEGREE.FAILURE:
            return {
                // ...state,
                // ...action.payload,
                loading: true,
                statusCreateDegree:2
            }

             // ======================== Update Degree ======================== //
        case UPDATE_DEGREE.REQUEST:
            return {
                ...state,
                ...action.payload,
                loading: true,
                statusUpdateDegree:0
            }
        case UPDATE_DEGREE.SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: true,
                statusUpdateDegree:1
            }
        case UPDATE_DEGREE.FAILURE:
            return {
                ...state,
                ...action.payload,
                loading: true,
                statusUpdateDegree:2
            }

                // ======================== Delete Degree ======================== //
        case DELETE_DEGREE.REQUEST:
            return {
                ...state,
                ...action.payload,
                loading: true,
                statusDeleteDegree:0
            }
        case DELETE_DEGREE.SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: true,
                statusDeleteDegree:1
            }
        case DELETE_DEGREE.FAILURE:
            return {
                ...state,
                ...action.payload,
                loading: true,
                statusDeleteDegree:2
            }


        // ============================ Drop Action =========================== //
        
        case DROP_ACTION.REQUEST:
            return{
                ...state,
                ...action.payload,
                statusListDegree:0, 
                statusCreateDegree:0,
                statusUpdateDegree:0,
                statusDeleteDegree:0
            }
        default:
            return state
    }
}
