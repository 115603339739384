import { all, call, put, takeLatest } from "redux-saga/effects"
import { createDegree, deleteDegree, getListDegree, updateDegree } from "src/utils/api"
import { GET_DEGREE, CREATE_DEGREE, UPDATE_DEGREE, DELETE_DEGREE } from "src/utils/types"

    function* getDegreeSaga() {
       
        try {
          const response = yield call(getListDegree)
         
          
          if (response.ok) {
            const responseJson = yield response.json()
            responseJson.data.forEach((item,key)=>{
                item.key = key+1
            })
            console.log(responseJson,"_______________________________list degree saga")
            yield put({
              type: GET_DEGREE.SUCCESS,
              payload:{
                listDegree:responseJson,
              } 
            })
          } else {
            yield put({ type: GET_DEGREE.FAILURE})
          }
         
        } catch (error) {
          console.error(error)
          yield put({ type: GET_DEGREE.FAILURE })
         
        }
      }

      function* createDegreeSaga(body) {
        // console.log(payload,"_______________________________list degree saga")
        try {
          const response = yield call(createDegree, body.payload)
         
          
          if (response.ok) {
            const responseJson = yield response.json()
            
       
            yield put({
              type: CREATE_DEGREE.SUCCESS,
              payload:{
                listDegree:responseJson,
                
              } 
            })
          } else {
            yield put({ type: CREATE_DEGREE.FAILURE})
          }
         
        } catch (error) {
          console.error(error)
          yield put({ type: CREATE_DEGREE.FAILURE })
         
        }
      }

      function* updateDegreeSaga(body) {
       
        try {
          const response = yield call(updateDegree, body.payload,body.body)
         
          
          if (response.ok) {
            const responseJson = yield response.json()
            
       
            yield put({
              type: UPDATE_DEGREE.SUCCESS,
              payload:{
                listDegree:responseJson,
                
              } 
            })
          } else {
            yield put({ type: UPDATE_DEGREE.FAILURE})
          }
         
        } catch (error) {
          console.error(error)
          yield put({ type: UPDATE_DEGREE.FAILURE })
         
        }
      }

      function* deleteDegreeSaga(body) {
      
        try {
          const response = yield call(deleteDegree, body.payload)
         
          
          if (response.ok) {
            const responseJson = yield response.json()
            
       
            yield put({
              type: DELETE_DEGREE.SUCCESS,
              payload:{
                listDegree:responseJson,
                
              } 
            })
          } else {
            yield put({ type: DELETE_DEGREE.FAILURE})
          }
         
        } catch (error) {
          console.error(error)
          yield put({ type: DELETE_DEGREE.FAILURE })
         
        }
      }
  
  function* authMonitor() {
      yield all([takeLatest(GET_DEGREE.REQUEST, getDegreeSaga),takeLatest(CREATE_DEGREE.REQUEST, createDegreeSaga),takeLatest(UPDATE_DEGREE.REQUEST, updateDegreeSaga),takeLatest(DELETE_DEGREE.REQUEST, deleteDegreeSaga)]);
  }
  
  export default authMonitor