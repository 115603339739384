import { CREATE_SPONSOR, DELETE_SPONSOR, FETCH_SPONSOR, LIST_SPONSORS, UPDATE_SPONSOR } from '../../../utils/types'

const defaultState = {
  sponsors: [],
}

export default function sponsor(state = defaultState, action) {
  switch (action.type) {
    case LIST_SPONSORS.REQUEST:
      console.log('LIST_CONTACTS.REQUEST:')
      return {
        ...state,
        statusList: 0,
      }
    case LIST_SPONSORS.SUCCESS:
      console.log('LIST_CONTACTS.SUCCESS')
      console.log(action.payload.contacts)
      console.log('LIST_CONTACTS.SUCCESS')
      state.contacts = action.payload.contacts
      state.page = action.payload.page
      state.limit = action.payload.limit
      state.totalCount = action.payload.totalCount
      console.log('state', state)
      return {
        ...state,
        ...action.payload,
        loading: false,
        statusList: 1,
      }
    case LIST_SPONSORS.FAILURE:
      return { ...state, error: action.error, loading: false, statusList: 2 }
    //
    //one Contact
    case FETCH_SPONSOR.REQUEST:
      return {
        ...state,
        ...action.payload,
      }
    case FETCH_SPONSOR.SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case FETCH_SPONSOR.FAILURE:
    //delete Contact
    case CREATE_SPONSOR.REQUEST:
    case CREATE_SPONSOR.SUCCESS:
    case CREATE_SPONSOR.FAILURE:

    //feedback contact
    case UPDATE_SPONSOR.REQUEST:
    case UPDATE_SPONSOR.SUCCESS:
    case UPDATE_SPONSOR.FAILURE:

    //feedback contact
    case DELETE_SPONSOR.REQUEST:
    case DELETE_SPONSOR.SUCCESS:
    case DELETE_SPONSOR.FAILURE:
    default:
      return state
  }
}
