import { message } from 'antd'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  checkInParticipants,
  checkOut,
  createParticipants,
  createParticipantsAPI,
  fetchOneParticipants,
  fetchSeminars,
  getAllDegree,
  getListDegree,
  getListParticipants,
  printCMEAPI,
  searchLetter,
  searchUsers,
  sendMail,
  updateCheckInCheckOut,
  updateTicket,
  checkOutAll,
  updateCheckInMobileAPI,
  getCheckInMobileAPI,
} from 'src/utils/api'
import { commonNotify } from 'src/utils/helpers'
import {
  CHECKIN_PARTICIPANTS,
  CHECK_OUT,
  CHECK_OUT_ALL,
  CREATE_PARTICIPANTS,
  FETCHONE_PARTICIPANTS,
  FETCH_PARTICIPANTS,
  GET_CHECK_IN_MOBILE,
  GET_DEGREE,
  PRINT_CME,
  SEARCH_PRESIDER,
  SEND_MAIL,
  UPDATE_CHECKIN_CHECKOUT,
  UPDATE_CHECK_IN_MOBILE,
  UPDATE_TICKET_PRESIDER,
} from 'src/utils/types'
import { upperCase } from 'src/utils/UpperCase'
import { fetchOneLetter, getCheckInMobile, updateTicketPresider } from './action'

function* fetchParticipantsSaga({ payload }) {
  const response = yield call(getListParticipants, { ...payload, ...payload.paging })

  try {
    const responseJson = yield response.json()
    const { participants } = responseJson.data

    yield put({
      type: FETCH_PARTICIPANTS.SUCCESS,
      payload: {
        listParticipants: {
          participants,
          ...responseJson.data,
        },
      },
    })
  } catch (error) {
    console.log('error', error)
    yield put({ type: FETCH_PARTICIPANTS.FAILURE, error })
  }
}

// function* searchUserSaga(payload) {
//   console.log(payload, 'search Saga __________________________________')
//   try {
//     const response = yield call(searchUsers, payload)

//     if (response.ok) {
//       const responseJson = yield response.json()
//       console.log(responseJson, '__________________________ Search saga hihi _____--')

//       responseJson.data.forEach((item, key) => {
//         item.key = key + 1
//         item.fullName = item.lastName + ' ' + item.firstName
//       })
//       yield put({
//         type: SEARCH_USER.SUCCESS,
//         payload: {
//           listUsers: responseJson,
//         },
//       })
//     } else {
//       yield put({ type: SEARCH_USER.FAILURE })
//     }
//   } catch (error) {
//     console.error(error)
//     yield put({ type: SEARCH_USER.FAILURE })
//   }
// }

// function* getDegreeSaga() {
//   try {
//     const response = yield call(getListDegree)
//     console.log(response, '_______________________________responseJson degree')
//     if (response.ok) {
//       const responseJson = yield response.json()
//       // console.log(responseJson );
//       responseJson.data.forEach((item, key) => {
//         item.key = key + 1
//       })

//       yield put({
//         type: GET_DEGREE.SUCCESS,
//         payload: {
//           listDegree: responseJson,
//         },
//       })
//     } else {
//       yield put({ type: GET_DEGREE.FAILURE })
//     }
//   } catch (error) {
//     console.error(error)
//     yield put({ type: GET_DEGREE.FAILURE })
//   }
// }

function* fetchOneParticipantsSaga(param) {
  try {
    const id = param.payload
    const response = yield call(fetchOneParticipants, id)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: FETCHONE_PARTICIPANTS.SUCCESS,
        payload: { resultLetter: responseJson },
      })
    } else {
      yield put({ type: FETCHONE_PARTICIPANTS.FAILURE })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: FETCHONE_PARTICIPANTS.FAILURE })
  }
}

function* updateCheckInParticipantsSaga(param) {
  try {
    const checkIn = param.payload
    const id = param.id
    const response = yield call(checkInParticipants, checkIn, id)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: CHECKIN_PARTICIPANTS.SUCCESS,
        payload: { resultLetter: responseJson },
      })
    } else {
      yield put({ type: CHECKIN_PARTICIPANTS.FAILURE })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: CHECKIN_PARTICIPANTS.FAILURE })
  }
}

function* createParticipantsSaga({ payload, action }) {
  try {
    const response = yield call(createParticipantsAPI, payload)

    if (response.ok) {
      const responseJson = yield response.json()
      commonNotify({ message: 'Tạo vé thành công', type: 'success' })

      yield put({
        type: CREATE_PARTICIPANTS.SUCCESS,
        payload: {
          createParticipants: responseJson,
          status: 200,
        },
      })
      action()
    } else if (response.status == 409) {
      commonNotify({ message: 'Đại biểu này đã có trong hệ thống. Vui lòng tìm kiếm và tạo vé', type: 'error' })
      yield put({ type: CREATE_PARTICIPANTS.FAILURE })
    } else if (response.status == 500) {
      commonNotify({ message: 'Đại biểu đã có thư mời trên hệ thống', type: 'error' })
      yield put({ type: CREATE_PARTICIPANTS.FAILURE })
    } else {
      throw new Error('Error: Create ticket')
    }
  } catch (error) {
    commonNotify({ message: 'Tạo vé không thành công', type: 'error' })
    console.log(error)
    yield put({ type: CREATE_PARTICIPANTS.FAILURE })
  }
}

function* updateTicketPresiderSaga(payload) {
  try {
    const id = payload.id
    const body = payload.payload

    const response = yield call(updateTicket, body, id)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: UPDATE_TICKET_PRESIDER.SUCCESS,
        payload: {
          updateTicketPresider: responseJson,
          status: 200,
        },
      })
    } else if (response.status == 400) {
      yield put({ type: UPDATE_TICKET_PRESIDER.FAILURE, status: 400 })
    } else {
      yield put({ type: UPDATE_TICKET_PRESIDER.FAILURE, status: 500 })
    }
  } catch (error) {
    console.error(error, 'error')
    yield put({ type: UPDATE_TICKET_PRESIDER.FAILURE, status: 500 })
  }
}

function* searchLetterSaga({ payload: body }) {
  try {
    const response = yield call(searchLetter, body)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: SEARCH_PRESIDER.SUCCESS,
        payload: {
          listParticipants: responseJson,
          status: 200,
        },
      })
    } else {
      yield put({ type: SEARCH_PRESIDER.FAILURE, status: 500 })
    }
  } catch (error) {
    console.error(error, 'error')
    yield put({ type: SEARCH_PRESIDER.FAILURE, status: 500 })
  }
}

function* sendMailSaga({ payload: ticketId, action }) {
  try {
    const response = yield call(sendMail, ticketId)
    if (response.ok) {
      const responseJson = yield response.json()

      commonNotify({ message: 'Đã gửi mail', type: 'success' })

      yield put({
        type: SEND_MAIL.SUCCESS,
        payload: {
          responseSendMail: responseJson,
          status: 200,
        },
      })

      action()
    } else {
      throw new Error('Error: Send Mail')
    }
  } catch (error) {
    console.error(error, 'error')
    commonNotify({ message: 'Gửi mail không thành công', type: 'error' })

    yield put({ type: SEND_MAIL.FAILURE, status: 500 })
  }
}

function* checkOutSaga({ payload }) {
  try {
    const response = yield call(checkOut, payload)

    if (response.ok) {
      const responseJson = yield response.json()
      commonNotify({ message: 'Check out thành công', type: 'success' })
      yield put({
        type: CHECK_OUT.SUCCESS,
        payload: {
          responseSendMail: responseJson,
          status: 200,
        },
      })
    } else {
      throw new Error('Error: Check Out')
    }
  } catch (error) {
    console.error(error, 'error')
    commonNotify({ message: 'Check out thất bại', type: 'error' })
    yield put({ type: CHECK_OUT.FAILURE, status: 500 })
  }
}

function* checkOutAllSaga({ payload, action }) {
  try {
    const response = yield call(checkOutAll, payload)
    if (response.ok) {
      commonNotify({ message: 'Check out thành công', type: 'success' })
      yield put({
        type: CHECK_OUT_ALL.SUCCESS,
      })
    } else {
      throw new Error('Error: Check Out All')
    }
  } catch (error) {
    console.error(error, 'error')
    commonNotify({ message: 'Check out thất bại', type: 'error' })
    yield put({ type: CHECK_OUT_ALL.FAILURE, status: 500 })
  } finally {
    action()
  }
}

function* updateCheckInCheckOutSaga({ payload, action }) {
  try {
    const response = yield call(updateCheckInCheckOut, payload)
    if (response.ok) {
      yield put({
        type: UPDATE_CHECKIN_CHECKOUT.SUCCESS,
      })
      commonNotify({ message: 'Cập nhật thành công', type: 'success' })
    } else {
      throw new Error('Error: Check Out All')
    }
  } catch (error) {
    commonNotify({ message: 'Cập nhật thất bại', type: 'error' })
    yield put({ type: UPDATE_CHECKIN_CHECKOUT.FAILURE, status: 500 })
  } finally {
    action()
  }
}

function* getCheckInMobileSaga({ payload: { body, action } }) {
  try {
    const response = yield call(getCheckInMobileAPI, body)
    if (response.ok) {
      const responseJSON = yield response.json()
      yield put({
        type: GET_CHECK_IN_MOBILE.SUCCESS,
      })
      action(responseJSON.data)
    } else {
      throw new Error('Error: Get checkin mobile')
    }
  } catch (error) {
    console.log(error);
    commonNotify({ message: 'Lỗi', type: 'error' })
    yield put({ type: GET_CHECK_IN_MOBILE.FAILURE, error })
  }
}

function* updateCheckInMobileSaga({ payload: { body, action } }) {
  try {
    const response = yield call(updateCheckInMobileAPI, body)
    if (response.ok) {
      const responseJSON = yield response.json()
      yield put({
        type: UPDATE_CHECK_IN_MOBILE.SUCCESS,
      })
      action(responseJSON.data)
    } else {
      throw new Error('Error: Update checkin mobile')
    }
  } catch (error) {
    console.log(error);
    commonNotify({ message: 'Lỗi', type: 'error' })
    yield put({ type: UPDATE_CHECK_IN_MOBILE.FAILURE, error })
  }
}

function* authMonitor() {
  yield all([
    takeLatest(FETCH_PARTICIPANTS.REQUEST, fetchParticipantsSaga),
    takeLatest(CREATE_PARTICIPANTS.REQUEST, createParticipantsSaga),
    takeLatest(FETCHONE_PARTICIPANTS.REQUEST, fetchOneParticipantsSaga),
    takeLatest(CHECKIN_PARTICIPANTS.REQUEST, updateCheckInParticipantsSaga),
    takeLatest(UPDATE_TICKET_PRESIDER.REQUEST, updateTicketPresiderSaga),
    takeLatest(SEARCH_PRESIDER.REQUEST, searchLetterSaga),
    takeLatest(SEND_MAIL.REQUEST, sendMailSaga),
    takeLatest(CHECK_OUT.REQUEST, checkOutSaga),
    takeLatest(CHECK_OUT_ALL.REQUEST, checkOutAllSaga),
    takeLatest(UPDATE_CHECKIN_CHECKOUT.REQUEST, updateCheckInCheckOutSaga),
    takeLatest(GET_CHECK_IN_MOBILE.REQUEST, getCheckInMobileSaga),
    takeLatest(UPDATE_CHECK_IN_MOBILE.REQUEST, updateCheckInMobileSaga),
  ])
}

export default authMonitor
