import { CONFIRM_PASSWORD, DROP_ACTION, FORGET_PASSWORD_ADMIN, LOGIN, LOGOUT, VERIFY_PASSWORD } from 'src/utils/types'

const defaultUserState = {}

export default function auth(state = defaultUserState, action) {
  switch (action.type) {
    case LOGIN.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        permission: [],
        statusLogin: 0,
      }
    case LOGIN.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        statusLogin: 1,
      }
    case LOGOUT.SUCCESS:
    case LOGOUT.FAILURE:
      return {
        statusLogin: 2,
      }
    case LOGIN.FAILURE:
      return { ...state, error: action.error, loading: false, statusLogin: 2 }

    // =============================== verifypassword =======================//
    case VERIFY_PASSWORD.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        permission: [],
        statusLoginVerify: 0,
      }
    case VERIFY_PASSWORD.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        statusLoginVerify: 1,
      }
    case VERIFY_PASSWORD.FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        statusLoginVerify: 2,
      }

    // =============================== verifypassword =======================//
    case CONFIRM_PASSWORD.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        permission: [],
        // statusLoginVerify:0
      }
    case CONFIRM_PASSWORD.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        // statusLoginVerify:1
      }
    case CONFIRM_PASSWORD.FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        // statusLoginVerify:2
      }

    // =============================== fortget password =======================//
    case FORGET_PASSWORD_ADMIN.REQUEST:
      return {
        ...state,
        loading: true,
        statusSendmailForgetPassword: 0,
      }
    case FORGET_PASSWORD_ADMIN.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        statusSendmailForgetPassword: 1,
      }
    case FORGET_PASSWORD_ADMIN.FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        statusSendmailForgetPassword: 2,
      }
    //=============================== drop ======================//
    case DROP_ACTION.REQUEST:
      return {
        ...state,
        loading: false,
        statusLogin: 0,
        statusLoginVerify: 0,
        statusSendmailForgetPassword: 0,
      }

    default:
      return state
  }
}
