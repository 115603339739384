import React from 'react'
import { Spin } from 'antd'

const LoadingScreen = () => (
  <div className="ant-layout" style={{ padding: '32px 0', height: '100%' }}>
    <Spin />
  </div>
)

export default LoadingScreen
