import { Col, Row } from 'antd'
import React from 'react'
import { Link, Switch } from 'react-router-dom'
import { renderRoutes } from 'src/utils/services/router'
import GlobalFooter from '../components/GlobalFooter'
import styles from './styles.css'

class UserLayout extends React.PureComponent {
  render() {
    const { routes } = this.props.route

    return (
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.top}>
            <Link to="/auth/login">
              <img className={styles.logo} src="/assets/images/logo_image.svg" alt="logo" style={{ width: '24em' }} />
            </Link>
          </div>
          <Switch>{renderRoutes(routes)}</Switch>
        </div>
        <GlobalFooter />
      </div>
    )
  }
}

export default UserLayout
