import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  createModerator,
  getListDegree,
  updateSpeaker,
  getListModerator,
  updateModerator,
  deleteModerator,
} from 'src/utils/api'
import {
  CREATE_DEGREE,
  CREATE_PRESIDER,
  CREATE_SPEAKER,
  DELETE_PRESIDER,
  DELETE_SPEAKER,
  GET_PRESIDER,
  GET_SPEAKER,
  UPDATE_PRESIDER,
  UPDATE_SPEAKER,
} from 'src/utils/types'
import {} from './action'

function* fetchPresiderSaga() {
  try {
    const response = yield call(getListModerator)
    const responseDegree = yield call(getListDegree)

    if (response.ok) {
      const responseJson = yield response.json()
      const responseDegreeJson = yield responseDegree.json()

      responseJson.map((item, key) => {
        item.key = key + 1
      })
      yield put({
        type: GET_PRESIDER.SUCCESS,
        payload: {
          listPresider: responseJson,
          listDegree: responseDegreeJson,
        },
      })
    } else {
      yield put({
        type: GET_PRESIDER.FAILURE,
      })
    }
  } catch (error) {
    console.log(error)
    yield put({
      type: GET_PRESIDER.FAILURE,
    })
  }
}

function* createSpeakerSaga(body) {
  try {
    const response = yield call(createModerator, body.payload)

    if (response.ok) {
      const responseJson = yield response.json()
      yield put({
        type: CREATE_PRESIDER.SUCCESS,
        payload: {
          listSpeaker: responseJson.data,
        },
      })
    } else {
      yield put({
        type: CREATE_PRESIDER.FAILURE,
      })
    }
  } catch (error) {
    console.log(error)
    yield put({
      type: CREATE_PRESIDER.FAILURE,
    })
  }
}

function* updatePresiderSaga(payload) {
  try {
    //  const id = body.payload;
    //  const bodyTemp = body.body
    const response = yield call(updateModerator, payload)

    if (response.ok) {
      const responseJson = yield response.json()
      yield put({
        type: UPDATE_PRESIDER.SUCCESS,
        payload: {
          listSpeaker: responseJson.data,
        },
      })
    } else {
      yield put({
        type: UPDATE_PRESIDER.FAILURE,
      })
    }
  } catch (error) {
    yield put({
      type: UPDATE_PRESIDER.FAILURE,
    })
  }
}

function* deletePresiderSaga(body) {
  try {
    const response = yield call(deleteModerator, body.payload)

    if (response.ok) {
      yield put({
        type: DELETE_PRESIDER.SUCCESS,
      })
    } else {
      yield put({
        type: DELETE_PRESIDER.FAILURE,
      })
    }
  } catch (error) {
    console.log(error)
    yield put({
      type: DELETE_PRESIDER.FAILURE,
    })
  }
}

// Monitoring Sagas
function* PresiderMonitor() {
  yield all([
    takeLatest(GET_PRESIDER.REQUEST, fetchPresiderSaga),
    takeLatest(CREATE_PRESIDER.REQUEST, createSpeakerSaga),
    takeLatest(UPDATE_PRESIDER.REQUEST, updatePresiderSaga),
    takeLatest(DELETE_PRESIDER.REQUEST, deletePresiderSaga),
  ])
}

export default PresiderMonitor
