import { takeLatest, put, call, all } from 'redux-saga/effects'
import { LIST_CONTACTS, ONE_CONTACT, DELETE_CONTACT, FEEDBACK_CONTACT } from '../../../utils/types'
import { getContactList, oneContact, deleteContact, postFeedback } from 'src/utils/api'
import { reject } from 'lodash'

function* findAllContacts(params) {
  try {
    console.log('paging', params)
    console.log('saga contact')
    const response = yield call(
      getContactList,
      params.payload.paging.page,
      params.payload.paging.limit,
      params.payload.searchText,
      params.payload.sortField,
      params.payload.sortOrder,
    )
    console.log('saga contact2')
    
    console.log('saga contact3')

    if (response.ok) {
      const responseJson = yield response.json()
      console.log('LIST_CONTACTS.REQUEST IN SAGA')
      
      console.log('LIST_CONTACTS.REQUEST IN SAGA')
      yield put({
        type: LIST_CONTACTS.SUCCESS,
        payload: {
          contacts: responseJson.contact,
          page: responseJson.page,
          limit: responseJson.limit,
          totalCount: responseJson.totalCount,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.error(error)
    yield put({ type: LIST_CONTACTS.FAILURE, error })
    yield call(reject)
  }
}

function* removeContact({ payload: id }) {
  try {
    const response = yield call(deleteContact, id)
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      yield put({
        type: DELETE_CONTACT.SUCCESS,
      })
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: DELETE_CONTACT.FAILURE, error })
    yield call(reject)
  }
}

function* getOneContact({ payload: id, showModal }) {
  try {
    const response = yield call(oneContact, id)
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      yield put({
        type: ONE_CONTACT.SUCCESS,
        payload: {
          oneContact: responseJson.result,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }
    showModal()
    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: ONE_CONTACT.FAILURE, error })
    yield call(reject)
  }
}

function* sendFeedbackContacts({ payload: body }) {
  try {
    const response = yield call(postFeedback, body)
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      yield put({
        type: FEEDBACK_CONTACT.SUCCESS,
      })
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: FEEDBACK_CONTACT.FAILURE, error })
    yield call(reject)
  }
}
// Monitoring Sagas
function* contactMonitor() {
  yield all([takeLatest(LIST_CONTACTS.REQUEST, findAllContacts)])

  yield all([takeLatest(DELETE_CONTACT.REQUEST, removeContact)])

  yield all([takeLatest(ONE_CONTACT.REQUEST, getOneContact)])

  yield all([takeLatest(FEEDBACK_CONTACT.REQUEST, sendFeedbackContacts)])
}

export default contactMonitor
