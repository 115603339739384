import {
  CREATE_USER,
  DEGREE,
  LOGIN,
  LOGOUT,
  USERS,
  DROP_ACTION,
  DELETE_USER,
  UPDATE_USER,
  SEARCH_USER,
  FORGETPASSWORD_USER,
  ONE_USER,
  MERGE_USER,
  VERIFY_CERTIFICATE,
} from 'src/utils/types'

const defaultUserState = {
  data: {
    listUser: [],
    listDegree: [],
    error: '',
    refreshing: false,
    isSearching: false,
  },
}

export default function users(state = defaultUserState, action) {
  switch (action.type) {
    case USERS.REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case USERS.SUCCESS:
      return {
        ...state,
        // data:action.payload,
        ...action.payload,

        error: false,
        loading: false,
      }
    case USERS.FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true,
      }
    // ====================== Degree ============================ //
    case DEGREE.REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case DEGREE.SUCCESS:
      return {
        ...state,
        ...action.payload,
        // data:action.payload,
        error: false,
        loading: false,
      }
    case DEGREE.FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }

    // ====================== Create User ============================ //
    case CREATE_USER.REQUEST:
      return {
        ...state,
      }
    case CREATE_USER.SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case CREATE_USER.FAILURE:
      return {
        ...state,
      }
    // ======================= Delete User ============= //
    case DELETE_USER.REQUEST:
      return {
        ...state,
        loading: true,
        statusDelete: 0,
      }
    case DELETE_USER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        // data:action.payload,
        statusDelete: 1,
        loading: false,
      }
    case DELETE_USER.FAILURE:
      return {
        ...state,
        statusDelete: 2,
        loading: false,
      }
    // ======================= Update User ============= //
    case UPDATE_USER.REQUEST:
      return {
        ...state,
        loading: true,
        statusUpdate: 0,
      }
    case UPDATE_USER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        // data:action.payload,
        statusUpdate: 1,
        loading: false,
      }
    case UPDATE_USER.FAILURE:
      return {
        ...state,
        statusUpdate: 2,
        loading: false,
      }

    // ======================= Search User ============= //
    case SEARCH_USER.REQUEST:
      return {
        ...state,
        isSearching: true,
      }
    case SEARCH_USER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        isSearching: false,
      }
    case SEARCH_USER.FAILURE:
      return {
        ...state,
        ...action.payload,
        isSearching: false,
      }

    // ======================= forgerPassword User ============= //
    case FORGETPASSWORD_USER.REQUEST:
      return {
        ...state,
        loading: true,
        statusForget: 0,
      }
    case FORGETPASSWORD_USER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        statusForget: 1,
      }
    case FORGETPASSWORD_USER.FAILURE:
      return {
        ...state,
        ...action.payload,
        statusForget: 2,
      }

    // ======================= verify certificate  ============= //
    case VERIFY_CERTIFICATE.REQUEST:
      return {
        ...state,
        loading: true,
        verify_certificate: 0,
      }
    case VERIFY_CERTIFICATE.SUCCESS:
      return {
        ...state,
        ...action.payload,
        verify_certificate: 1,
      }
    case VERIFY_CERTIFICATE.FAILURE:
      return {
        ...state,
        ...action.payload,
        verify_certificate: 2,
      }

    // ================== Drop Action ================ //
    case DROP_ACTION.REQUEST:
      return {
        ...state,
        ...action.payload,
        refreshing: false,
        errorCreate: 0,
        statusDelete: 0,
        statusUpdate: 0,
        statusForget: 0,
        verify_certificate: 0,
      }
    // ================== One user ================ //
    case ONE_USER.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ONE_USER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case ONE_USER.FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }

    // ================== merge user ================ //
    case MERGE_USER.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case MERGE_USER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case MERGE_USER.FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }

    default:
      return state
  }
}
