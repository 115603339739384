import {
  LIST_NEWS_CATEGORIES,
  CREATE_NEWS_CATEGORY,
  ONE_NEWS_CATEGORY,
  UPDATE_NEWS_CATEGORY,
  DELETE_NEWS_CATEGORY,
} from '../../../utils/types'

const defaultState = {
  newCategories: null,
}

export default function newsCategory(state = defaultState, action) {
  switch (action.type) {
    //list Categories
    case LIST_NEWS_CATEGORIES.REQUEST:
      state.newCategories = action.payload
      return {
        ...state,
        loading: true,
      }
    case LIST_NEWS_CATEGORIES.SUCCESS:
      console.log('action.payload', action.payload.newsCategory)
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case LIST_NEWS_CATEGORIES.FAILURE:
      return { ...state, error: action.error, loading: false }

    //One category
    case ONE_NEWS_CATEGORY.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case ONE_NEWS_CATEGORY.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case ONE_NEWS_CATEGORY.FAILURE:
      return { ...state, error: action.error, loading: false }

    //Create Category
    case CREATE_NEWS_CATEGORY.REQUEST:
    case CREATE_NEWS_CATEGORY.SUCCESS:
    case CREATE_NEWS_CATEGORY.FAILURE:

    //Create Category
    case UPDATE_NEWS_CATEGORY.REQUEST:
    case UPDATE_NEWS_CATEGORY.SUCCESS:
    case UPDATE_NEWS_CATEGORY.FAILURE:

    //Create Category
    case DELETE_NEWS_CATEGORY.REQUEST:
    case DELETE_NEWS_CATEGORY.SUCCESS:
    case DELETE_NEWS_CATEGORY.FAILURE:

    default:
      return state
  }
}
