import { message } from "antd"
import { push } from "connected-react-router"
import { all, call, put, takeLatest } from "redux-saga/effects"
import { fetchCoordinatesAPI, fetchCoordinatesCMEAPI, postCoordinatesCMEAPI } from "src/utils/api"
import { FETCH_COORDINATES_CME, POST_COORDINATES_CME } from "src/utils/types"


function* postCoordinatesCMESaga(body) {

  const payload = body.payload
  const arrData = Object.entries(payload.CME) // arr == arr
  // const arrData = Object.values(payload) // arr == value
  const seminarId = payload.id
  const obj = {
    arrData,
    seminarId
  }
  console.log(obj,"body");
  const responseSeminar = yield call(postCoordinatesCMEAPI,obj)
  try {

     if(responseSeminar.ok){
      const responseSeminarJson = yield responseSeminar.json()

      yield put({
              type: POST_COORDINATES_CME.SUCCESS,
              payload:{
                coordinatesSeminar: responseSeminarJson
              },
      })
      message.success('Cập nhật thông tin chứng chỉ thành công.', 5);
      yield put(push('/dashboard/seminars'))
     }else{
        yield put({
          type: POST_COORDINATES_CME.FAILURE,

        })
     }

  }catch(error){
      yield put({ type: POST_COORDINATES_CME.FAILURE, error })
  }
}

function* fetchCoordinatesCMESaga(payload) {

  console.log(payload.payload,"payload");
  const id =  payload.payload
  const responseSeminar = yield call(fetchCoordinatesCMEAPI,id)

  try {
    if(responseSeminar.ok){
      const responseSeminarJson = yield responseSeminar.json()
      yield put({
              type: FETCH_COORDINATES_CME.SUCCESS,
              payload:{
                coordinatesCME: responseSeminarJson
              },
      })
    }else{
      yield put({
        type: FETCH_COORDINATES_CME.FAILURE,
      })
    }


  }catch(error){
      yield put({ type: FETCH_COORDINATES_CME.FAILURE, error })
  }
}

function* authMonitor() {
    yield all([
      takeLatest(POST_COORDINATES_CME.REQUEST, postCoordinatesCMESaga),
      takeLatest(FETCH_COORDINATES_CME.REQUEST, fetchCoordinatesCMESaga),

    ])
}

export default authMonitor
