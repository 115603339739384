import { LIST_CONTACTS, ONE_CONTACT, DELETE_CONTACT, FEEDBACK_CONTACT } from '../../../utils/types'

const defaultState = {
  contacts: [],
  page: 0,
  limit: 0,
  totalCount: 0,
  oneContact: null,
}

export default function contact(state = defaultState, action) {
  switch (action.type) {
    case LIST_CONTACTS.REQUEST:
      console.log('LIST_CONTACTS.REQUEST:')
      return {
        ...state,
        statusList: 0,
      }
    case LIST_CONTACTS.SUCCESS:
      console.log('LIST_CONTACTS.SUCCESS')
      console.log(action.payload.contacts)
      console.log('LIST_CONTACTS.SUCCESS')
      state.contacts = action.payload.contacts
      state.page = action.payload.page
      state.limit = action.payload.limit
      state.totalCount = action.payload.totalCount
      console.log('state', state)
      return {
        ...state,
        ...action.payload,
        loading: false,
        statusList: 1,
      }
    case LIST_CONTACTS.FAILURE:
      return { ...state, error: action.error, loading: false, statusList: 2 }
    //
    //one Contact
    case ONE_CONTACT.REQUEST:
      return {
        ...state,
        ...action.payload,
      }
    case ONE_CONTACT.SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case ONE_CONTACT.FAILURE:
    //delete Contact
    case DELETE_CONTACT.REQUEST:
    case DELETE_CONTACT.SUCCESS:
    case DELETE_CONTACT.FAILURE:

    //feedback contact
    case FEEDBACK_CONTACT.REQUEST:
    case FEEDBACK_CONTACT.SUCCESS:
    case FEEDBACK_CONTACT.FAILURE:
    default:
      return state
  }
}
