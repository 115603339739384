import { takeLatest, put, call, all } from 'redux-saga/effects'
import { SIGNATURE_ZOOM } from '../../../utils/types'
import { getSignatureLiveMeeting } from 'src/utils/api'
import { reject } from 'lodash'

function* fetchSignature({ payload: body, joinMeeting }) {
  try {
    
    const response = yield call(getSignatureLiveMeeting, body)

   

    if (response.ok) {
      const responseJson = yield response.json()

   
      if (!status) {
        yield put({
          type: SIGNATURE_ZOOM.SUCCESS,
          payload: {
            signature: responseJson.signature,
            meetConfig: responseJson.meetConfig,
          },
        })
        joinMeeting(responseJson.signature, responseJson.meetConfig)
      } else {
        yield put({ type: SIGNATURE_ZOOM.FAILURE })
        yield call(reject)
      }
    } else {
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.error(error)
    yield put({ type: SIGNATURE_ZOOM.FAILURE, error })
    yield call(reject)
  }
}

// Monitoring Sagas
function* zoomMeetingMonitor() {
  yield all([takeLatest(SIGNATURE_ZOOM.REQUEST, fetchSignature)])
}

export default zoomMeetingMonitor
