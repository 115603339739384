import { takeLatest, put, call, all } from 'redux-saga/effects'
import { LIST_NEWS, CREATE_NEWS, ONE_NEWS, UPDATE_NEWS, DELETE_NEWS } from '../../../utils/types'
import { getNewsList, createNews, getOneNews, updateNews, deleteNews } from 'src/utils/api'
import { reject } from 'lodash'

function* findAllNews({ payload: searchText }) {
  
  try {
    
    const response = yield call(getNewsList, searchText)
    
    
    

    if (response.ok) {
      const responseJson = yield response.json()
      console.log('LISTNEWS.REQUEST')
      console.log(responseJson.data)
      console.log('LISTNEWS.REQUEST')
      if (!responseJson.message) {
        yield put({
          type: LIST_NEWS.SUCCESS,
          payload: {
            newsList: responseJson.data,
          },
        })
      } else {
        yield put({ type: LIST_NEWS.FAILURE })
        yield call(reject)
      }
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: LIST_NEWS.FAILURE })
    yield call(reject)
  }
}

function* addNews({ payload: body, handleNotification }) {
  try {
  
    const response = yield call(createNews, body)
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      if (responseJson.message) {
        yield put({ type: CREATE_NEWS.FAILURE })
        handleNotification(responseJson.message)
      } else {
        yield put({
          type: CREATE_NEWS.SUCCESS,
        })
        handleNotification('Thêm thành công')
      }
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: CREATE_NEWS.FAILURE, error })
    handleNotification('Thêm thất bại')
    yield call(reject)
  }
}

function* findOneNews({ payload: slug, handleOpenModalUpdate }) {
  try {
    
    const response = yield call(getOneNews, slug)
    
    
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      
      
      yield put({
        type: ONE_NEWS.SUCCESS,
        payload: {
          oneNews: responseJson,
        },
      })
      if (handleOpenModalUpdate) {
        handleOpenModalUpdate()
      }
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: ONE_NEWS.FAILURE, error })
    yield call(reject)
  }
}

function* editNews({ payload: body, handleNotification, setIsModalUpdate }) {
  try {
  
    const response = yield call(updateNews, body)
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      if (responseJson.message) {
        yield put({ type: CREATE_NEWS.FAILURE })
        handleNotification(responseJson.message)
      } else {
        yield put({
          type: UPDATE_NEWS.SUCCESS,
        })

        handleNotification('Sửa thành công')
        setIsModalUpdate(false)
      }
    } else {
      handleNotification('Sửa thất bại')
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_NEWS.FAILURE, error })
    handleNotification('Sửa thất bại')
    yield call(reject)
  }
}

function* removeNews({ payload: id, handleNotification }) {
  try {
    
    const response = yield call(deleteNews, id)
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      yield put({
        type: DELETE_NEWS.SUCCESS,
      })
      handleNotification('Xoá thành công')
    } else {
      handleNotification('Xoá thất bại')
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: DELETE_NEWS.FAILURE, error })
    handleNotification('Xoá thất bại')
    yield call(reject)
  }
}

// Monitoring Sagas
function* newsMonitor() {
  yield all([takeLatest(LIST_NEWS.REQUEST, findAllNews)])

  yield all([takeLatest(CREATE_NEWS.REQUEST, addNews)])

  yield all([takeLatest(ONE_NEWS.REQUEST, findOneNews)])

  yield all([takeLatest(UPDATE_NEWS.REQUEST, editNews)])

  yield all([takeLatest(DELETE_NEWS.REQUEST, removeNews)])
}

export default newsMonitor
