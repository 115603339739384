import { LIST_POLL, ONE_POLL, CREATE_POLL, EDIT_POLL, DELETE_POLL } from 'src/utils/types'

const defaultState = {
  data: [],
}

export default function seminarPanelist(state = defaultState, action) {
  switch (action.type) {
    case LIST_POLL.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LIST_POLL.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case LIST_POLL.FAILURE:
      return { ...state, error: action.error, loading: false }

    case ONE_POLL.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ONE_POLL.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case ONE_POLL.FAILURE:
      return { ...state, error: action.error, loading: false }
    //

    case CREATE_POLL.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CREATE_POLL.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case CREATE_POLL.FAILURE:
      return { ...state, error: action.error, loading: false }

    case EDIT_POLL.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case EDIT_POLL.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case EDIT_POLL.FAILURE:
      return { ...state, error: action.error, loading: false }

    case DELETE_POLL.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case DELETE_POLL.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case DELETE_POLL.FAILURE:
      return { ...state, error: action.error, loading: false }
    default:
      return state
  }
}
