import { notification } from 'antd'
import jwt_decode from 'jwt-decode'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

function omitDeepArrayWalk(arr, key) {
  return arr.map((val) => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key)
    if (typeof val === 'object') return omitDeep(val, key)
    return val
  })
}

export const omitDeep = (obj, key) => {
  const keys = Object.keys(obj)
  const newObj = {}
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i]
      if (val instanceof Date) newObj[i] = val
      else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key)
      else if (typeof val === 'object' && val !== null) newObj[i] = omitDeep(val, key)
      else newObj[i] = val
    }
  })

  return newObj
}

export function isExpToken(token) {
  const decoded = jwt_decode(token)

  const { exp } = decoded

  return exp * 1000 <= Date.now()
}

export function commonNotify(config) {
  notification[config.type]({
    ...config,
  })
}

export async function exportDataToExcel(data, columns, fileName) {
  if (!data || (data && data.length === 0)) {
    commonNotify({ message: 'Không có dữ liệu để export', type: 'warning' })
    return
  }

  if (!columns || (columns && columns.length === 0)) {
    commonNotify({ message: 'Header file không hợp lệ', type: 'warning' })
    return
  }

  const workbook = new ExcelJS.Workbook()
  workbook.addWorksheet('sheet1')

  const worksheet = workbook.getWorksheet('sheet1')
  worksheet.columns = columns
  worksheet.addRows(data)

  columns.map((c, index) => {
    worksheet.getColumn(index + 1).alignment = { vertical: 'middle', wrapText: true }
  })

  worksheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '538DD5' },
    bgColor: { argb: 'FFFFFF' },
  }

  worksheet.getRow(1).font = {
    bold: true,
    color: { argb: 'FFFFFFFF' },
  }

  worksheet.getRow(1).border = {
    top: { style: 'double', color: { argb: 'FFFFFFFF' } },
    left: { style: 'double', color: { argb: 'FFFFFFFF' } },
    bottom: { style: 'double', color: { argb: 'FFFFFFFF' } },
    right: { style: 'double', color: { argb: 'FFFFFFFF' } },
  }

  worksheet.autoFilter = {
    from: {
      row: 1,
      column: 1,
    },
    to: {
      row: 1,
      column: columns.length,
    },
  }

  const uint8Array = await workbook.xlsx.writeBuffer()

  const blob = new Blob([uint8Array], { type: 'application/octet-binary' })
  saveAs(blob, fileName || `file-${Date.now()}.xlsx`)
}
