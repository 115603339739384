import { takeLatest, put, call, all } from 'redux-saga/effects'
import {
  LIST_CATEGORIES,
  CREATE_CATEGORY,
  ONE_CATEGORY,
  CREATE_CHILD_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_CHILD_CATEGORY,
  DELETE_CATEGORY,
  DELETE_CHILD_CATEGORY,
} from '../../../utils/types'
import {
  getCategoriesList,
  createCategory,
  getOneCategory,
  updateChildCategory,
  createChildCategory,
  updateCategory,
  deleteCategory,
  deleteChildCategory,
} from 'src/utils/api'
import { reject } from 'lodash'

function* findAllCategories() {
  try {
    
    const response = yield call(getCategoriesList)
    
    
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      console.log(responseJson.category)
      
      yield put({
        type: LIST_CATEGORIES.SUCCESS,
        payload: {
          category: responseJson.category,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: LIST_CATEGORIES.FAILURE, error })
    yield call(reject)
  }
}

function* addCategory({ payload: body }) {
  try {
  
    const response = yield call(createCategory, body)
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      yield put({
        type: CREATE_CATEGORY.SUCCESS,
      })
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: CREATE_CATEGORY.FAILURE, error })
    yield call(reject)
  }
}

function* findOneCategory({ payload: id, handleOpenModalUpdate }) {
  try {
    
    const response = yield call(getOneCategory, id)
    
    
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      
      
      yield put({
        type: ONE_CATEGORY.SUCCESS,
        payload: {
          oneCategory: responseJson,
        },
      })
      if (handleOpenModalUpdate) {
        handleOpenModalUpdate()
      }
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: ONE_CATEGORY.FAILURE, error })
    yield call(reject)
  }
}

function* addChildCategory({ payload: body, fetchCategory, handleOpenModalUpdate }) {
  try {
  
    const response = yield call(createChildCategory, body)
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      yield put({
        type: CREATE_CHILD_CATEGORY.SUCCESS,
      })
      fetchCategory(body.parentCateId, handleOpenModalUpdate())
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: CREATE_CHILD_CATEGORY.FAILURE, error })
    yield call(reject)
  }
}

function* editChildCategory({ payload: body, fetchCategory, handleOpenModalUpdate }) {
  try {
  
    const response = yield call(updateChildCategory, body)
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      yield put({
        type: UPDATE_CHILD_CATEGORY.SUCCESS,
      })
      fetchCategory(body.parentCateId, handleOpenModalUpdate())
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_CHILD_CATEGORY.FAILURE, error })
    yield call(reject)
  }
}

function* editCategory({ payload: body, fetchCategory, handleOpenModalUpdate }) {
  try {
  
    const response = yield call(updateCategory, body)
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      yield put({
        type: UPDATE_CATEGORY.SUCCESS,
      })

      fetchCategory(body.id, handleOpenModalUpdate())
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_CATEGORY.FAILURE, error })
    yield call(reject)
  }
}

function* removeCategory({ payload: id }) {
  try {
    
    const response = yield call(deleteCategory, id)
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      yield put({
        type: DELETE_CATEGORY.SUCCESS,
      })
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: DELETE_CATEGORY.FAILURE, error })
    yield call(reject)
  }
}

function* removeChildCategory({ payload: body, fetchCategory, handleOpenModalUpdate }) {
  try {
  
    const response = yield call(deleteChildCategory, body.id)
    

    if (response.ok) {
      const responseJson = yield response.json()
      
      yield put({
        type: DELETE_CHILD_CATEGORY.SUCCESS,
      })

      fetchCategory(body.parentCateId, handleOpenModalUpdate())
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: DELETE_CHILD_CATEGORY.FAILURE, error })
    yield call(reject)
  }
}

// Monitoring Sagas
function* categoryMonitor() {
  yield all([takeLatest(LIST_CATEGORIES.REQUEST, findAllCategories)])

  yield all([takeLatest(CREATE_CATEGORY.REQUEST, addCategory)])

  yield all([takeLatest(ONE_CATEGORY.REQUEST, findOneCategory)])

  yield all([takeLatest(CREATE_CHILD_CATEGORY.REQUEST, addChildCategory)])

  yield all([takeLatest(UPDATE_CHILD_CATEGORY.REQUEST, editChildCategory)])

  yield all([takeLatest(UPDATE_CATEGORY.REQUEST, editCategory)])

  yield all([takeLatest(DELETE_CHILD_CATEGORY.REQUEST, removeChildCategory)])

  yield all([takeLatest(DELETE_CATEGORY.REQUEST, removeCategory)])
}

export default categoryMonitor
