import {
  LIST_SEMINARS,
  CREATE_SEMINAR,
  UPDATE_SEMINAR,
  DELETE_SEMINAR,
  LIST_CATEGORIES,
  LIST_REPORTERS,
  LIST_MODERATORS,
  LIST_SPONSORS,
  LIST_RANK_SPONSORS,
  CHANGE_SEMINAR_PAGINATION,
  ONE_SEMINAR,
  LIST_SESSION,
  SEND_MAIL_SEMINAR,
  SET_DEFAULT_STATUS,
  LIST_SEMINARS_FULL,
  CREATE_LIVE_MEETING,
  PUBLIC_SEMINAR,
  CREATE_SEMINAR_PN,
  DROP_ACTION,
  UPDATE_CONTENT_EMAIL,
  SET_DEFAULT_BTN_CME_STATUS,
  UPDATE_CONTENT_EMAIL_PUBLIC,
  RECORD_SEMINAR,
  TEST_MAIL_CUTOFF,
  UPDATE_KEYLIVE_SEMINAR,
  SEND_ALL_USERS_SEMINAR_PUBLIC,
  UPDATE_DOCUMENTS,
  GET_DOCUMENTS,
} from '../../../utils/types'

/* const initialState = {
  loading = false,
  totalCount: 0,
  page: 1,
  limit: 10,
  data: null,
} */

const defaultState = {
  categories: [],
  reporters: [],
  moderator: [],
  sponsors: [],
  rankSponsors: [],
  sessions: [],
  seminar: [],
  page: 0,
  limit: 0,
  totalCount: 0,
  statusOneSeminar: 0,
  isUpdatingKey: false,
  updatingDocuments: false,
  documentList: [],
}

export default function seminar(state = defaultState, action) {
  switch (action.type) {
    //Change pagination
    case CHANGE_SEMINAR_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      }
    /*     case SET_DEFAULT_STATUS:
      console.log('----------SET STATUS LIST----------------')
      state.statusOneSeminar = 0
      return {
        ...state,
      } */
    case LIST_SEMINARS.REQUEST:
      console.log('recuder seminar request')
      console.log(state)
      return {
        ...state,
        loading: true,
        error: '',
        statusList: 0,
      }
    case LIST_SEMINARS.SUCCESS:
      state.seminars = action.payload.seminar
      state.page = action.payload.page
      state.limit = action.payload.limit
      state.totalCount = action.payload.totalCount
      return {
        ...state,
        ...action.payload,
        loading: false,
        statusList: 1,
      }
    case LIST_SEMINARS.FAILURE:
      return { ...state, error: action.error, loading: false, statusList: 2 }
    //list Categories
    case LIST_CATEGORIES.REQUEST:
    case LIST_CATEGORIES.SUCCESS:
      state.categories = action.payload
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case LIST_CATEGORIES.FAILURE:
      return { ...state, error: action.error, loading: false }
    //list SPONSOR
    case LIST_SPONSORS.REQUEST:
    case LIST_SPONSORS.SUCCESS:
      state.sponsors = action.payload
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case LIST_SPONSORS.FAILURE:
      return { ...state, error: action.error, loading: false }
    //list RANK SPONSOR
    case LIST_RANK_SPONSORS.REQUEST:
    case LIST_RANK_SPONSORS.SUCCESS:
      console.log('LIST_RANK_SPONSORS.SUCCESS')

      console.log('LIST_RANK_SPONSORS.SUCCESS')
      state.rankSponsors = action.payload
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case LIST_RANK_SPONSORS.FAILURE:
      return { ...state, error: action.error, loading: false }
    //list Moderators
    case LIST_MODERATORS.REQUEST:
    case LIST_MODERATORS.SUCCESS:
      console.log('LIST_MODERATORS.SUCCESS')

      console.log('LIST_MODERATORS.SUCCESS')
      state.moderator = action.payload
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case LIST_MODERATORS.FAILURE:
      return { ...state, error: action.error, loading: false }
    //list Reporters
    case LIST_REPORTERS.REQUEST:
    case LIST_REPORTERS.SUCCESS:
      state.reporters = action.payload
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case LIST_REPORTERS.FAILURE:
      return { ...state, error: action.error, loading: false }
    //one Seminar
    case ONE_SEMINAR.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        statusOneSeminar: 0,
      }
    case ONE_SEMINAR.SUCCESS:
      state.oneSeminar = action.payload
      return {
        ...state,
        ...action.payload,
        seminarData: action.payload,
        loading: false,
        statusOneSeminar: 1,
      }
    case ONE_SEMINAR.FAILURE:
      return { ...state, error: action.error, loading: false, statusOneSeminar: 2 }
    //create Seminar
    case CREATE_SEMINAR.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case CREATE_SEMINAR.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case CREATE_SEMINAR.FAILURE:
      return { ...state, error: action.error, loading: false }
    //create Seminar PN2
    case CREATE_SEMINAR_PN.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        status_step1: 0,
      }
    case CREATE_SEMINAR_PN.SUCCESS:
      return {
        ...action,
        status_step1: 1,
      }
    case CREATE_SEMINAR_PN.FAILURE:
      return { status_step1: 2 }
    //update Seminar
    case UPDATE_SEMINAR.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case UPDATE_SEMINAR.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case UPDATE_SEMINAR.FAILURE:
      return { ...state, error: action.error, loading: false }
    //delete Seminar
    case DELETE_SEMINAR.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case DELETE_SEMINAR.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case DELETE_SEMINAR.FAILURE:
      return {
        ...state,
        loading: false,
        error: '',
      }
    //sendMail Seminar
    case SEND_MAIL_SEMINAR.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case SEND_MAIL_SEMINAR.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case SEND_MAIL_SEMINAR.FAILURE:
      return {
        ...state,
        loading: false,
        error: '',
      }
    case SEND_ALL_USERS_SEMINAR_PUBLIC.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case SEND_ALL_USERS_SEMINAR_PUBLIC.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case SEND_ALL_USERS_SEMINAR_PUBLIC.FAILURE:
      return {
        ...state,
        loading: false,
        error: '',
      }

    //sendMail Cutoff
    case TEST_MAIL_CUTOFF.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case TEST_MAIL_CUTOFF.SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case TEST_MAIL_CUTOFF.FAILURE:
      return {
        ...state,
        loading: false,
      }

    //FULL Seminar for exportExcel
    case LIST_SEMINARS_FULL.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case LIST_SEMINARS_FULL.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case LIST_SEMINARS_FULL.FAILURE:
      return {
        ...state,
        loading: false,
        error: '',
      }

    //create live meeting
    case CREATE_LIVE_MEETING.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case CREATE_LIVE_MEETING.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case CREATE_LIVE_MEETING.FAILURE:
      return {
        ...state,
        loading: false,
        error: '',
      }

    //public seminar
    case PUBLIC_SEMINAR.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case PUBLIC_SEMINAR.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case PUBLIC_SEMINAR.FAILURE:
      return {
        ...state,
        loading: false,
        error: '',
      }

    //UPDATE_CONTENT_EMAIL
    case UPDATE_CONTENT_EMAIL.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        status_update_content_email: 0,
      }
    case UPDATE_CONTENT_EMAIL.SUCCESS:
      return {
        ...state,
        loading: false,
        status_update_content_email: 1,
      }
    case UPDATE_CONTENT_EMAIL.FAILURE:
      return {
        ...state,
        loading: false,
        error: '',
        status_update_content_email: 2,
      }

    //SET_DEFAULT_BTN_CME_STATUS
    case SET_DEFAULT_BTN_CME_STATUS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        statusSetBTNCME: 0,
      }
    case SET_DEFAULT_BTN_CME_STATUS.SUCCESS:
      return {
        ...state,
        loading: false,
        statusSetBTNCME: 1,
      }
    case SET_DEFAULT_BTN_CME_STATUS.FAILURE:
      return {
        ...state,
        loading: false,
        error: '',
        statusSetBTNCME: 2,
      }

    //UPDATE_CONTENT_EMAIL
    case UPDATE_CONTENT_EMAIL_PUBLIC.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        status_update_content_email_public: 0,
      }
    case UPDATE_CONTENT_EMAIL_PUBLIC.SUCCESS:
      return {
        ...state,
        loading: false,
        status_update_content_email_public: 1,
      }
    case UPDATE_CONTENT_EMAIL_PUBLIC.FAILURE:
      return {
        ...state,
        loading: false,
        error: '',
        status_update_content_email_public: 2,
      }

    //UPDATE_RECORD
    case RECORD_SEMINAR.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        status_record_seminar: 0,
      }
    case RECORD_SEMINAR.SUCCESS:
      return {
        ...state,
        loading: false,
        status_record_seminar: 1,
      }
    case RECORD_SEMINAR.FAILURE:
      return {
        ...state,
        loading: false,
        error: '',
        status_record_seminar: 2,
      }

    //update key youtube
    case UPDATE_KEYLIVE_SEMINAR.REQUEST:
      return {
        ...state,
        isUpdatingKey: true,
      }
    case UPDATE_KEYLIVE_SEMINAR.SUCCESS:
      return {
        ...state,
        isUpdatingKey: false,
      }
    case UPDATE_KEYLIVE_SEMINAR.FAILURE:
      return {
        ...state,
        isUpdatingKey: false,
      }

    //load documents
    case GET_DOCUMENTS.SUCCESS:
      return {
        ...state,
        documentList: action.payload,
      }

    //update documents
    case UPDATE_DOCUMENTS.REQUEST:
      return {
        ...state,
        updatingDocuments: true,
      }
    case UPDATE_DOCUMENTS.SUCCESS:
      return {
        ...state,
        updatingDocuments: false,
      }
    case UPDATE_DOCUMENTS.FAILURE:
      return {
        ...state,
        updatingDocuments: false,
      }

    case DROP_ACTION.REQUEST:
      return {
        status_step1: 0,
        statusSetBTNCME: 0,
        status_update_content_email_public: 0,
        status_record_seminar: 0,
      }

    default:
      return state
  }
}
