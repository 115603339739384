import { push } from "connected-react-router";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {  fetchCoordinatesAPI, fetchSeminars, postCoordinatesAPI } from "src/utils/api";
import { FETCH_COORDINATES, FETCH_SEMINAR, POST_COORDINATES1 } from "src/utils/types";
import { message } from 'antd';

function* fetchSeminarsSaga() {
    const responseSeminar = yield call(fetchSeminars)
    try {

        const responseSeminarJson = yield responseSeminar.json()


        yield put({
                type: FETCH_SEMINAR.SUCCESS,
                payload:{
                  listSeminar:responseSeminarJson
                },
        })

    }catch(error){
        yield put({ type: FETCH_SEMINAR.FAILURE, error })
    }
}

function* postCoordinatesSaga(body) {
 
  try {
    const payload = body.payload
    const responseSeminar = yield call(postCoordinatesAPI, payload)
     if(responseSeminar.ok){
      const responseSeminarJson = yield responseSeminar.json()

      yield put({
              type: POST_COORDINATES1.SUCCESS,
              payload:{
                coordinatesSeminar: responseSeminarJson
              },
      })
      message.success('Cập nhật thành công.')
      yield put(push('/dashboard/seminars'))
     }else{
        yield put({
          type: POST_COORDINATES1.FAILURE,
        })
     }

  }catch(error){
      yield put({ type: POST_COORDINATES1.FAILURE, error })
  }
}

function* fetchCoordinatesSaga(payload) {

  console.log(payload.payload,"payload");
  const id =  payload.payload
  const responseSeminar = yield call(fetchCoordinatesAPI,id)

  try {
    if(responseSeminar.ok){
      const responseSeminarJson = yield responseSeminar.json()
      yield put({
              type: FETCH_COORDINATES.SUCCESS,
              payload:{
                coordinatesTicket: responseSeminarJson
              },
      })
    }else{
      yield put({
        type: FETCH_COORDINATES.FAILURE,
      })
    }


  }catch(error){
      yield put({ type: FETCH_COORDINATES.FAILURE, error })
  }
}

function* designTicketMonitor() {
    yield all([ takeLatest(POST_COORDINATES1.REQUEST, postCoordinatesSaga) ])
    yield all([ takeLatest(FETCH_COORDINATES.REQUEST, fetchCoordinatesSaga) ])
}

export default designTicketMonitor
