import { log } from 'lodash-decorators/utils'
import {
  LIST_ORDERS,
  ONE_ORDER,
  CONFIRM_ORDER,
  CANCEL_ORDER,
  LIST_ORDERS_FULL,
  RESEND_MAIL_ORDER,
  DROP_ACTION,
  GET_SEMINAR_EXPORT,
  CALL_EXPORT_ORDER,
} from '../../../utils/types'

const defaultState = {
  listOrders: [],
  page: 0,
  limit: 0,
  totalCount1: 0,
  type: '',
  isExporting: false,
  isFetchingOrder: false,
}

export default function order(state = defaultState, action) {
  switch (action.type) {
    case LIST_ORDERS.REQUEST:
      return {
        ...state,
        ...action.payload,
        statusList: 0,
        isFetchingOrder: true,
      }
    case LIST_ORDERS.SUCCESS:
      if (state.type === 'all') {
        state.page = action.payload.page

        state.limit = action.payload.limit
        state.totalCount1 = action.payload.data.All.quantity

        state.listOrders = action.payload.data.All.data
      } else if (state.type === 'success') {
        state.page = action.payload.page

        state.limit = action.payload.limit

        state.totalCount1 = action.payload.data.Success.quantity

        state.listOrders = action.payload.data.Success.data
      } else if (state.type === 'pending') {
        state.page = action.payload.page

        state.limit = action.payload.limit

        state.totalCount1 = action.payload.data.Pending.quantity

        state.listOrders = action.payload.data.Pending.data
      } else if (state.type === 'waiting') {
        state.page = action.payload.page

        state.limit = action.payload.limit

        state.totalCount1 = action.payload.data.Waiting.quantity

        state.listOrders = action.payload.data.Waiting.data
      } else if (state.type === 'fail') {
        state.page = action.payload.page

        state.limit = action.payload.limit

        state.totalCount1 = action.payload.data.Fail.quantity

        state.listOrders = action.payload.data.Fail.data
      }

      return {
        ...state,
        ...action.payload,
        payload: action.payload,
        loading: false,
        isFetchingOrder: false,
        statusList: 1,
      }
    case LIST_ORDERS.FAILURE:
      return { ...state, error: action.error, loading: false, isFetchingOrder: false, statusList: 2 }
    // ONE ORDER
    case ONE_ORDER.REQUEST:
      return {
        ...state,
        ...action.payload,
      }
    case ONE_ORDER.SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    // case ONE_ORDER.FAILURE:
    // //Cancel Order
    // case CANCEL_ORDER.REQUEST:
    // case CANCEL_ORDER.SUCCESS:
    // case CANCEL_ORDER.FAILURE:
    // //ConfirmOrder
    // case CONFIRM_ORDER.REQUEST:
    case CONFIRM_ORDER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        confirm_status_order: 1,
      }
    // case CONFIRM_ORDER.FAILURE:

    // FULL Seminar for exportExcel
    case LIST_ORDERS_FULL.REQUEST:
      return {
        ...state,
        status_resend_mail: 0,
        isExporting: true,
      }
    case LIST_ORDERS_FULL.SUCCESS:
      return {
        ...state,
        status_resend_mail: 1,
        isExporting: false,
      }
    case LIST_ORDERS_FULL.FAILURE:
      return {
        ...state,
        status_resend_mail: 2,
        isExporting: false,
      }

    // get seminar

    case GET_SEMINAR_EXPORT.REQUEST:
      return {
        ...state,
        status_seminar: 0,
      }
    case GET_SEMINAR_EXPORT.SUCCESS:
      return {
        ...state,
        ...action.payload,
        status_seminar: 1,
      }
    case GET_SEMINAR_EXPORT.FAILURE:
      return {
        ...state,
        status_seminar: 2,
      }

    // call data export

    case CALL_EXPORT_ORDER.REQUEST:
      return {
        ...state,
        status_call_data_export: 0,
      }
    case CALL_EXPORT_ORDER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        status_call_data_export: 1,
      }
    case CALL_EXPORT_ORDER.FAILURE:
      return {
        ...state,
        status_call_data_export: 2,
      }

    case DROP_ACTION.REQUEST:
      return {
        ...state,
        status_resend_mail: 0,
        status_seminar: 0,
        confirm_status_order: 0,
      }
    // resendMail
    // case RESEND_MAIL_ORDER.REQUEST:
    // case RESEND_MAIL_ORDER.SUCCESS:
    //   return {
    //     ...state,
    //     ...action.payload,
    //   }
    // case RESEND_MAIL_ORDER.FAILURE:
    default:
      return state
  }
}
