import React from 'react'
import { Route } from 'react-router-dom'
import NotFound from 'src/containers/Exception/404'

const renderRoutes = (routes = [], extraProps = {}, role) =>

  routes.map((route, i) => (
    <Route
      key={route.key || i}
      path={route.path}
      exact={route.exact}
      strict={route.strict}
      render={(props) => {
        // if (route.model && role) {
        //   if (!role.includes(route.model)) {
        //     return <NotFound />
        //   }
        // }
        return route.render ? (
          route.render({ ...props, ...extraProps, route })
        ) : (
          <route.component {...props} {...extraProps} route={route} />
        )
      }}
    />
  ))

export { renderRoutes }
