import React from 'react'
import { Layout } from 'antd'
import { Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { flowRight as compose } from 'lodash'
import { renderRoutes } from 'src/utils/services/router'

import GlobalHeader from '../components/GlobalHeader'
import GlobalFooter from '../components/GlobalFooter'
import SiderMenu from '../components/SiderMenu'
import { getMenuData } from '../common/menu'
import { logout } from '../containers/Auth/actions'
import "./basicLayout.css";

const { Content, Header, Footer } = Layout

class BasicLayout extends React.PureComponent {
  state = {
    collapsed: false,
  }

  badgeNew = () => {
    const {
      data: {
        ageVerification = { totalDocs: 0 },
        salaryVerification = { totalDocs: 0 },
        aboutVerification = { totalDocs: 0 },
        todayWordVerification = { totalDocs: 0 },
        imageVerification = { totalDocs: 0 },
        adminGetFeedbackList = { totalDocs: 0 },
      },
    } = this.props
    return [
      {
        name: 'Verifications',
        badge: salaryVerification.totalDocs + ageVerification.totalDocs,
      },
      {
        name: 'Image',
        badge: imageVerification.totalDocs,
      },
      {
        name: 'About',
        badge: aboutVerification.totalDocs,
      },
      {
        name: 'Tweet',
        badge: todayWordVerification.totalDocs,
      },
      {
        name: 'Feedback',
        badge: adminGetFeedbackList.totalDocs,
      },
    ]
  }

  handleMenuCollapse = (collapsed) => {
    this.setState({ collapsed })
  }

  render() {
    const { auth, location, history, loading } = this.props
    const { routes } = this.props.route
    const permission = this.props.auth.permission
   
    return (
      <Layout>
        <SiderMenu
          permission={permission}
          theme="light"
          currentUser={auth?.user}
          collapsed={this.state.collapsed}
          location={location}
          menuData={getMenuData(permission)}
          // badgeData={this.badgeNew()}
          onCollapse={this.handleMenuCollapse}
          history={history}
          loading={loading}
        />
        <Layout>
          <Header style={{ padding: 0, background: '#FFF' }}>
            <GlobalHeader
              currentUser={auth}
              collapsed={this.state.collapsed}
              onCollapse={this.handleMenuCollapse}
              logout={this.props.signOut}
              history={history}
            />
          </Header>
          <Content style={{ margin: '24px 24px 0', height: '100%', backgroundColor: "#f0f2f5" }}>
            <Switch>{renderRoutes(routes, { hasNew: false }, auth?.user?.role?.policy)}</Switch>
          </Content>
          <Footer style={{ padding: 0, backgroundColor: '#f0f2f5' }}>
            <GlobalFooter />
          </Footer>
        </Layout>
      </Layout>
    )
  }
}

export default compose(connect(({ auth }) => ({ auth, loading: auth.loading }), { signOut: logout }))(BasicLayout)
