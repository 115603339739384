import { takeLatest, put, call, all } from 'redux-saga/effects'
import { LIST_FOOTER, ONE_FOOTER, EDIT_FOOTER } from '../../../utils/types'
import { getFooterList, getOneFooter, updateFooter } from 'src/utils/api'
import { reject } from 'lodash'

function* findAllFooter() {
  try {
    console.log('saga')
    const response = yield call(getFooterList)
    
    if (response.ok) {
      const responseJson = yield response.json()

      if (!responseJson.message) {
        yield put({
          type: LIST_FOOTER.SUCCESS,
          payload: {
            footerList: responseJson,
          },
        })
      } else {
        yield put({ type: LIST_FOOTER.FAILURE })
        yield call(reject)
      }
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: LIST_FOOTER.FAILURE })
    yield call(reject)
  }
}

function* findOneFooter({ payload: id, handleOpenModalUpdate }) {
  try {
    const response = yield call(getOneFooter, id)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: ONE_FOOTER.SUCCESS,
        payload: {
          oneFooter: responseJson,
        },
      })
      if (handleOpenModalUpdate) {
        handleOpenModalUpdate()
      }
    } else {
      throw new Error('Invalid login request')
    }

    //yield call(resolve)
    //yield put(push('dashboard/seminar'))
  } catch (error) {
    console.error(error)
    yield put({ type: ONE_FOOTER.FAILURE, error })
    yield call(reject)
  }
}

function* editFooter({ payload: body, handleNotification, setIsModalUpdate }) {
  try {
    const response = yield call(updateFooter, body)

    if (response.ok) {
      const responseJson = yield response.json()
      if (responseJson.message) {
        yield put({ type: EDIT_FOOTER.FAILURE })
        handleNotification(responseJson.message)
      } else {
        yield put({
          type: EDIT_FOOTER.SUCCESS,
        })

        handleNotification('Sửa thành công')
        setIsModalUpdate(false)
      }
    } else {
      handleNotification('Sửa thất bại')
      yield put({ type: EDIT_FOOTER.FAILURE, error })
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.error(error)
    yield put({ type: EDIT_FOOTER.FAILURE, error })
    handleNotification('Sửa thất bại')
    yield call(reject)
  }
}

// Monitoring Sagas
function* footerMonitor() {
  yield all([takeLatest(LIST_FOOTER.REQUEST, findAllFooter)])

  yield all([takeLatest(ONE_FOOTER.REQUEST, findOneFooter)])

  yield all([takeLatest(EDIT_FOOTER.REQUEST, editFooter)])
}

export default footerMonitor
