import { DROP_ACTION, FETCH_COORDINATES, FETCH_SEMINAR, POST_COORDINATES1 } from "src/utils/types"

const defaultState = {
    data:{
        coordinatesTicket: []
    }
  }

export default function designTicketReducer(state = defaultState, action) {
    switch (action.type) {
      case FETCH_SEMINAR.REQUEST:
          return {
              ...state,
              ...action.payload,
              loading: true,
              error: false,
          }
      case FETCH_SEMINAR.SUCCESS:
          return {
              ...state,
              // data:action.payload,
              ...action.payload,

              error: false,
              loading: false,
          }
      case FETCH_SEMINAR.FAILURE:
          return {
              ...state,
              ...action.payload,
              loading: false,
              error: true,
          }

      // ======================== create ====================//
    case POST_COORDINATES1.REQUEST:
        return {
            ...state,
            ...action.payload,
            loading: true,
            stateCreate:0
        }
    case POST_COORDINATES1.SUCCESS:
        return {
            ...state,
            ...action.payload,
            loading: false,
            stateCreate:1

        }
    case POST_COORDINATES1.FAILURE:
        return {
            ...state,
            ...action.payload,
            loading: false,
            stateCreate:2

        }

    // ========================== fecth one ==========================//

    case FETCH_COORDINATES.REQUEST:

        return {
            ...state,
            ...action.payload,
            loading: true,
            statusFetchCoordinates:0
        }
    case FETCH_COORDINATES.SUCCESS:
        console.log("--------------------------------", action.payload);

        return {
            ...state,
            ...action.payload,
            loading: false,
            statusFetchCoordinates:1

        }
    case FETCH_COORDINATES.FAILURE:
        return {
            ...state,
            ...action.payload,
            loading: false,
            statusFetchCoordinates:2

        }

    //========================= drop func =========================//
    case DROP_ACTION.REQUEST:
        return {
            stateCreate:0,
            statusFetchCoordinates:0
        }
          default:
            return state
        }
}
