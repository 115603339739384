import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Button, Input, Modal, Row, Col  } from 'antd'
import { Icon } from '@ant-design/compatible'
import Cookies from 'universal-cookie';
import { auth, dropAction,confirmPassword } from './actions'
import styles from './styles'
import Image from './Components/Image';
import {LeftOutlined} from '@ant-design/icons'
import {
  Link
} from "react-router-dom";
class ComfirmPassword extends Component {

 constructor(props) {
   super(props);
   this.state={
    email:'',
    password:'',
    isHidden:true
  }
 }


  componentDidMount() {
    const { token, history, confirmPassword } = this.props
  }

  handleSubmit = async (values) => {
    const otp = window.location.search.split('=')[1]

   
    try {
      await this.onSubmit(otp, values.password)
    } catch (error) {
      Modal.error({ title: 'Login failed', content: 'Please try again' })
    }
  }

  componentDidUpdate(){
   
    if(this.props.statusLogin === 2){
      Modal.error({ title: 'Login failed', content: 'Please try again' })
      dropAction()
    }

  }

  onSubmit = (otp, password) => this.props.confirmPassword({ otp, password })

  handleChange = (e) => {
    if(e.target.value.length > 0){
      this.state.isHidden = false
      
    }
    // this.state.email = e.target.value
  }

  render() {
    const { loading } = this.props

    return (
      <div className={styles.container}>
        <Row>
          <Col span={12}>
            <div>
                <Image/>
            </div>
          </Col>
          <Col span={12}>
            <div className={styles.main}>
              <div className={styles.login}>
                <div>
                  <Link to="/auth/login">
                    <Button className={styles.callback}  icon={<LeftOutlined />}>
                      Quay lại
                    </Button>
                  </Link>
                </div>
                <div className={styles.header}>
                  <p>Mật khẩu mới</p>
                </div>
                <Form onFinish={this.handleSubmit} className="login-form">
                  <div style={{ height:'40px', color: 'rgba(0, 0, 0, 0.5)' }}>
                    <label style={{fontSize:'14px',}}>Vui lòng nhập mật khẩu mới của bạn. </label>
                  </div>
                  <Form.Item  name="password" rules={[{ required: true, message: 'Please input your password' }]}>
                    <Input
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)'}} />}
                      type="password"
                      name="password"
                      placeholder="Mật khẩu"
                      value={this.state.email}
                      onChange={this.handleChange}
                      size="large"

                    />
                  </Form.Item>


                    <Form.Item>
                      <Button size="large" className={styles.submit} type="primary" htmlType="submit">
                        Xác nhận
                        {loading && <Icon type="loading" />}
                      </Button>
                    </Form.Item>

                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect((state) => state.confirmPassword, { auth, dropAction, confirmPassword })(ComfirmPassword)
