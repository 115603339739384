import { takeLatest, put, call, all } from 'redux-saga/effects'
import { LIST_POLL, ONE_POLL, CREATE_POLL, EDIT_POLL, DELETE_POLL } from 'src/utils/types'
import { createPoll, updatePoll, deletePoll, getPollList, getOnePoll } from 'src/utils/api'
import { reject } from 'lodash'

function* fetchPolls({ payload: slug }) {
  try {
    const response = yield call(getPollList, slug)

    if (response.ok) {
      const responseJson = yield response.json()

      yield put({
        type: LIST_POLL.SUCCESS,
        payload: {
          polls: responseJson,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.error(error)
    yield put({ type: LIST_POLL.FAILURE, error })
    yield call(reject)
  }
}

function* onePoll({ payload: id, slug, setIsModalVisibleUpdate }) {
  try {
    const response = yield call(getOnePoll, id, slug)
    if (response.ok) {
      const responseJson = yield response.json()
      setIsModalVisibleUpdate(true)
      yield put({
        type: ONE_POLL.SUCCESS,
        payload: {
          onePoll: responseJson,
        },
      })
    } else {
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.error(error)
    yield put({ type: ONE_POLL.FAILURE, error })
    yield call(reject)
  }
}

function* createPolls({ payload: body, handleNotification, slug, setIsModalVisible }) {
  try {
    const response = yield call(createPoll, body, slug)

    if (response.ok) {
      const responseJson = yield response.json()
      if (responseJson.status === 1) {
        handleNotification('Thành công')
        setIsModalVisible(false)
        yield put({
          type: CREATE_POLL.SUCCESS,
          payload: {
            status: responseJson,
          },
        })
      } else {
        handleNotification('Thất bại')
        yield put({ type: CREATE_POLL.FAILURE })
        yield call(reject)
      }
    } else {
      handleNotification('Thất bại')
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.error(error)
    handleNotification('Thất bại')
    yield put({ type: CREATE_POLL.FAILURE, error })
    yield call(reject)
  }
}

function* editPolls({ payload: body, handleNotification, slug, setIsModalVisibleUpdate }) {
  try {
    const response = yield call(updatePoll, slug, body)

    if (response.ok) {
      const responseJson = yield response.json()
      if (responseJson.status === 1) {
        handleNotification('Thành công')
        setIsModalVisibleUpdate(false)
        yield put({
          type: EDIT_POLL.SUCCESS,
          payload: {
            status: responseJson,
          },
        })
      } else {
        handleNotification('Thất bại')
        yield put({ type: EDIT_POLL.FAILURE })
        yield call(reject)
      }
    } else {
      handleNotification('Thất bại')
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.error(error)
    handleNotification('Thất bại')
    yield put({ type: EDIT_POLL.FAILURE, error })
    yield call(reject)
  }
}

function* deletePolls({ payload: body, handleNotification, slug }) {
  try {
    const response = yield call(deletePoll, slug, body.id)
    if (response.ok) {
      const responseJson = yield response.json()
      if (responseJson.status === 1) {
        handleNotification('Thành công')

        yield put({
          type: DELETE_POLL.SUCCESS,
          payload: {
            status: responseJson,
          },
        })
      } else {
        handleNotification('Thất bại')
        yield put({ type: DELETE_POLL.FAILURE })
        yield call(reject)
      }
    } else {
      handleNotification('Thất bại')
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.error(error)
    handleNotification('Thất bại')
    yield put({ type: DELETE_POLL.FAILURE, error })
    yield call(reject)
  }
}

// Monitoring Sagas
function* seminarPollMonitor() {
  yield all([takeLatest(LIST_POLL.REQUEST, fetchPolls)])

  yield all([takeLatest(ONE_POLL.REQUEST, onePoll)])

  yield all([takeLatest(CREATE_POLL.REQUEST, createPolls)])

  yield all([takeLatest(EDIT_POLL.REQUEST, editPolls)])

  yield all([takeLatest(DELETE_POLL.REQUEST, deletePolls)])
}

export default seminarPollMonitor
