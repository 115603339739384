import {
  LIST_CATEGORIES,
  CREATE_CATEGORY,
  ONE_CATEGORY,
  CREATE_CHILD_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_CHILD_CATEGORY,
  DELETE_CATEGORY,
  DELETE_CHILD_CATEGORY,
} from '../../../utils/types'

const defaultState = {
  categories: null,
}

export default function category(state = defaultState, action) {
  switch (action.type) {
    //list Categories
    case LIST_CATEGORIES.REQUEST:
    case LIST_CATEGORIES.SUCCESS:
      
      
      
      state.categories = action.payload
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case LIST_CATEGORIES.FAILURE:
      return { ...state, error: action.error, loading: false }

    //One category
    case ONE_CATEGORY.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case ONE_CATEGORY.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case ONE_CATEGORY.FAILURE:
      return { ...state, error: action.error, loading: false }

    //Create Category
    case CREATE_CATEGORY.REQUEST:
    case CREATE_CATEGORY.SUCCESS:
    case CREATE_CATEGORY.FAILURE:

    //Create Category
    case CREATE_CHILD_CATEGORY.REQUEST:
    case CREATE_CHILD_CATEGORY.SUCCESS:
    case CREATE_CHILD_CATEGORY.FAILURE:

    //Create Category
    case UPDATE_CATEGORY.REQUEST:
    case UPDATE_CATEGORY.SUCCESS:
    case UPDATE_CATEGORY.FAILURE:

    //Create Category
    case UPDATE_CHILD_CATEGORY.REQUEST:
    case UPDATE_CHILD_CATEGORY.SUCCESS:
    case UPDATE_CHILD_CATEGORY.FAILURE:

    //Create Category
    case DELETE_CATEGORY.REQUEST:
    case DELETE_CATEGORY.SUCCESS:
    case DELETE_CATEGORY.FAILURE:
    //Create Category
    case DELETE_CHILD_CATEGORY.REQUEST:
    case DELETE_CHILD_CATEGORY.SUCCESS:
    case DELETE_CHILD_CATEGORY.FAILURE:

    default:
      return state
  }
}
