import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getStore } from 'src/store'
import L from 'react-loadable'

import AuthLayout from './layouts/AuthLayout'
import LoginForm from './containers/Auth/Login'
import ForgetPassword from './containers/Auth/ForgetPassword'
import LoadingScreen from './components/LoadingScreen'
import ConfirmPassword from './containers/Auth/confirmPassword'
import BasicLayout from './layouts/BasicLayout'
import MeetingLayout from './layouts/LiveMeetingLayout'
import { isExpToken } from './utils/helpers'

const Loadable = (opts) =>
  L({
    loading: LoadingScreen,
    ...opts,
  })

const AsyncUser = Loadable({
  loader: () => import('./routes/User'),
})

const AsyncSeminar = Loadable({
  loader: () => import('./routes/Seminar'),
})

const AsyncSeminarPanelist = Loadable({
  loader: () => import('./routes/SeminarPanelist'),
})

const AsyncSeminarPoll = Loadable({
  loader: () => import('./routes/SeminarPoll'),
})

const AsyncCategory = Loadable({
  loader: () => import('./routes/Category'),
})

const AsyncNewsCategory = Loadable({
  loader: () => import('./routes/NewsCategory'),
})

const AsyncNews = Loadable({
  loader: () => import('./routes/News'),
})

const AsyncDegree = Loadable({
  loader: () => import('./routes/Degree'),
})

const AsyncContact = Loadable({
  loader: () => import('./routes/Contact'),
})

const AsyncSponsor = Loadable({
  loader: () => import('./routes/Sponsor'),
})

const AsyncOrder = Loadable({
  loader: () => import('./routes/Order'),
})

const AsyncSpeaker = Loadable({
  loader: () => import('./routes/Speaker'),
})

const AsyncPresider = Loadable({
  loader: () => import('./routes/Presider'),
})

const AsyncParticipants = Loadable({
  loader: () => import('./routes/Participants'),
})

const AsyncCme = Loadable({
  loader: () => import('./routes/Cme'),
})

const AsyncCoupon = Loadable({
  loader: () => import('./routes/Coupon'),
})

const AsyncAdmin = Loadable({
  loader: () => import('./routes/Admin'),
})

const VerifyPasswordAdmin = Loadable({
  loader: () => import('./routes/VerifyPassword'),
})

const VerifyConfirmPassword = Loadable({
  loader: () => import('./routes/ConfirmPassword'),
})
 

const AsyncDesignTicket = Loadable({
  loader: () => import('./routes/DesignTicket'),
})

const AsyncZoomLive = Loadable({
  loader: () => import('./routes/ZoomLive'),
})

const AsyncDesignCME = Loadable({
  loader: () => import('./routes/DesignCME'),
})

const AsyncNotification = Loadable({
  loader: () => import('./routes/Notification'),
})

const AsyncFooter = Loadable({
  loader: () => import('./routes/Footer'),
})

const createAuthorizedRoute = (Component, props) =>
  getStore().getState().auth.token && !isExpToken(getStore().getState().auth.token) ? (
    <Component {...props} />
  ) : (
    <Redirect to="/auth/login" />
  )

const PublicRoutes = (
  <Route path="/auth" component={AuthLayout}>
    <Route exact path="/auth/login" component={LoginForm} />

    <Route exact path="/auth/forgetPassword" component={ForgetPassword} />

    <Route exact path="/auth/verify" component={VerifyPasswordAdmin} />

    <Route exact path="/auth/confirmpassword" component={VerifyConfirmPassword} />
  </Route>
)

const MeetingRoutes = (
  <Route path="/live-meeting" component={(props) => createAuthorizedRoute(MeetingLayout, props)}>
    {/* <Route exact path="/live-meeting" component={() => <Redirect to="/live-meeting/seminar" />} /> */}

    <Route model="live" path="/live-meeting/seminar/:slug" component={AsyncZoomLive} />
  </Route>
)

const DashboardRoutes = (
  <Route path="/dashboard" component={(props) => createAuthorizedRoute(BasicLayout, props)}>
    <Route exact path="/dashboard" component={() => <Redirect to="/dashboard/seminars" />} />

    <Route model="user" path="/dashboard/users" component={AsyncUser} />

    <Route model="seminar" path="/dashboard/seminars" component={AsyncSeminar} />

    <Route model="panelist" path="/dashboard/panelist/:slug" component={AsyncSeminarPanelist} />

    <Route model="seminarPoll" path="/dashboard/poll/:slug" component={AsyncSeminarPoll} />

    <Route model="sponsor" path="/dashboard/sponsor" component={AsyncSponsor} />

    <Route model="category" path="/dashboard/category" component={AsyncCategory} />

    <Route model="newsCategory" path="/dashboard/news-category" component={AsyncNewsCategory} />

    <Route model="news" path="/dashboard/news" component={AsyncNews} />

    <Route model="footer" path="/dashboard/footer" component={AsyncFooter} />

    <Route model="degree" path="/dashboard/degree" component={AsyncDegree} />

    <Route model="contact" path="/dashboard/contact" component={AsyncContact} />

    <Route model="order" path="/dashboard/order" component={AsyncOrder} />

    <Route model="speaker" path="/dashboard/speaker" component={AsyncSpeaker} />

    <Route model="presider" path="/dashboard/presider" component={AsyncPresider} />

    <Route model="participants" path="/dashboard/participants" component={AsyncParticipants} />

    <Route model="cme" path="/dashboard/cme" component={AsyncCme} />

    <Route model="coupon" path="/dashboard/coupon" component={AsyncCoupon} />

    <Route model="admins" path="/dashboard/admins" component={AsyncAdmin} />

    <Route model="designCME" path="/dashboard/designCME/:id" component={AsyncDesignCME} />

    <Route model="designTicket" path="/dashboard/designTicket/:id" component={AsyncDesignTicket} />

    <Route model="notification" path="/dashboard/notification" component={AsyncNotification} />
  </Route>
)

const getChildRoutes = (childRoutes) =>
  React.Children.map(childRoutes, ({ props: { exact, path, component, children, model } }) => ({
    exact,
    path,
    component,
    model,
    routes: children ? getChildRoutes(children) : children,
  }))

const createRouteArray = (routes) => [
  {
    exact: routes.props.exact,
    path: routes.props.path,
    component: routes.props.component,
    model: routes.props.model,
    routes: getChildRoutes(routes.props.children),
  },
]

const publicRoutesArray = createRouteArray(PublicRoutes)
const dashboardRoutesArray = createRouteArray(DashboardRoutes)
const meetingRoutesArray = createRouteArray(MeetingRoutes)

export { publicRoutesArray, dashboardRoutesArray, meetingRoutesArray }
