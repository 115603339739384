import { CREATE_PRESIDER, DELETE_PRESIDER, DROP_ACTION, GET_PRESIDER, UPDATE_PRESIDER } from 'src/utils/types'

export default function seminar(state = {}, action) {
  switch (action.type) {
    case GET_PRESIDER.REQUEST:
      return {
        ...state,
        ...action.payload,
        statusFetchPresider: 0,
      }

    case GET_PRESIDER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        statusFetchPresider: 1,
      }

    case GET_PRESIDER.FAILURE:
      return {
        ...state,
        ...action.payload,
        statusFetchPresider: 2,
      }

    case CREATE_PRESIDER.REQUEST:
      return {
        ...state,
        ...action.payload,
        statusCreatePresider: 0,
      }

    case CREATE_PRESIDER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        statusCreatePresider: 1,
      }

    case CREATE_PRESIDER.FAILURE:
      return {
        ...state,
        ...action.payload,
        statusCreatePresider: 2,
      }

    case UPDATE_PRESIDER.REQUEST:
      return {
        ...state,
        ...action.payload,
        statusUpdatePresider: 0,
      }

    case UPDATE_PRESIDER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        statusUpdatePresider: 1,
      }

    case UPDATE_PRESIDER.FAILURE:
      return {
        ...state,
        ...action.payload,
        statusUpdatePresider: 2,
      }

    case DELETE_PRESIDER.REQUEST:
      return {
        ...state,
        ...action.payload,
        statusDeletePresider: 0,
      }

    case DELETE_PRESIDER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        statusDeletePresider: 1,
      }

    case DELETE_PRESIDER.FAILURE:
      return {
        ...state,
        ...action.payload,
        statusDeletePresider: 2,
      }

    case DROP_ACTION.REQUEST:
      return {
        ...state,
        ...action.payload,
        statusFetchPresider: 0,
        statusCreatePresider: 0,
        statusUpdatePresider: 0,
        statusDeletePresider: 0,
      }

    default:
      return state
  }
}
