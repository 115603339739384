import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getListSpeaker, createListSpeaker, deleteSpeaker, getListDegree, updateSpeaker } from 'src/utils/api'
import { CREATE_DEGREE, CREATE_SPEAKER, DELETE_SPEAKER, GET_SPEAKER, UPDATE_SPEAKER } from 'src/utils/types'

function* fetchSpeakerSaga() {
  try {
    const response = yield call(getListSpeaker)
    const responseDegree = yield call(getListDegree)

    if (response.ok) {
      const responseJson = yield response.json()
      const responseDegreeJson = yield responseDegree.json()
      console.log(responseDegreeJson, '____________________________responseDegree________________________')
      responseJson.data.map((item, key) => {
        item.key = key + 1
      })
      yield put({
        type: GET_SPEAKER.SUCCESS,
        payload: {
          listSpeaker: responseJson,
          listDegree: responseDegreeJson,
        },
      })
    } else {
      yield put({
        type: GET_SPEAKER.FAILURE,
      })
    }
  } catch (error) {
    console.log(error)
    yield put({
      type: GET_SPEAKER.FAILURE,
    })
  }
}

function* createSpeakerSaga(body) {
  try {
    const response = yield call(createListSpeaker, body.payload)

    console.log(response.body)
    if (response.ok) {
      const responseJson = yield response.json()

      console.log(responseJson.data)

      yield put({
        type: CREATE_SPEAKER.SUCCESS,
        payload: {
          listSpeaker: responseJson.data,
        },
      })
    } else {
      yield put({
        type: CREATE_SPEAKER.FAILURE,
      })
    }
  } catch (error) {
    console.log(error)
    yield put({
      type: CREATE_SPEAKER.FAILURE,
    })
  }
}

function* updateSpeakerSaga(payload) {
  try {
    //  const id = body.payload;
    //  const bodyTemp = body.body
    console.log(payload)
    const response = yield call(updateSpeaker, payload)

    
    if (response.ok) {
      const responseJson = yield response.json()

      console.log(responseJson.data)

      yield put({
        type: UPDATE_SPEAKER.SUCCESS,
        payload: {
          listSpeaker: responseJson.data,
        },
      })
    } else {
      yield put({
        type: UPDATE_SPEAKER.FAILURE,
      })
    }
  } catch (error) {
    console.log(error)
    yield put({
      type: UPDATE_SPEAKER.FAILURE,
    })
  }
}

function* deleteSpeakerSaga(body) {
  try {
    const response = yield call(deleteSpeaker, body.payload)

    if (response.ok) {
      yield put({
        type: DELETE_SPEAKER.SUCCESS,
      })
    } else {
      yield put({
        type: DELETE_SPEAKER.FAILURE,
      })
    }
  } catch (error) {
    console.log(error)
    yield put({
      type: DELETE_SPEAKER.FAILURE,
    })
  }
}

// Monitoring Sagas
function* speakerMonitor() {
  yield all([
    takeLatest(GET_SPEAKER.REQUEST, fetchSpeakerSaga),
    takeLatest(UPDATE_SPEAKER.REQUEST, updateSpeakerSaga),
    takeLatest(CREATE_SPEAKER.REQUEST, createSpeakerSaga),
    takeLatest(DELETE_SPEAKER.REQUEST, deleteSpeakerSaga),
  ])
}

export default speakerMonitor
