import { message } from 'antd'
import { isUrl } from './url'
import { S3_FOLDER } from './constants'

const Images = {
  formatImg(url) {
    if (url && url.indexOf('data:image') === 0) {
      return url
    }
    if (url && isUrl(url)) {
      return url
    }
    return url ? `${S3_FOLDER}${url}` : ''
  },

  isImageType: (type) => type.split('/')[0] === 'image',

  isImage: (file) => file.type.split('/')[0] === 'image',

  beforeUpload(file) {
    // warning: use this with Upload component will not fire action props
    const isImage = Images.isImage(file)
    if (!isImage) {
      message.error('You can only select images')
    }
    const isLt2M = file.size / 1024 / 1024 < 5
    if (!isLt2M) {
      message.error('File size must be smaller than 5MB!')
    }
    return false
  },

  dataURItoBlob: (dataURI) => {
    const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1])
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const max = bytes.length
    const ia = new Uint8Array(max)
    for (let i = 0; i < max; i += 1) {
      ia[i] = bytes.charCodeAt(i)
    }
    return new Blob([ia], { type: mime })
  },

  resizeImage: (blob) => {
    const maxSize = 1024 // HD resolution
    const image = new Image()
    const canvas = document.createElement('canvas')
    const resize = function resize() {
      let { width, height } = image
      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width
          width = maxSize
        }
      } else if (height > maxSize) {
        width *= maxSize / height
        height = maxSize
      }
      canvas.width = width
      canvas.height = height
      canvas.getContext('2d').drawImage(image, 0, 0, width, height)
      const dataUrl = canvas.toDataURL('image/jpeg')
      return Images.dataURItoBlob(dataUrl)
    }
    return new Promise((ok) => {
      image.onload = () => ok(resize())
      image.src = URL.createObjectURL(blob)
    })
  },

  getBase64: (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  },

  isGIFs: (content) => {
    const pattern = /^(https?|ftp|file):\/\/[^\s]+\.gif$/
    return pattern.test(content)
  },
}

export default Images
