import React, { PureComponent } from 'react'
import { Menu, Spin, Dropdown, Avatar, Drawer, Row, Col, Divider } from 'antd'
import { Icon } from '@ant-design/compatible'
import { UserOutlined } from '@ant-design/icons'
import styles from './styles.css'

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
)

export default class GlobalHeader extends PureComponent {
  state = { visible: false }
  handleToggle = () => {
    const { collapsed, onCollapse } = this.props
    onCollapse(!collapsed)
  }

  handleMenuClick = ({ key }) => {
    switch (key) {
      case 'logout':
        this.props.logout()
        break

      case 'profile':
        this.showDrawer()
        break

      default:
        break
    }
  }

  onCloseDrawer = () => {
    this.setState({
      visible: false,
    })
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    })
  }

  componentDidMount() {
    console.log(this.props.currentUser, 'this.props.currentUser')
  }

  render() {
    const { currentUser = {}, collapsed } = this.props

    const menu = (
      <Menu className={styles.menu} selectedKeys={[]} onClick={this.handleMenuClick}>
        {
          //   <Menu.Item key="profile">
          //   <Icon type="user" />
          //   Profile
          // </Menu.Item>
        }

        <Menu.Item key="logout" data-cy="menu-logout">
          <Icon type="logout" />
           Đăng xuất
        </Menu.Item>
      </Menu>
    )

    return (
      <div className={styles.header}>
        <Icon className={styles.trigger} type={collapsed ? 'menu-unfold' : 'menu-fold'} onClick={this.handleToggle} />
        <div className={styles.right}>
          {currentUser.token ? (
            <Dropdown overlay={menu}>
              <span className={`${styles.action} ${styles.account}`} data-cy="main-menu">
                <span className={styles.name}>{currentUser?.user?.email} </span>
                {currentUser?.user?.avatar == null ? (
                  <Avatar size="default" className={styles.avatar} icon={<UserOutlined />} />
                ) : (
                  <Avatar src={currentUser?.user?.avatar} />
                )}
              </span>
            </Dropdown>
          ) : (
            <Spin size="small" style={{ marginLeft: 8 }} />
          )}
        </div>
        <Drawer
          placement="right"
          closable={false}
          onClose={this.onCloseDrawer}
          visible={this.state.visible}
          getContainer={true}
          width={600}
          // style={{ width: "500px" }}
        >
          <>
            <p className="site-description-item-profile-p" style={{ marginBottom: 24 }}></p>
            <p className="site-description-item-profile-p"></p>
            <Row>
              <Col span={12}>
                <DescriptionItem title="Full Name" content="Lily" />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Account" content="AntDesign@example.com" />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DescriptionItem title="City" content="HangZhou" />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Country" content="Viet Nam" />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DescriptionItem title="Birthday" content="February 2,1900" />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Website" content="-" />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Message" content="Make things as simple as possible but no simpler." />
              </Col>
            </Row>
          </>
        </Drawer>
      </div>
    )
  }
}
