import {
  CREATE_ACC_ADMIN,
  CREATE_ACC_ADMIN_BY_USER,
  DROP_ACTION,
  FETCH_DATA_ADMIN,
  SEARCH_ADMIN,
  SEARCH_USER,
  SEARCH_USER_ADMIN,
  SEARCH_USER_PAGE_ADMIN,
  UPDATE_PERMISSION_ADMIN,
  UPDATE_USER_ADMIN,
  UPGRADE_ACC_ADMIN_BY_USER,
} from 'src/utils/types'

const defaultState = {}

export default function Participants(state = defaultState, action) {
  switch (action.type) {
    case FETCH_DATA_ADMIN.REQUEST:
      return {
        ...state,
      }
    case FETCH_DATA_ADMIN.SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case FETCH_DATA_ADMIN.FAILURE:
      return {
        ...state,
        ...action.payload,
      }
    //================================= update date permission admin ====================//
    case FETCH_DATA_ADMIN.REQUEST:
      return {
        ...state,
        ...action.payload,
        statusUpdatePermission: 0,
      }
    case FETCH_DATA_ADMIN.SUCCESS:
      return {
        ...state,
        ...action.payload,
        statusUpdatePermission: 1,
      }
    case FETCH_DATA_ADMIN.FAILURE:
      return {
        ...state,
        ...action.payload,
        statusUpdatePermission: 2,
      }

    //================================= update date permission admin ====================//UPDATE_PERMISSION_ADMIN
    case CREATE_ACC_ADMIN.REQUEST:
      return {
        ...state,
        ...action.payload,
        statusCreateUserAdmin: 0,
      }
    case CREATE_ACC_ADMIN.SUCCESS:
      return {
        ...state,
        ...action.payload,
        statusCreateUserAdmin: 1,
      }
    case CREATE_ACC_ADMIN.FAILURE:
      return {
        ...state,
        ...action.payload,
        statusCreateUserAdmin: 2,
      }

    //================================= update date permission admin ====================//
    case UPDATE_PERMISSION_ADMIN.REQUEST:
      return {
        ...state,
        ...action.payload,
        statusUpdateUserAdmin: 0,
      }
    case UPDATE_PERMISSION_ADMIN.SUCCESS:
      return {
        ...state,
        ...action.payload,
        statusUpdateUserAdmin: 1,
      }
    case UPDATE_PERMISSION_ADMIN.FAILURE:
      return {
        ...state,
        ...action.payload,
        statusUpdateUserAdmin: 2,
      }
    //================================= search users admin ====================//
    case SEARCH_ADMIN.REQUEST:
      return {
        ...state,
        ...action.payload,
      }
    case SEARCH_ADMIN.SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case SEARCH_ADMIN.FAILURE:
      return {
        ...state,
        ...action.payload,
      }

    //================================= update users admin ====================//
    case UPDATE_USER_ADMIN.REQUEST:
      return {
        ...state,
        ...action.payload,
        statusUpdate: 0,
      }
    case UPDATE_USER_ADMIN.SUCCESS:
      return {
        ...state,
        ...action.payload,
        statusUpdate: 1,
      }
    case UPDATE_USER_ADMIN.FAILURE:
      return {
        ...state,
        ...action.payload,
        statusUpdate: 2,
      }

    //================================= update users admin ====================//
    case SEARCH_USER_ADMIN.REQUEST:
      return {
        ...state,
        ...action.payload,
        statusSearch: 0,
      }
    case SEARCH_USER_ADMIN.SUCCESS:
      return {
        ...state,
        ...action.payload,
        statusSearch: 1,
      }
    case SEARCH_USER_ADMIN.FAILURE:
      return {
        ...state,
        ...action.payload,
        statusSearch: 2,
      }

    // ======================= Search User ============= //

    case SEARCH_USER_PAGE_ADMIN.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case SEARCH_USER_PAGE_ADMIN.SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case SEARCH_USER_PAGE_ADMIN.FAILURE:
      return {
        ...state,
        ...action.payload,
      }

    // ============================== CREATE BY USER ================================================================//
    case CREATE_ACC_ADMIN_BY_USER.REQUEST:
      return {
        ...state,
        ...action.payload,
        statusCreateUserAdmin: 0,
      }
    case CREATE_ACC_ADMIN_BY_USER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        statusCreateUserAdmin: 1,
      }
    case CREATE_ACC_ADMIN_BY_USER.FAILURE:
      return {
        ...state,
        ...action.payload,
        statusCreateUserAdmin: 2,
      }

    //================  upgrade Acc User ================================//
    case UPGRADE_ACC_ADMIN_BY_USER.REQUEST:
      return {
        ...state,
        ...action.payload,
        statusUpgrade: 0,
      }
    case UPGRADE_ACC_ADMIN_BY_USER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        statusUpgrade: 1,
      }
    case UPGRADE_ACC_ADMIN_BY_USER.FAILURE:
      return {
        ...state,
        ...action.payload,
        statusUpgrade: 2,
      }

    // ================== Drop Action ================ //
    case DROP_ACTION.REQUEST:
      return {
        ...state,
        statusUpdatePermission: 0,
        statusCreateUserAdmin: 0,
        statusUpdateUserAdmin: 0,
        statusUpdate: 0,
        statusSearch: 0,
        statusUpgrade: 0,
      }
    default:
      return state
  }
}
