import { CREATE_PRESIDER, DELETE_PRESIDER, DROP_ACTION, GET_PRESIDER, UPDATE_PRESIDER } from "src/utils/types";

export function fetchPresider(){
    return {
        type:GET_PRESIDER.REQUEST
    }
}

export function createPresider(body){
    return {
        type:CREATE_PRESIDER.REQUEST,
        payload:body
    }
}

export function updatePresider(id,body){
    return {
        type:UPDATE_PRESIDER.REQUEST,
        payload:id,body
    }
}

export function deletePresider(id){
    return {
        type:DELETE_PRESIDER.REQUEST,
        payload:id
    }
}

export function dropAction(){
    return {
        type: DROP_ACTION.REQUEST,
    }
}
