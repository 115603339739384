import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale-provider/en_US'
import moment from 'moment'
import { CookiesProvider } from 'react-cookie'
import App from './containers/App'
import './index.css';

moment.locale('vi')

ReactDOM.render(
  <ConfigProvider locale={enUS} >
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </ConfigProvider>,
  document.getElementById('app'),
)
