import {
  CREATE_TEMPLATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  DROP_ACTION,
  LIST_CME,
  LIST_NOTIFICATION,
  SEARCH_SEMINAR_NOTI,
  SEARCH_USER,
  SEND_NOTIFICATION,
  SEND_SEMINAR_NOTIFICATION,
} from 'src/utils/types'

const defaultState = {
  templateNotification: null,
  isSendSeminarNoti: false,
}

export default function newsCategory(state = defaultState, action) {
  switch (action.type) {
    //list Categories
    case LIST_NOTIFICATION.REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
        error: false,
      }

    case LIST_NOTIFICATION.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      }
    case LIST_NOTIFICATION.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true,
      }
    //============ CREATE TEMPLATE ============//

    case CREATE_TEMPLATE_NOTIFICATION.REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
        error: false,
        state_create_template: 0,
      }

    case CREATE_TEMPLATE_NOTIFICATION.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
        state_create_template: 1,
      }
    case CREATE_TEMPLATE_NOTIFICATION.FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true,
        state_create_template: 2,
      }

    //============ SEND NOTIFICATION ============//

    case SEND_NOTIFICATION.REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
        error: false,
        status_send_noti: 0,
      }

    case SEND_NOTIFICATION.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
        status_send_noti: 1,
      }
    case SEND_NOTIFICATION.FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true,
        status_send_noti: 2,
      }

    case SEND_SEMINAR_NOTIFICATION.REQUEST:
      return {
        ...state,
        isSendSeminarNoti: true,
      }

    case SEND_SEMINAR_NOTIFICATION.SUCCESS:
      return {
        ...state,
        isSendSeminarNoti: false,
      }
    case SEND_SEMINAR_NOTIFICATION.FAILURE:
      return {
        ...state,
        isSendSeminarNoti: false,
      }

    //============ SEARCH SEMINAR ============//

    case SEARCH_SEMINAR_NOTI.REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
        error: false,
      }

    case SEARCH_SEMINAR_NOTI.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      }
    case SEARCH_SEMINAR_NOTI.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true,
      }

    //============ LIST CME ============//

    case LIST_CME.REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
        error: false,
      }

    case LIST_CME.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      }
    case LIST_CME.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true,
      }
    //============ DELETE NOTI ============//

    case DELETE_NOTIFICATION.REQUEST:
      return {
        ...state,
        ...action.payload,
        status_noti: 0,
        loading: true,
        error: false,
      }

    case DELETE_NOTIFICATION.SUCCESS:
      return {
        ...state,
        ...action.payload,
        status_noti: 0,
        status_noti: 1,
        error: false,
        loading: false,
      }
    case DELETE_NOTIFICATION.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true,
        status_noti: 2,
      }

    // ============= DROP ACTION ===============//

    case DROP_ACTION.REQUEST:
      return {
        state_create_template: 0,
        status_send_noti: 0,
        status_noti: 0,
      }

    default:
      return state
  }
}
