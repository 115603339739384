import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from 'src/containers/Auth/reducer'
import users from 'src/containers/Admin/User/reduce'

import seminar from 'src/containers/Admin/Seminar/reducer'
import category from 'src/containers/Admin/Category/reducer'
import degree from 'src/containers/Admin/Degree/reducer'
import contact from 'src/containers/Admin/Contact/reducer'
import speaker from 'src/containers/Admin/Speaker/reducer'
import presider from 'src/containers/Admin/Presider/reducer'
import participants from 'src/containers/Admin/Participants/reduce'
import order from 'src/containers/Admin/Order/reducer'
import admin from 'src/containers/Admin/Admin/reducer'
import sponsor from 'src/containers/Admin/Sponsor/reducer'
import cme from 'src/containers/Admin/CME/reduce'
import designTicket from 'src/containers/Admin/DesignTicket/reducer'
import zoomMeeting from 'src/containers/Admin/ZoomLive/reducer'
import newsCategory from 'src/containers/Admin/NewsCategory/reducer'
import news from 'src/containers/Admin/News/reducer'
import designCME from 'src/containers/Admin/DesignCME/reduce'
import coupon from 'src/containers/Admin/Coupon/reducer'
import notification from 'src/containers/Admin/Notification/reducers'
import footer from 'src/containers/Admin/Footer/reducer'

import seminarPanelist from 'src/containers/Admin/Panelist/reducer'
import seminarPoll from 'src/containers/Admin/Poll/reducer'

const rootReducer = {
  auth,
  seminar,
  category,
  users,
  degree,
  contact,
  speaker,
  speaker,
  presider,
  participants,
  order,
  admin,
  sponsor,
  cme,
  designTicket,
  zoomMeeting,
  newsCategory,
  news,
  designCME,
  coupon,
  notification,
  footer,
  seminarPanelist,
  seminarPoll,
}

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    ...rootReducer,
  })
